import { useLocation } from 'react-router';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

import storeIcon from 'assets/common/store.png';
import medicalIcon from 'assets/common/medical.png';
import circleIcon from 'assets/common/circle.png';
import arrowRight from 'assets/common/arrow_right_bar_white.png';

import { ActiveBtn } from 'animation';
import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import Logo from 'components/_common/Logo';
import ToggleBtn from 'components/_common/ToggleBtn';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

function Header() {
  const { refundCondition } = useAppSelector((state) => state) as IGlobalState;
  const { pathname } = useLocation();
  const isVisibleToggleBtn =
    ['/home', '/receipt'].includes(pathname) && refundCondition === 'BOTH';

  const isValidatePage = pathname === '/' || pathname === '/medical';
  const deviceWitdh = window.innerWidth;

  const onClickMoveMedicalPage = () => {
    const redirectUrl = 'https://meditour.ktaxpay.com/';
    window.location.href = redirectUrl;
  };
  return (
    <MaxContainer>
      <HeaderContainer>
        <TranslationZone></TranslationZone>
        <Container>
          <Inner>
            <LogoWrapper>
              <Logo isTop={false} />
              {isValidatePage && (
                <FlexWrap alignItems='center' gap='8px'>
                  <Icon
                    src={pathname === '/' ? storeIcon : medicalIcon}
                    width='16px'
                    height='16px'
                  />
                  <Typography
                    size={isMobile ? '12px' : '15px'}
                    fontWeight='500'
                    lineHeight='20px'
                    color='#246CF6'
                  >
                    {pathname === '/' ? 'STORE CUSTOMER' : 'MEDICAL CUSTOMER'}
                  </Typography>
                </FlexWrap>
              )}
              {pathname === '/refund-info-leaflet' && (
                <MedicalMoveBtnContainer onClick={onClickMoveMedicalPage}>
                  <MedicalMoveBtn topLayer>
                    {deviceWitdh >= 300 && (
                      <Icon src={circleIcon} width='16px' height='16px' />
                    )}
                    <Typography
                      size={isMobile ? '12px' : '14px'}
                      fontWeight='500'
                      lineHeight='22px'
                      color='#FFF'
                    >
                      MEDICAL REFUND {deviceWitdh >= 300 && 'SITE'}
                    </Typography>
                    <Icon src={arrowRight} width='12px' height='12px' />
                  </MedicalMoveBtn>
                  <MedicalMoveBtn>
                    {deviceWitdh >= 300 && (
                      <Icon src={circleIcon} width='16px' height='16px' />
                    )}
                    <Typography
                      size={isMobile ? '12px' : '14px'}
                      fontWeight='500'
                      lineHeight='22px'
                      color='#FFF'
                    >
                      MEDICAL REFUND {deviceWitdh >= 300 && 'SITE'}
                    </Typography>
                    <Icon src={arrowRight} width='12px' height='12px' />
                  </MedicalMoveBtn>
                </MedicalMoveBtnContainer>
              )}
              {isVisibleToggleBtn && <ToggleBtn />}
            </LogoWrapper>
          </Inner>
        </Container>
      </HeaderContainer>
    </MaxContainer>
  );
}
const MaxContainer = styled.div`
  width: 375px;
  z-index: 60;
  position: fixed;
  top: 0;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
`;
const TranslationZone = styled.div`
  height: 40px;
  background: #f1f1f1;
  @media ${(props) => props.theme.mobile} {
    height: 32px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.desktop} {
    width: 375px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const LogoWrapper = styled.div`
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
  }
`;
const Icon = styled.img<{ width?: string; height?: string }>`
  width: ${(props) => (props.width ? props.width : '24px')};
  height: ${(props) => (props.height ? props.height : '24px')};
`;
const MedicalMoveBtnContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
const MedicalMoveBtn = styled.button<{ topLayer?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 12px;
  border-radius: 100px;
  border: 1px solid #0e3b90;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
  background-color: #1856cd;
  cursor: pointer;
  ${(props) =>
    props.topLayer &&
    css`
      transform: translate(-1.3px, -1.5px);
      background-color: #246cf6;
      z-index: 10;
      animation: ${ActiveBtn} 1200ms steps(1) infinite;
    `}
`;
export default Header;

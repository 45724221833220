import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const theme: DefaultTheme = {
  primaryColor: '#1B84A2',
  darkPrimaryColor: '#0054A2',
  primaryTransparent: 'rgba(27, 132, 162, 0.8)',
  secondaryColor: '#FF6464',
  primaryBgColor: '#05191F',
  primaryTextColor: '#146076',
  warningTextColor: '#FF5239',
  fontColorTitle: '#000',
  fontColorContent: '#3A3B3E',
  fontColorFooter: '#5F6165',
  fontColorSubContent: '#9FA1A7',
  secondaryBgColor: '#F2F2F2',
  mobile: '(max-width: 520px)',
  desktop: '(min-width: 520px)',
};

export const GlobalStyle = createGlobalStyle`
    ${reset};

    * {
        box-sizing: border-box;
        font-family: 'Pretendard', sans-serif;
      }
    body {
      font-size: 16px;
      background-color: #fff;
      font-family: 'Pretendard', sans-serif;
      font-weight: 500; 
      ::-webkit-scrollbar {
      display: none;
      }
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
    }
    button{
      border:none;
      cursor:pointer;
      background-color:transparent;
      padding: 0;
      :hover{
        opacity:0.8;
      }
      transition:200ms all ease-in;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.fontColorTitle};
    }
    input{
      outline:none;
    }
    h1,h2,h3,h4,span,p,button{
      color: #000000;
    }
    .skiptranslate.goog-te-gadget {
     color: transparent;
     & span{
       display:none;
     }
    }
    .VIpgJd-suEOdc.VIpgJd-yAWNEb-L7lbkb.skiptranslate{
      display:none !important;
    }
    select{
      padding: 4px 12px;
      width: 140px;
      border-radius: 2px;
      outline:none;
      background-color:#ffffff;
      color: #000000;
      @media ${(props) => props.theme.mobile} {
        padding: 2px 10px;
      }
    }
    body{
      top:0 !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
   
    .react-calendar{
      border:0 !important;
      padding: 0 20px;
      width: 100%;
    }
    .react-calendar__navigation__label__labelText{
    }
    .react-calendar__navigation__arrow{
      font-size: 28px;
      font-weight: 300;
    }
    .react-calendar__tile--now,.react-calendar__navigation button:disabled{
      background: #ffffff ;
      color:black !important;
    }
    .react-calendar__tile--now:hover{
      background:lightgray !important;
    }
    .react-calendar__tile--now::after{
      content:'•';
      font-size:16px;
      width: 0;
      display:inline-block;
      color:blue;
      transform:translate(-12px,-13px)
    }
    .react-calendar__tile--active{
      border-radius:50%;
    }
    .react-calendar__tile{
      padding: 13px 6px;
    }
    .react-calendar__tile:disabled{
     color:gray;
     cursor: not-allowed;
    }
    .react-calendar__month-view__weekdays__weekday abbr{
      text-decoration: none;
    }
    @media print {
    .print-page {
      size: landscape;
    }
  }
`;

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import ScrollToTop from 'components/_common/ScrollToTop';
import LoginAuto from 'components/_common/LoginAuto';
import CustomModal from 'components/_common/CustomModal';
import ReceiptPreviewModal from 'components/Home/ReceiptPreviewModal';
import GuideModal from 'components/UploadImage/GuideModal';
import ImagePreviewModal from 'components/UploadImage/ImagePreviewModal';
import PlaceDetailModal from 'components/Home/PlaceDetailModal';
import CalendarModal from 'components/Home/CalendarModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import CustomerRefund from 'pages/ktp/CustomerRefund';
import NotFound from 'pages/ktp/NotFound';
import VerifiactionRedirect from 'pages/ktp/VerificationRedirect';
import PassportValidate from 'pages/ktp/PassportValidate';
import ReceiptInquiry from 'pages/ktp/ReceiptInquiry';
import RefundResult from 'pages/ktp/RefundResult';
import RefundProcedure from 'pages/ktp/RefundProcedure';
import RefundInfo from 'pages/ktp/RefundInfo';
import Home from 'pages/ktp/Home';
import QnA from 'pages/ktp/QnA';
import RefundInfoLeaflet from 'pages/ktp/RefundInfoLeaflet';

import { IGlobalState } from 'store';
import { Iso639_1Code } from 'utils/nations';
import MedicalPassportValidate from 'pages/medical/MedicalPassportValidate';
import Redirect from 'components/_common/Redirect';

function RouterContainer() {
  const dispatch = useAppDispatch();
  const { nationality, globalModalInfo } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const selectElement = document.querySelector('.goog-te-combo');

  function changeGoogleTranslateLanguage(nationality: string | null) {
    const nation = Iso639_1Code(nationality);
    if (!nation) return;
    //@ts-ignore
    selectElement.value = nation;
    //@ts-ignore
    selectElement.dispatchEvent(new Event('change'));
  }

  const onCloseModal = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: { isActive: false, data: null, type: null },
    });
  };

  useEffect(() => {
    selectElement && changeGoogleTranslateLanguage(nationality);
  }, [selectElement, nationality]);

  // 구글 번역을 1초뒤에 번역하도록 함 / 렌더링 영향을 줌
  useEffect(() => {
    setTimeout(() => {
      window.googleTranslateLoaded = () => {
        if (typeof window.google !== 'undefined' && window.google.translate) {
          window.google.translate.TranslateElement(
            { pageLanguage: 'en', autoDisplay: false },
            'google_translate_element'
          );
        }
      };
    }, 1000);
  }, []);

  return (
    <Container>
      <Translation id='google_translate_element'></Translation>
      <Router>
        <Redirect />
        <LoginAuto />
        <ScrollToTop />
        <Switch>
          <Route path='/' component={PassportValidate} exact />
          <Route
            path='/email-verification/:id/:verifiedEmail'
            component={VerifiactionRedirect}
          />
          <Route path='/home' component={Home} />
          <Route path='/receipt' component={ReceiptInquiry} />
          <Route path='/refund-result' component={RefundResult} />
          <Route path='/refund' component={CustomerRefund} />
          <Route path='/refund-procedure' component={RefundProcedure} />
          <Route path='/refund-info' component={RefundInfo} />
          <Route path='/refund-info-leaflet' component={RefundInfoLeaflet} />
          <Route path='/qna' component={QnA} />
          {/* 메디컬 */}
          <Route path='/medical' component={MedicalPassportValidate} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
      {/* 커스텀 모달 */}
      {globalModalInfo?.type === 'UPLOAD_IMAGE' &&
        globalModalInfo?.isActive && <GuideModal {...globalModalInfo?.data} />}
      {globalModalInfo?.type === 'PREVIEW_IMAGE' &&
        globalModalInfo?.isActive && (
          <ImagePreviewModal {...globalModalInfo?.data} />
        )}

      {globalModalInfo?.type === 'LOCATION' && globalModalInfo?.isActive && (
        <PlaceDetailModal {...globalModalInfo?.data} />
      )}
      {globalModalInfo?.type === 'CALENDAR' && globalModalInfo?.isActive && (
        <CalendarModal onClose={onCloseModal} />
      )}
      {globalModalInfo?.type === 'CUSTOM' && globalModalInfo?.isActive && (
        <CustomModal onClose={onCloseModal} {...globalModalInfo?.data} />
      )}
      {globalModalInfo?.type === 'PREVIEW_RECEIPT' &&
        globalModalInfo?.isActive && (
          <ReceiptPreviewModal {...globalModalInfo?.data} />
        )}
    </Container>
  );
}
const Container = styled.div`
  position: static;
`;

const Translation = styled.div`
  position: fixed;
  z-index: 200;
  @media ${(props) => props.theme.desktop} {
    right: 42%;
    top: 2px;
  }
  @media ${(props) => props.theme.mobile} {
    top: 0px;
    right: 20px;
  }
`;
export default RouterContainer;

import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';

import LogoIcon from 'assets/receipt/logo.png';
import ServiceLogoIcon from 'assets/common/logo_service.png';
import TableRow from './TableRow';
import { IFormatedReceiptInfo } from 'model/types';
import FlexWrap from 'components/_common/FlexWrap';
import Customs from 'assets/receipt/customs.png';
import Notification from './Notification';
import { useAppSelector } from 'hooks/useReduxHooks';
import DashedLineImg from 'assets/receipt/dashedLine.png';

export interface IPrintDataInfo {
  refundIndex: number;
  purchaseSn: string;
  barcodeS3Path: string | null;
  rowDataArr: Array<IFormatedReceiptInfo>;
}
interface IProps {
  info: IPrintDataInfo;
  order: number;
  lastIndex: number;
  setIsChildrenComponentRendered?: Dispatch<SetStateAction<boolean>>;
}
function ReceiptForPrint({
  info,
  order,
  lastIndex,
  setIsChildrenComponentRendered,
}: IProps) {
  const { refundAfter } = useAppSelector((state) => state);

  const convertUrl = (url: string) => {
    const timestamp = new Date().getTime();
    const imageWithTimestamp = url.includes('?')
      ? `${url}&v=${timestamp}`
      : `${url}?v=${timestamp}`;
    return imageWithTimestamp;
  };

  useEffect(() => {
    if (order !== lastIndex) return;
    setIsChildrenComponentRendered && setIsChildrenComponentRendered(true);
  }, []);

  return (
    <Container className='notranslate'>
      <CustomsLocationContainer>
        <CustomsLocation src={Customs} />
      </CustomsLocationContainer>
      <ReceiptContainer dir='column'>
        <FlexWrap dir='column' alignItems='center'>
          <FlexWrap alignItems='center' gap='20px'>
            <CompanyLogo src={LogoIcon} />
            <ServiceLogo src={ServiceLogoIcon} />
          </FlexWrap>
          <GrayText>{info.purchaseSn}</GrayText>
        </FlexWrap>
        <FlexWrap justifyContent='center'>
          {info.barcodeS3Path && (
            <>
              <Barcode
                src={convertUrl(info.barcodeS3Path)}
                crossOrigin='anonymous'
                height='75px'
              />
            </>
          )}
        </FlexWrap>
        <FlexWrap justifyContent='space-between'>
          <AdditionalInfo isCustomerLeft>
            KTP 환급전표
            <br />
            https://ktaxpay.com/
          </AdditionalInfo>
          <AdditionalInfo>
            Korea Taxfree Payment receipt fot Tax Refund
            <br />
            (국세청인정서식 Authorized by Korea NTS)
          </AdditionalInfo>
        </FlexWrap>
        {info.rowDataArr.map((item: IFormatedReceiptInfo, idx: number) => (
          <TableRow
            key={idx}
            {...item}
            isForPrinting
            refundAfter={refundAfter}
            hasBarcode={!!info.barcodeS3Path}
          />
        ))}
        {refundAfter && (
          <>
            <BlackText>Pls present your passport, goods and receipt</BlackText>
            <BlackTextWrap justifyContent='flex-end' margin='8px 0 0'>
              <GrayText>{info.purchaseSn}</GrayText>
            </BlackTextWrap>
            <DashedLine src={DashedLineImg} />
          </>
        )}
        <FlexWrap justifyContent='space-evenly'>
          <GrayText>석세스모드</GrayText>
          <GrayText> 제 138-00078 호</GrayText>
        </FlexWrap>
      </ReceiptContainer>
      <Notification />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  width: 1130px;
  height: 794px;
  background-color: #ffffff;
`;
const ReceiptContainer = styled(FlexWrap)`
  flex: 1;
  padding: 8px 30px;
  max-width: 33%;
  justify-content: center;
`;
const CustomsLocationContainer = styled(FlexWrap)`
  flex: 1;
`;
const CustomsLocation = styled.img`
  height: 790px;
`;
const CompanyLogo = styled.img`
  width: 200px;
  height: 35px;
`;
const ServiceLogo = styled.img`
  width: 100px;
  height: 25px;
`;
const GrayText = styled.p`
  color: #5f6165;
  line-height: 14px;
  font-size: 8px;
  font-weight: 400;
`;
const Barcode = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 8px 0 12px;
`;
const AdditionalInfo = styled(GrayText)<{ isCustomerLeft?: boolean }>`
  text-align: center;
  margin-bottom: 8px;
  text-align: ${(props) => (props.isCustomerLeft ? 'left' : 'right')};
`;
const BlackTextWrap = styled(FlexWrap)`
  padding-bottom: 10px;
`;
const BlackText = styled.p`
  color: #000000;
  font-size: 8px;
  line-height: 16px;
`;
const DashedLine = styled.img`
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
`;
export default ReceiptForPrint;

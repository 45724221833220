import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import html2pdf from 'html2pdf.js';

import { getReceipt, sendReceiptMail } from 'api';
import ReceiptForPrint, {
  IPrintDataInfo,
} from 'components/Receipt/ReceiptForPrint';
import { IReceiptPayload, ReceiptResponseType } from 'model/types';
import { IGlobalState } from 'store';
import { formatPrintReceiptForm } from 'utils/format';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHooks';
import LoadingView from './LoadingView';

type Props = {
  setSendingStatus: Dispatch<SetStateAction<'IS_SENDING' | 'DEFAULT'>>;
  callback?: () => void;
  isActiveLoadingView?: boolean;
};
function SendingReceiptMail({
  setSendingStatus,
  callback,
  isActiveLoadingView = true,
}: Props) {
  const dispatch = useAppDispatch();
  const elementRef = useRef(null);
  const { passportNumber, refundAfter, email } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [data, setData] = useState<IPrintDataInfo[] | undefined>();
  const [isChildrenComponentRendered, setIsChildrenComponentRendered] =
    useState<boolean>(false);

  const { mutate: receiptMutation } = useMutation<
    ReceiptResponseType,
    AxiosError,
    IReceiptPayload
  >((payload) => getReceipt(payload), {
    retry: false,
    onSuccess: (data) => {
      setData(formatPrintReceiptForm(data!, passportNumber as string));
    },
  });

  const { mutate: sendReceiptMailMutate } = useMutation<
    string,
    AxiosError,
    FormData
  >((payload) => sendReceiptMail(payload), {
    retry: false,
    onSuccess: () => {
      setSendingStatus('DEFAULT');
      dispatch({
        type: 'UPDATE_HOME_MODAL_STATE',
        payload: {
          isActive: true,
          type: 'CUSTOM',
          data: {
            title: '메일 전송 완료',
            content: `텍스 리펀 영수증(첨부파일)을\n출력 후 세관에 가져가세요.`,
            btnText: '확인',
          },
        },
      });
      callback && callback();
    },
    onError: () => {
      setSendingStatus('DEFAULT');
      alert('메일 전송에 실패하였습니다.');
    },
  });
  const convertComponentToPdf = async () => {
    const pdfOptions = {
      margin: 0,
      filename: `ktp_${passportNumber}_refund_statement.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };
    const pdfBlob = await html2pdf()
      .set(pdfOptions)
      .from(elementRef.current)
      .toPdf()
      .output('blob');

    const formData = new FormData();
    formData.append(
      `receiptPdf`,
      pdfBlob as Blob,
      `ktp_${passportNumber}_refund_statement.pdf`
    );
    formData.append('email', email);

    sendReceiptMailMutate(formData);
  };

  useEffect(() => {
    receiptMutation({
      passportNumber,
      latest: true,
      refundAfter,
    });
  }, []);

  useEffect(() => {
    if (!isChildrenComponentRendered) return;
    convertComponentToPdf();
  }, [isChildrenComponentRendered]);

  return (
    <>
      {isActiveLoadingView && (
        <LoadingView
          description={`텍스 리펀 영수증을\n이메일로 전송하고 있습니다.`}
        />
      )}
      <HiddenWrap>
        <div ref={elementRef}>
          {data?.map((item, index) => (
            <ReceiptForPrint
              key={index}
              info={item}
              order={index + 1}
              lastIndex={data?.length}
              setIsChildrenComponentRendered={setIsChildrenComponentRendered}
            />
          ))}
        </div>
      </HiddenWrap>
    </>
  );
}

const HiddenWrap = styled.div`
  position: fixed;
  top: 200%;
  left: 0;
`;
export default SendingReceiptMail;

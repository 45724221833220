import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { useHistory } from 'react-router';
import { routes } from 'routes';

function ToggleBtn() {
  const { refundAfter } = useAppSelector((state) => state) as IGlobalState;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onClick = () => {
    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: {
        refundAfter: !refundAfter,
      },
    });
    history.push(routes.home);
  };

  return (
    <Container onClick={onClick}>
      <RefundTypeText active={refundAfter}>사후</RefundTypeText>
      <RefundTypeText active={!refundAfter}>즉시</RefundTypeText>
      <ToggleItem isRefundAfter={refundAfter} />
    </Container>
  );
}
const Container = styled.button`
  margin-right: 24px;
  padding: 5px 14px;
  border-radius: 100px;
  border: 1px solid #cbccce;
  position: relative;
  display: flex;
  gap: 23px;
  justify-content: space-between;
  align-items: center;
  outline: none;
`;
const RefundTypeText = styled.span<{ active: boolean }>`
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => (props.active ? '#fff' : '#5F6165')};
  z-index: 100;
  opacity: ${(props) => (props.active ? 1 : 0)};
  @media ${(props) => props.theme.mobile} {
    width: 26px;
  }
`;
const ToggleItem = styled.span<{ isRefundAfter: boolean }>`
  position: absolute;
  top: 3px;
  left: 4px;
  background-color: ${(props) => (props.isRefundAfter ? '#246CF6' : '#80848A')};
  border-radius: 100px;
  width: 44px;
  height: 26px;
  transform: ${(props) =>
    props.isRefundAfter ? ' translateX(0px)' : ' translateX(47px)'};
  transition: 250ms all ease-in;
  @media ${(props) => props.theme.mobile} {
    transform: ${(props) =>
      props.isRefundAfter ? ' translateX(0px)' : ' translateX(50px)'};
  }
`;
export default ToggleBtn;

import html2canvas from 'html2canvas';

export function CalculateBox(
  targetArr: [number, number?, number?, number?]
): string {
  let resultArr: [number, number, number, number] = [
    targetArr[0],
    targetArr[0],
    targetArr[0],
    targetArr[0],
  ];

  if (targetArr.length === 2) {
    if (targetArr[1] !== undefined)
      resultArr = [targetArr[0], targetArr[1], targetArr[0], targetArr[1]];
  } else if (targetArr.length === 3) {
    if (targetArr[1] !== undefined && targetArr[2] !== undefined)
      resultArr = [targetArr[0], targetArr[1], targetArr[2], targetArr[1]];
  } else if (targetArr.length === 4) {
    if (
      targetArr[1] !== undefined &&
      targetArr[2] !== undefined &&
      targetArr[3] !== undefined
    )
      resultArr = [targetArr[0], targetArr[1], targetArr[2], targetArr[3]];
  }

  return `${resultArr.toString().replace(/,/gi, 'px ')}px`;
}
export const addComma = (num: string | number) =>
  String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getDday = (date: string) => {
  //시간-분 단위를 0으로 만들기 위한 작업(시간-분 단위가 없어야 디데이 계산이 정확함)
  const today = new Date();
  const currYear = today.getFullYear();
  const currMonth = (today.getMonth() + 1).toString().padStart(2, '0');
  const currDay = today.getDate().toString().padStart(2, '0');
  const todayDate = new Date(currYear, +currMonth, +currDay);
  //================================================================
  const newDay = new Date(date);
  const year = newDay.getFullYear();
  const month = (newDay.getMonth() + 1).toString().padStart(2, '0');
  const day = newDay.getDate().toString().padStart(2, '0');
  const newDate = new Date(year, +month, +day);
  const diffDay = newDate.getTime() - todayDate.getTime();
  const result = diffDay / (1000 * 60 * 60 * 24);

  if (result > 0) {
    return `D-${result}`;
  } else {
    return 'D-DAY';
  }
};

export const convertComponentToImage = async (el: HTMLElement) => {
  const canvas = await html2canvas(el);
  return canvas.toDataURL('image/png', 1.0);
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const getProductType = (num: number) => {
  switch (num) {
    case 0:
      return '쌍커풀 수술';
    case 1:
      return '코 성형 수술';
    case 2:
      return '유방 수술';
    case 3:
      return '지방 흡입술';
    case 4:
      return '주름살 제거술';
    case 5:
      return '안면 윤곽술';
    case 6:
      return '치아 성형술';
    case 7:
      return '악안면 교정술';
    case 8:
      return '색소 모반/주근깨/흑색점/기미 치료술';
    case 9:
      return '여드름 치료술';
    case 10:
      return '제모술';
    case 11:
      return '탈모 치료/모발이식술';
    case 12:
      return '문신/문신 제거/피어싱';
    case 13:
      return '지방융해술';
    case 14:
      return '피부재생/미백/항노화치료/모공 축소술';
    case 15:
      return '기타';
  }
};
// 생일 1993-05-11 형식
export const attachedHyphensDate = (dateNumber: string) => {
  return dateNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/g, '$1-$2-$3')
    .replace(/(\-{1,2})$/g, '');
};
// 우편번호 000-0000 형식
export const attachedHyphensPostCode = (dateNumber: string) => {
  return dateNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{3})(\d{0,4})/, '$1-$2')
    .replace(/(\-{1,2})$/g, '');
};
export const getIsFrequentCountry = (country: string): boolean => {
  return [
    'CHN',
    'USA',
    'JPN',
    'THA',
    'AUS',
    'CAN',
    'TWN',
    'ARE',
    'CHL',
    'NZL',
    'IDN',
    'SGP',
    'MNG',
    'CHE',
    'DEU',
    'PHL',
    'RUS',
    'HKG',
  ].includes(country);
};

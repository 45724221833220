import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import LogoCompanyIcon from 'assets/receipt/logo.png';
import LogoServiceIcon from 'assets/common/logo_service.png';
import DownTriangle from 'assets/receipt/downTriangle.png';
import DashedLineImg from 'assets/receipt/dashedLine.png';
import FlexWrap from 'components/_common/FlexWrap';
import TableRow from 'components/Receipt/TableRow';
import { IData } from 'components/Receipt/RefundReceipt';

interface IProps {
  info: IData;
  index: number;
  style?: any;
}
function ReceiptImage({ info, index, style }: IProps) {
  const receiptRef = useRef<HTMLDivElement | null>(null);

  return (
    <ImageContainer dir='column' alignItems='center' style={style}>
      <ReceiptEdge src={DownTriangle} isTop />
      <ReceiptContainer ref={receiptRef} className='notranslate'>
        <ContentBox>
          <LogoContainer gap='20px' alignItems='center'>
            <LogoCompany src={LogoCompanyIcon} />
            <LogoService src={LogoServiceIcon} />
          </LogoContainer>
          {info?.barcodeS3Path && (
            <Barcode src={info.barcodeS3Path} style={{ top: 152 }} />
          )}
          <AdditionalInfo isSerialNumber>{info?.purchaseSn}</AdditionalInfo>
          <AdditionalInfo>
            KTP 환급전표
            <br />
            Korea Taxfree Payment receipt fot Tax Refund
            <br />
            https://ktaxpay.com/
            <br />
            (국세청인정서식 Authorized by Korea NTS)
          </AdditionalInfo>
          <RowWrap>
            {info.rowDataArr.map((item, idx) => (
              <TableRow key={idx} {...item} refundAfter={info.refundAfter} />
            ))}
          </RowWrap>
          {info.refundAfter && (
            <>
              <BlackGuideText>
                Pls present your passport, goods and receipt
              </BlackGuideText>
              <AdditionalInfo isSerialNumber>{info.purchaseSn}</AdditionalInfo>
              {info.barcodeS3Path && <BarcodeSection />}
              <DashedLine src={DashedLineImg} />
            </>
          )}
          <GrayText>https://ktaxpay.com </GrayText>
          <FlexWrap justifyContent='space-evenly'>
            <GrayText>석세스모드</GrayText>
            <GrayText>제 138-00078 호</GrayText>
          </FlexWrap>
          <br />
          <GrayText>{`- ${index + 1} - `}</GrayText>
          <br />
          <br />
          {info.barcodeS3Path && (
            <Barcode src={info.barcodeS3Path} style={{ bottom: 324 }} />
          )}
        </ContentBox>
      </ReceiptContainer>
      <ReceiptEdge src={DownTriangle} />
    </ImageContainer>
  );
}
const ImageContainer = styled(FlexWrap)`
  margin: 36px 0 50px;
`;
const ReceiptContainer = styled.div`
  width: 335px;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;
const ReceiptEdge = styled.img<{ isTop?: boolean }>`
  width: 100%;
  ${(props) =>
    props.isTop
      ? css`
          transform: rotate(180deg) translate(0, 0px) scale(1.2, 1);
        `
      : css`
          transform: translate(0, -2px) scale(1.2, 1);
        `}
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoContainer = styled(FlexWrap)`
  margin: 40px 0 24px;
`;
const LogoCompany = styled.img`
  height: 29px;
  width: 148px;
`;
const LogoService = styled.img`
  height: 33px;
  width: 128px;
`;
const Barcode = styled.img`
  margin: 17px 0px 29px;
  max-width: 335px;
  height: auto;
`;
const BarcodeSection = styled.div`
  height: 80px;
`;
const AdditionalInfo = styled.p<{ isSerialNumber?: boolean }>`
  text-align: center;
  color: #5f6165;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${(props) => (props.isSerialNumber ? '10px' : '35px')};
`;
const RowWrap = styled.div`
  padding: 0 20px;
`;
const GrayText = styled.p`
  color: #5f6165;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
`;
const DashedLine = styled.img`
  width: 100%;
  height: 1px;
  padding: 0 20px;
  margin-bottom: 16px;
`;
const BlackGuideText = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 100px 0 10px;
  text-align: center;
`;
export default ReceiptImage;

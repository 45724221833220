import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';

export interface IGuideDetail {
  id: number;
  location: string;
  name: string;
  places: IPlaces[];
  isActive: boolean;
}

export interface IPlaces {
  id: number;
  placeDetail: string;
  placeImg: string;
  refundPrintImg: string;
}
interface IProps {
  info: IGuideDetail;
}

function GuideDetail({ info }: IProps) {
  const dispatch = useAppDispatch();
  const { nationality } = useAppSelector((state) => state);
  const { name, places } = info;

  const onClickLocation = (place: IPlaces) => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        type: 'LOCATION',
        isActive: true,
        data: { name, placeInfo: place },
      },
    });
  };
  window.history.pushState(null, '1', window.location.href);

  return (
    <Container>
      <LocationName>{name}</LocationName>
      <FlexWrap dir='column' gap='24px' margin='0 0 40px'>
        {places.map((place, index) => (
          <Place key={index}>
            <PlaceLocation>{place.placeDetail}</PlaceLocation>
            <PlaceImg src={place.placeImg} width='100%' height='210px' />
            <PlaceButton onClick={() => onClickLocation(place)}>
              {nationality === 'KOR' ? '위치보기' : 'LOCATION'}
            </PlaceButton>
          </Place>
        ))}
      </FlexWrap>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 36px;
`;
const LocationName = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;
const Place = styled(FlexWrap)`
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;
const PlaceLocation = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
const PlaceImg = styled.img`
  object-fit: contain;
`;
const PlaceButton = styled.button`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background-color: #246cf6;
  border-radius: 8px;
  padding: 12px 0;
`;
export default GuideDetail;

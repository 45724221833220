import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { Spin } from 'animation';
import { deleteImage, updateImage, uploadImage } from 'api';
import noImage from 'assets/uploadImage/no_image.png';
import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import ToastPopUp from 'components/UploadImage/ToastPopUp';
import { comma } from 'utils/format';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { IDeleteImagePayload, IUploadImagePayload } from 'model/types';

interface IProps {
  refundIndex: number;
  franchiseeName: string;
  category: string;
  price: number;
  refund: number;
  refundS3Path: string | null;
  refetchHome: () => void;
}
function UploadItem({
  refundIndex,
  franchiseeName,
  category,
  price,
  refund,
  refundS3Path,
  refetchHome,
}: IProps) {
  const dispatch = useAppDispatch();
  const [image, setImage] = useState(refundS3Path || null);
  const [isToast, setIsToast] = useState(false);
  const priceInfo = [
    ['품목', category],
    ['결제금액', comma(price)],
    ['환급액', comma(refund)],
  ];
  const inputRef = useRef<HTMLInputElement | null>(null);

  const uploadMutation = useMutation<string, AxiosError, IUploadImagePayload>(
    (payload) => uploadImage(payload),
    {
      retry: false,
      onError: () => {
        alert(
          `[error code:0000] 서버요청에 실패하였습니다. 다시 시도해주세요.`
        );
      },
      onSuccess: (newS3Url) => {
        setOpenToastTimer();
        setImage(newS3Url);
        refetchHome();
      },
    }
  );
  const updateMutation = useMutation<string, AxiosError, IUploadImagePayload>(
    (payload) => updateImage(payload),
    {
      retry: false,
      onError: () => {
        alert(
          `[error code:0000] 서버요청에 실패하였습니다. 다시 시도해주세요.`
        );
      },
      onSuccess: (newS3Url) => {
        setOpenToastTimer();
        setImage(newS3Url + `?timestamp=${new Date().getTime()}`);
        refetchHome();
      },
    }
  );
  const deleteMutation = useMutation<number, AxiosError, IDeleteImagePayload>(
    (payload) => deleteImage(payload),
    {
      retry: false,
      onError: () => {
        alert(
          `[error code:0000] 서버요청에 실패하였습니다. 다시 시도해주세요.`
        );
      },
      onSuccess: () => {
        setImage(null);
        refetchHome();
      },
    }
  );
  const onSubmit = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        type: 'UPLOAD_IMAGE',
        isActive: true,
        data: { inputRef },
      },
    });
  };
  const onDelete = () => {
    deleteMutation.mutate({ refundIndex });
  };
  const onChangeImage: React.ChangeEventHandler<HTMLInputElement> = (
    e: any
  ) => {
    const formData = new FormData();
    formData.append('uploadImage', e.target.files[0]);
    if (image) {
      updateMutation.mutate({ refundIndex, formData });
    } else {
      uploadMutation.mutate({ refundIndex, formData });
    }
  };
  const onOpenPreview = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        type: 'PREVIEW_IMAGE',
        isActive: true,
        data: { imgUrl: `${image}?${Math.random()}` },
      },
    });
  };
  const setOpenToastTimer = () => {
    setIsToast(true);
    setTimeout(() => {
      setIsToast(false);
    }, 4000);
  };

  return (
    <Container dir='column'>
      <Title>{franchiseeName}</Title>
      <ContentContainer justifyContent='space-between'>
        <PriceItem dir='column' justifyContent='center'>
          {priceInfo.map(([key, value], idx) => (
            <FlexWrap key={idx}>
              <BoldText>{key}</BoldText>
              <Text>{value}</Text>
            </FlexWrap>
          ))}
        </PriceItem>
        {updateMutation.isLoading || uploadMutation.isLoading ? (
          <LoadingBox>
            <LoadingSpinner />
          </LoadingBox>
        ) : (
          <>
            {image ? (
              <ProductImage
                src={`${image}?${Math.random()}`}
                onClick={onOpenPreview}
              />
            ) : (
              <NoImageContainer
                dir='column'
                justifyContent='center'
                alignItems='center'
                active
                onClick={onSubmit}
              >
                <NoImage src={noImage} />
                <NoImageText>{`세관에서 도장을 받은\n영수증 사진을 올려주세요.`}</NoImageText>
              </NoImageContainer>
            )}
          </>
        )}
      </ContentContainer>
      <HiddenFileInput
        type='file'
        ref={inputRef}
        accept='image/*'
        name='uploadImage'
        onChange={onChangeImage}
      />
      <TextButton
        title={image ? '사진 교체' : '사진 올리기'}
        bgColor={image ? '#FFFFFF' : '#246CF6'}
        color={image ? '#1856CD' : '#FFFFFF'}
        outline
        isActive
        onClick={onSubmit}
        margin='32px 0 0'
        width='100%'
      />
      {image && (
        <TextButton
          title='사진 삭제'
          bgColor='#fff'
          outline
          onClick={onDelete}
          color='#3A3B3E'
          margin='12px 0 0'
          width='100%'
        />
      )}
      {<ToastPopUp visiable={isToast} />}
    </Container>
  );
}
const Container = styled(FlexWrap)`
  padding: 30px 0;
  border-bottom: 1px solid #cbccce;
  width: 100%;
  align-items: flex-start;
`;
const ContentContainer = styled(FlexWrap)`
  width: 100%;
`;
const HiddenFileInput = styled.input`
  height: 0;
  opacity: 0;
`;
const PriceItem = styled(FlexWrap)`
  flex: 1;
`;
const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 700;
`;
const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
`;
const BoldText = styled(Text)`
  text-align: left;
  font-weight: 500;
  width: 70px;
`;
const NoImageContainer = styled(FlexWrap)<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#E5E6E8' : '#ececec')};
  border-radius: 4px;
  width: 152px;
  height: 118px;
  :hover {
    cursor: pointer;
  }
`;
const ProductImage = styled.img`
  width: 152px;
  height: 118px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    opacity: 70%;
  }
  transition: all 300ms ease-in;
`;
const NoImage = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`;
const NoImageText = styled.span`
  font-size: 12px;
  color: #9fa1a7;
  text-align: center;
  line-height: 150%;
  white-space: pre-wrap;
`;
const LoadingBox = styled(FlexWrap)`
  justify-content: center;
  align-items: center;
  border: 1px solid #9fa1a7;
  border-radius: 4px;
  width: 152px;
  height: 118px;
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 5px solid #fff;
  margin: 0 auto;
  border-top: 5px solid #246cf6;
`;
export default UploadItem;

import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { getReceipt } from 'api';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import LoadingView from 'components/_common/LoadingView';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IReceiptPayload, ReceiptResponseType } from 'model/types';
import { IGlobalState } from 'store';
import { formatPrintReceiptForm } from 'utils/format';
import ReceiptAccordionContentItem from './ReceiptAccordionContentItem';

type Props = {
  style?: any;
};
function ReceiptAccordionContent({ style }: Props) {
  const dispatch = useAppDispatch();
  const { passportNumber, nationality, refundAfter } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [latest, setLatest] = useState(true);

  const {
    isLoading,
    mutate: receiptMutate,
    data,
  } = useMutation<ReceiptResponseType, AxiosError, IReceiptPayload>(
    (payload) => getReceipt(payload),
    {
      retry: false,
      onSuccess: (data) => {
        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: {
            haveSeenReceipt: true,
            printData: formatPrintReceiptForm(data!, passportNumber as string),
          },
        });
      },
    }
  );
  useEffect(() => {
    receiptMutate({
      passportNumber,
      latest,
      refundAfter,
    });
  }, [latest, refundAfter]);

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <FlexWrap dir='column' style={style}>
          {data && (
            <>
              <Filter justifyContent='flex-end'>
                <Sort isActive={latest} onClick={() => setLatest(true)}>
                  {nationality === 'KOR' ? '최신순' : 'Latest'}
                </Sort>
                <Sort>ㅣ</Sort>
                <Sort isActive={!latest} onClick={() => setLatest(false)}>
                  {nationality === 'KOR' ? '과거순' : 'Past'}
                </Sort>
              </Filter>
              {data?.map((item, idx) => (
                <ReceiptAccordionContentItem
                  key={idx}
                  data={item}
                  order={idx}
                />
              ))}
            </>
          )}
        </FlexWrap>
      )}
    </>
  );
}

const Filter = styled(FlexWrap)`
  width: 100%;
  position: relative;
`;
const Sort = styled.button<{ isActive?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.isActive ? '#000000' : '#9FA1A7')};
  padding: 0 7px;
`;
export default ReceiptAccordionContent;

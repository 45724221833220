import styled from 'styled-components';

import PhoneImg from 'assets/refundProcedure/banner.png';
import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';

function TopSection() {
  const { nationality } = useAppSelector((state) => state);
  return (
    <Banner dir='column' alignItems='center'>
      <BannerSubTitle>
        {nationality === 'KOR' ? '송금으로 가볍게 받는' : 'Easy Mobile'}
      </BannerSubTitle>
      <BannerTitle>
        {nationality === 'KOR' ? 'KTP 텍스 리펀' : 'KTP Tax Refund'}
      </BannerTitle>
      <BannerImg src={PhoneImg} />
    </Banner>
  );
}

const Banner = styled(FlexWrap)`
  background-color: #f4f8ff;
  height: 100%;
  width: 375px;
  overflow: hidden;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    margin-top: -8px;
  }
`;
const BannerImg = styled.img`
  width: 110%;
`;
const BannerSubTitle = styled.h3`
  color: #3a3b3e;
  font-size: 18px;
  margin-top: 44px;
  line-height: 150%;
`;
const BannerTitle = styled.h2`
  color: #246cf6;
  font-size: 36px;
  line-height: 150%;
  font-weight: 700;
`;
export default TopSection;

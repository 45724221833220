export const transferMethodChn = ['Ali Pay'];

export const chnBankList = [
  '中国银行 BOC',
  '中国工商银行 ICBC',
  '华夏银行 HXB',
  '中国农业银行 ABC',
  '中国建设银行 CCB',
  '交通银行 BOCOM',
  '光大银行 CEB',
  '上海银行 SHB',
  '临商银行 LSB',
];

export const chnBankTraditionList = [
  '中國銀行 BOC',
  '中國工商銀行 ICBC',
  '華夏銀行 HXB',
  '中國農業銀行 ABC',
  '中國建設銀行 CCB',
  '交通銀行 BOCOM',
  '光大銀行 CEB',
  '上海銀行 SHB',
  '臨商銀行 LSB',
];

export const chnBankabbreviationList = [
  'BOC',
  'ICBC',
  'HXB',
  'ABC',
  'CCB',
  'BOCOM',
  'CEB',
  'SHB',
  'LSB',
];

export const hkgBankList = [
  'AAREAL BANK AG, WIESBADEN, GERMANY [552]',
  'ABN AMRO BANK N.V. [307]',
  'AGRICULTURAL BANK OF CHINA LIMITED FRANKFURT BRANCH [A10]',
  'AGRICULTURAL BANK OF CHINA LIMITED [222]',
  'AGRICULTURAL BANK OF CHINA LIMITED, SINGAPORE BRANCH [340]',
  'AGRICULTURAL BANK OF CHINA, BEIJING [400]',
  'AIRSTAR BANK LIMITED [395]',
  'ALLAHABAD BANK [256]',
  'ALLIED BANKING CORPORATION (HK) LTD. [402]',
  'ANT BANK (HONG KONG) LIMITED [393]',
  'AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD [152]',
  'AXIS BANK LIMITED [261]',
  'BANCA MONTE DEI PASCHI DI SIENA S.P.A. - HONG KONG BRANCH [234]',
  'BANCO BILBAO VIZCAYA ARGENTARIA, S.A. [147]',
  'BANCO DO BRASIL S. A., TOKYO BRANCH [356]',
  'BANCO SANTANDER, S.A. [267]',
  'BANGKOK BANK PUBLIC COMPANY LTD [049]',
  'BANK FOR FOREIGN TRADE OF VIETNAM [553]',
  'BANK J. SAFRA SARASIN LTD [278]',
  'BANK JULIUS BAER & CO. LTD. [320]',
  'BANK OF AMERICA, NATIONAL ASSOCIATION [055]',
  'BANK OF BARODA [259]',
  'BANK OF CHINA (ELUOSI) [283]',
  'BANK OF CHINA (HONG KONG) LIMITED [012]',
  'BANK OF CHINA (HONG KONG) LIMITED [014]',
  'BANK OF CHINA (HONG KONG) LIMITED [019]',
  'BANK OF CHINA (HONG KONG) LIMITED [026]',
  'BANK OF CHINA (HONG KONG) LIMITED [030]',
  'BANK OF CHINA (HONG KONG) LIMITED [031]',
  'BANK OF CHINA (HONG KONG) LIMITED [033]',
  'BANK OF CHINA (HONG KONG) LIMITED [036]',
  'BANK OF CHINA (HONG KONG) LIMITED [064]',
  'BANK OF CHINA (HONG KONG) LIMITED [070]',
  'BANK OF CHINA (HUNGARY) CLOSE LIMITED [290]',
  'BANK OF CHINA (MALAYSIA) BERHAD [282]',
  'BANK OF CHINA LIMITED JAKARTA BRANCH [284]',
  'BANK OF CHINA LIMITED JOHANNESBURG BRANCH [298]',
  'BANK OF CHINA LIMITED LUXEMBOURG BRANCH [301]',
  'BANK OF CHINA LIMITED MANILA BRANCH [279]',
  'BANK OF CHINA LIMITED MILAN BRANCH [291]',
  'BANK OF CHINA LIMITED NEW YORK BRANCH [304]',
  'BANK OF CHINA LIMITED PARIS BRANCH [303]',
  'BANK OF CHINA LIMITED PHNOM PENH BRANCH [327]',
  'BANK OF CHINA LIMITED SEOUL BRANCH [285]',
  'BANK OF CHINA LIMITED SINGAPORE BRANCH [286]',
  'BANK OF CHINA LIMITED SYDNEY BRANCH [288]',
  'BANK OF CHINA LIMITED TAIPEI BRANCH [A00]',
  'BANK OF CHINA LIMITED TOKYO BRANCH [280]',
  'BANK OF CHINA LIMITED, HONG KONG BRANCH [338]',
  'BANK OF CHINA LIMITED, PANAMA BRANCH [311]',
  'BANK OF CHINA LONDON BRANCH [292]',
  'BANK OF CHINA LTD. - ABU DHABI [A12]',
  'BANK OF CHINA, LUXEMBOURG S.A. [300]',
  'BANK OF COMMUNICATIONS CO., LTD. HEAD OFFICE, SHANGHAI [428]',
  'BANK OF COMMUNICATIONS CO., LTD. SHENZHEN BRANCH [429]',
  'BANK OF COMMUNICATIONS CO., LTD. [027]',
  'BANK OF COMMUNICATIONS HONG KONG LIMITED [382]',
  'BANK OF COMMUNICATIONS SEOUL BRANCH [349]',
  'BANK OF HANGZHOU, HANGZHOU [555]',
  'BANK OF INDIA [058]',
  'BANK OF JIANGSU CO. LTD., NANJING [506]',
  'BANK OF MONTREAL [086]',
  'BANK OF MONTREAL, GUANGZHOU BRANCH [542]',
  'BANK OF NINGBO CO. LTD., ZHEJIANG [478]',
  'BANK OF SHANGHAI (HONG KONG) LIMITED [372]',
  'BANK OF SINGAPORE LIMITED [272]',
  'BANK OF TAIWAN [201]',
  'BANK SINOPAC [241]',
  'BANK SINOPAC [244]',
  'BANK SINOPAC, LOS ANGELES BRANCH [355]',
  'BANK SINOPAC, TAIPEI [431]',
  'BARCLAYS BANK PLC [074]',
  'BDO UNIBANK, INC. [067]',
  'BDO UNIBANK, INC. [BNORHKHH]',
  'BNP PARIBAS SECURITIES SERVICES [275]',
  'BNP PARIBAS WEALTH MANAGEMENT [240]',
  'BNP PARIBAS [056]',
  'BOC CREDIT CARD (INTERNATIONAL) LIMITED [252]',
  'BSI LTD [350]',
  'CAMBODIA MEKONG BANK PUBLIC LIMITED [351]',
  'CANADIAN IMPERIAL BANK OF COMMERCE [092]',
  'CANADIAN IMPERIAL BANK OF COMMERCE, CANADA [366]',
  'CANARA BANK [262]',
  'CATHAY BANK [263]',
  'CATHAY UNITED BANK COMPANY, LIMITED [236]',
  'CHANG HWA COMMERCIAL BANK LTD [206]',
  'CHANGSHA CITY COMMERCIAL BANK [529]',
  'CHINA CITIC BANK INTERNATIONAL LIMITED [018]',
  'CHINA CITIC BANK INTERNATIONAL LIMITED [051]',
  'CHINA CITIC BANK, BEIJING [437]',
  'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [009]',
  'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [CCBQHKAXWHS]',
  'CHINA CONSTRUCTION BANK CORPORATION [221]',
  'CHINA CONSTRUCTION BANK SEOUL BRANCH [358]',
  'CHINA DEVELOPMENT BANK CORPORATION [276]',
  'CHINA EVERBRIGHT BANK CO., LTD [368]',
  'CHINA EVERBRIGHT BANK, BEIJING [434]',
  'CHINA GUANGFA BANK CO. LTD, BEIJING [447]',
  'CHINA GUANGFA BANK CO., LTD. GUANGZHOU [549]',
  'CHINA MERCHANTS BANK CO., LTD, SINGAPORE BRANCH [A09]',
  'CHINA MERCHANTS BANK CO., LTD. [238]',
  'CHINA MERCHANTS BANK, HEAD OFFICE, SHENZHEN [435]',
  'CHINA MINSHENG BANKING CORP., LTD. [353]',
  'CHINA MINSHENG BANKING CORPORATION LTD., BEIJING [436]',
  'CHINA RESOURCES BANK OF ZHUHAI CO LTD., ZHUHAI [507]',
  'CHIYU BANKING CORPORATION LTD [039]',
  'CHONG HING BANK LTD [041]',
  'CIMB BANK BERHAD [374]',
  'CIMB THAI BANK PUBLIC COMPANY LIMITED [343]',
  'CITIBANK (CHINA) CO LTD, SHENZHEN BRANCH [531]',
  'CITIBANK (HONG KONG) LIMITED [250]',
  'CITIBANK N.A. [006]',
  'COMMERZBANK AG [097]',
  'COMMONWEALTH BANK OF AUSTRALIA [153]',
  'CONCORD BANK LTD., NINGBO [524]',
  'CONTINUOUS LINKED SETTLEMENT BANK INTERNATIONAL [868]',
  'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. (TRADIN [323]',
  'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. [183]',
  'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A.(TRADING [319]',
  'COUTTS & CO LTD [205]',
  'COUTTS AND CO. LTD., SINGAPORE [513]',
  'CREDIT AGRICOLE (SUISSE) S.A. [339]',
  'CREDIT AGRICOLE CIB [CRLYHKHH]',
  'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK [005]',
  'CREDIT SUISSE AG [233]',
  'CTBC BANK CO., LTD. [229]',
  'DAH SING BANK LTD [040]',
  'DBS BANK (HONG KONG) LIMITED [016]',
  'DBS BANK LTD, HONG KONG BRANCH (INCORPORATED IN SINGAPORE WI [DBSSHKHH]',
  'DEUTSCHE BANK AG [054]',
  'DNB NOR BANK ASA, SINGAPORE BRANCH [328]',
  'DONGGUAN RURAL CREDIT COOPERATIVES UNION, DONGGUAN [439]',
  'DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK [113]',
  'E.SUN COMMERCIAL BANK, LTD [243]',
  'E.SUN COMMERCIAL BANK, LTD., TAIPEI [557]',
  'EAST WEST BANK [258]',
  'EDMOND DE ROTHSCHILD (SUISSE) S.A. [346]',
  'EFG BANK AG [237]',
  'ERSTE GROUP BANK AG [227]',
  'EXPORT-IMPORT BANK OF THAILAND, BANGKOK [440]',
  'FALCON PRIVATE BANK LTD. [253]',
  'FAR EASTERN INTERNATIONAL BANK [260]',
  'FINABANK N.V. [362]',
  'FIRST COMMERCIAL BANK [203]',
  'FUBON BANK (HONG KONG) LIMITED [128]',
  'FUSION BANK LIMITED [391]',
  'GRANIT BANK ZRT [325]',
  'GUIYANG COMMERCIAL BANK, GUIYANG [540]',
  'HABIB BANK ZURICH (HONG KONG) LIMITED [322]',
  'HANA BANK [112]',
  'HANG SENG BANK LTD [024]',
  'HDFC BANK LTD. [308]',
  'HKT Payment Limited [935]',
  'HONG KONG MONETARY AUTHORITY [888]',
  'HONG LEONG BANK BERHAD [248]',
  'HSBC - THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD [004]',
  'HSBC BANGLADESH [450]',
  'HSBC BANK (TAIWAN) LIMITED [464]',
  'HSBC BANK MALAYSIA BERHAD [457]',
  'HSBC BANK PLC [347]',
  'HSBC BANK USA, NATIONAL ASSOCIATION [106]',
  'HSBC BANK VIETNAM LTD [466]',
  'HSBC BRUNEI [451]',
  'HSBC INDIA [452]',
  'HSBC INDONESIA [453]',
  'HSBC JAPAN [454]',
  'HSBC MANILA [458]',
  'HSBC PRIVATE BANK (SUISSE) SA [235]',
  'HSBC SEOUL BRANCH [460]',
  'HSBC SINGAPORE [462]',
  'HSBC SRI LANKA [463]',
  'HSBC THAILAND [465]',
  'HUA NAN COMMERCIAL BANK LTD [198]',
  'HUA NAN COMMERCIAL BANK LTD., TAIPEI [550]',
  'HUA XIA BANK, BEIJING [468]',
  'ICBC STANDARD BANK PLC [341]',
  'ICICI BANK LIMITED [251]',
  'INDIAN OVERSEAS BANK [050]',
  'INDIAN OVERSEAS BANK, HONG KONG [IOBAHKHHTST]',
  'INDIAN OVERSEAS BANK, HONG KONG [IOBAHKHH]',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [029]',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [072]',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD [330]',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED [214]',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA, BEIJING [469]',
  'INDUSTRIAL BANK CO., LTD. HONG KONG BRANCH [FJIBHKHH]',
  'INDUSTRIAL BANK CO., LTD. [377]',
  'INDUSTRIAL BANK COMPANY LTD., FUZHOU [445]',
  'INDUSTRIAL BANK OF KOREA [271]',
  'INDUSTRIAL BANK OF KOREA, HONG [IBKOHKHH]',
  'INDUSTRIAL BANK OF TAIWAN CO., LTD. [274]',
  'INDUSTRIAL BANK OF TAIWAN, TAIPEI [470]',
  'ING BANK N.V. [145]',
  'INTESA SANPAOLO S.P.A. [161]',
  'J.P. MORGAN SECURITIES (ASIA PACIFIC) LIMITED [375]',
  'JIANG YIN RURAL COMMERCIAL BANK, JIANG YIN CITY, JIANG SU [541]',
  "JOINT STOCK COMMERCIAL BANK 'LANTA-BANK' (CJSC) [317]",
  'JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT O [407]',
  'JPMORGAN CHASE BANK, N.A., HONG KONG BRANCH [CHASHKHP863]',
  'JPMORGAN CHASE BANK, N.A., HONG KONG [007]',
  'JSC AB (BANK OF CHINA KAZAKHSTAN) [305]',
  'JSC CAPITAL BANK [A06]',
  'KASIKORNBANK PUBLIC COMPANY LIMITED [380]',
  'KBC BANK N.V. [178]',
  'KDB ASIA LIMITED [318]',
  'KEB ASIA FINANCE LIMITED [363]',
  'KEB HANA BANK [KOEXHKHH]',
  'KOREA EXCHANGE BANK [046]',
  'KOREA EXCHANGE BANK, KOREA [A02]',
  'LAND BANK OF TAIWAN CO., LTD. HONG KONG [LBOTHKHH]',
  'LAND BANK OF TAIWAN CO., LTD. [264]',
  'LGT BANK AG [342]',
  'LGT BANK AG, HONG KONG BRANCH [BLFLHKHH]',
  'LIVI BANK LIMITED [388]',
  'LIVI BANK LIMITED [LIVIHKHHXXX]',
  'LLOYDS TSB PACIFIC LIMITED [LOYDHKHH]',
  'MACQUARIE BANK LIMITED [344]',
  'MANULIFE (INTERNATIONAL) LIMITED [MFCTHKHHXXX]',
  'MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY [379]',
  'MASHREQBANK PSC [352]',
  'MAYBANK (MALAYAN BANKING BERHAD) [063]',
  'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. [242]',
  'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD., HK [ICBCHKHH]',
  'MELLI BANK PLC [254]',
  'METROPOLITAN BANK & TRUST COMPANY [295]',
  'MITSUBISHI UFJ TRUST AND BANKING CORPORATION [138]',
  'MIZUHO BANK, LTD. [109]',
  'MUFG BANK, LTD. [BOTKHKHH]',
  'Mox Bank Limited [389]',
  'NANYANG COMMERCIAL BANK LTD [043]',
  'NATIONAL AUSTRALIA BANK LTD [150]',
  'NATIONAL BANK OF ABU DHABI [277]',
  'NATIONAL BANK OF CANADA [331]',
  'NATIONAL BANK OF PAKISTAN [060]',
  'NATIXIS [210]',
  'OCBC WING HANG BANK LIMITED [035]',
  'OCBC WING HANG BANK LIMITED [044]',
  'ORIENT EXPRESS BANK [367]',
  'ORIX ASIA LIMITED [373]',
  'OVERSEA-CHINESE BANKING CORPORATION LTD [022]',
  'PHILIPPINE NATIONAL BANK [119]',
  'PICTET & CIE (EUROPE) S.A. [357]',
  'PING AN BANK CO., LTD, SHENZHEN [485]',
  'PORTIGON AG [218]',
  'PT BANK CENTRAL ASIA TBK, INDONESIA [281]',
  'PT. BANK MANDIRI (PERSERO) TBK, HONG KONG BRANCH [480]',
  'PT. BANK NEGARA INDONESIA (PERSERO) TBK. [066]',
  'PUBLIC BANK (HONG KONG) LIMITED [028]',
  'PUNJAB NATIONAL BANK [266]',
  'RAIFFEISEN BANK INTERNATIONAL AG [370]',
  'RAIFFEISEN BANK INTERNATIONAL AG, SINGAPORE BRANCH [354]',
  'ROYAL BANK OF CANADA [080]',
  'SAIGON THUONG TIN COMMERCIAL JOINT STOCK BANK [296]',
  'SHANGHAI COMMERCIAL BANK LTD [025]',
  'SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD [345]',
  'SHANGHAI PUDONG DEVELOPMENT BANK [SPDBHKHH]',
  'SHANGHAI PUDONG DEVELOPMENT BANK, SHANGHAI [484]',
  'SHENZHEN DEVELOPMENT BANK, SHENZHEN [486]',
  'SHENZHEN RURAL COMMERCIAL BANK [509]',
  'SHINHAN BANK [273]',
  'SKANDINAVISKA ENSKILDA BANKEN AB [316]',
  'SOCIETE GENERALE BANK & TRUST [255]',
  'SOCIETE GENERALE [081]',
  'STANDARD CHARTERED BANK (HONG KONG) LIMITED - EURO CLEARING [818]',
  'STANDARD CHARTERED BANK (HONG KONG) LIMITED [003]',
  'STANDARD CHARTERED BANK HONG KONG BRANCH [249]',
  'STANDARD CHARTERED BANK PLC, LONDON [536]',
  'STANDARD CHARTERED BANK, BANGKOK [521]',
  'STANDARD CHARTERED BANK, BEIJING BRANCH [489]',
  'STANDARD CHARTERED BANK, JAKARTA BRANCH [515]',
  'STANDARD CHARTERED BANK, MALAYSIA BERHAD [519]',
  'STANDARD CHARTERED BANK, NANJING BRANCH [490]',
  'STANDARD CHARTERED BANK, PHILIPPINES BRANCH [517]',
  'STANDARD CHARTERED BANK, SEOUL KOREA [518]',
  'STANDARD CHARTERED BANK, SHANGHAI BRANCH [491]',
  'STANDARD CHARTERED BANK, SHENZHEN BRANCH [492]',
  'STANDARD CHARTERED BANK, SINGAPORE BRANCH [516]',
  'STANDARD CHARTERED BANK, SRI LANKA [522]',
  'STANDARD CHARTERED BANK, TAIWAN [520]',
  'STANDARD CHARTERED BANK, TIANJIN BRANCH [493]',
  'STANDARD CHARTERED BANK, TOKYO [A07]',
  'STANDARD CHARTERED BANK, XIAMEN BRANCH [494]',
  'STANDARD CHARTERED BANK, ZHUHAI BRANCH [495]',
  'STATE BANK OF INDIA [082]',
  'STATE STREET BANK AND TRUST COMPANY [220]',
  'SUMITOMO MITSUI BANKING CORPORATION [065]',
  'SUMITOMO MITSUI TRUST BANK, LIMITED [371]',
  'SUNNY BANK, TAIPEI [496]',
  'SVENSKA HANDELSBANKEN AB (PUBL) [165]',
  'TA CHONG BANK, LTD. [378]',
  'TAI SANG BANK LTD [061]',
  'TAI YAU BANK LTD [038]',
  'TAICHUNG COMMERCIAL BANK, TAICHUNG [498]',
  'TAIPEI FUBON COMMERCIAL BANK CO., LTD. [239]',
  'TAISHIN INTERNATIONAL BANK CO., LTD [245]',
  'TAISHIN INTERNATIONAL BANK, TAIPEI [499]',
  'TAIWAN BUSINESS BANK HONG KONG BRANCH [230]',
  'TAIWAN BUSINESS BANK HONG KONG BRANCH [MBBTHKHH]',
  'TAIWAN COOPERATIVE BANK, HONG KONG BRANCH [TACBHKHH]',
  'TAIWAN COOPERATIVE BANK, LTD. [265]',
  'TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD. [337]',
  'TAIWAN SHIN KONG COMMERCIAL BANK COMPANY LIMITED, TAIPEI [474]',
  'THE BANK OF BERMUDA LIMITED, HONG KONG BRANCH [502]',
  'THE BANK OF EAST ASIA, LTD [015]',
  'THE BANK OF NEW YORK MELLON [139]',
  'THE BANK OF NOVA SCOTIA [076]',
  'THE BANK OF NOVA SCOTIA, SINGAPORE [329]',
  'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD. [047]',
  'THE CHIBA BANK LTD [170]',
  'THE CHUGOKU BANK LTD [202]',
  'THE HACHIJUNI BANK, LTD [188]',
  'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - USD CLEA [828]',
  'THE HOUSING BANK FOR TRADE AND FINANCE, JORDAN [554]',
  'THE IYO BANK LTD [224]',
  'THE ROYAL BANK OF SCOTLAND N.V. [360]',
  'THE ROYAL BANK OF SCOTLAND PLC [008]',
  'THE RURAL CREDIT COOPERATIVES UNION OF SHUNDE [528]',
  'THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD [269]',
  'THE SHIGA BANK, LTD. [199]',
  'THE SHIZUOKA BANK, LTD [186]',
  'THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED [487]',
  'THE TORONTO-DOMINION BANK [085]',
  'TMB BANK PUBLIC COMPANY LIMITED, BANGKOK [500]',
  'TMB BANK PUBLIC COMPANY LIMITED, HONG KONG [501]',
  'TURK EKONOMI BANKASI A.S. [312]',
  'UBS AG, HONG KONG [103]',
  'UCO BANK [045]',
  'UNICREDIT BANK AG HONG KONG BRANCH [BVBEHKHH]',
  'UNICREDIT BANK AG [164]',
  'UNION BANK OF INDIA [268]',
  'UNITED OVERSEAS BANK LTD [071]',
  'UNITED SUBURBAN AND RURAL CREDIT COOPERATIVE WUXI, WUXI [504]',
  'VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE [293]',
  'VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK, HANOI [505]',
  'WELAB BANK LIMITED [390]',
  'WELLS FARGO BANK, N.A. [180]',
  'WELLS FARGO BANK, N.A., LONDON BRANCH [297]',
  'WESTPAC BANKING CORPORATION [151]',
  'WING LUNG BANK LTD [020]',
  'WOORI BANK [118]',
  'WOORI BANK, KOREA [A05]',
  'XIAMEN BANK CO., LTD., XIAMEN [547]',
  'ZA BANK LIMITED [387]',
  'ZA BANK LIMITED [AABLHKHH]',
  'ZAO INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MOSCOW) [A04]',
  'ZIBO CITY COMMERCIAL BANK, SHANDONG [525]',
];

type HkgInfoListType = {
  [key: string]: {
    streetList: string[];
  };
};

export const hkgInfoList: HkgInfoListType = {
  Hongkong: {
    streetList: [
      'Central and Western',
      'Eastern',
      'Southern',
      'Wan Chai',
      'Direct Input',
    ],
  },
  Kowlong: {
    streetList: [
      'Kowlong City',
      'Kwun Tong',
      'Sham Shui Po',
      'Wong Tai Sin',
      'Yau Tsim Mong',
      'Direct Input',
    ],
  },
  'The New Territories': {
    streetList: [
      'Islands',
      'Kwai Tsing',
      'North',
      'Sai Kung',
      'Sha Tin',
      'Tai Po',
      'Tsuen Wan',
      'Then Mun',
      'Yuen Long',
      'Direct Input',
    ],
  },
};

export const usaBankList = ['Bank Transfer Anywhere'];

export const jpnBankList = [
  '77BANK',
  'Abashiri Shinkin bank',
  'Abukuma Shinkin bank',
  'AEON BANK,LTD.',
  "Aichi Doctor's Credit Cooperative",
  'Aichi Iryou Credit Union',
  'Aichi Police Credit Union',
  'Aichi Shinkin bank',
  'Aichi Shogin',
  'Aichiken Chuo Credit Cooperative',
  'Aizu Commerce and Industry Credit Union',
  'Aizu Shinkin bank',
  'Akagi Credit Union',
  'Akita Shinkin bank',
  'Akita-Kenshin',
  'Alps Chuo Shinkin Bank',
  'Amagasaki Shinkin Bank',
  'Amakusa Shinkin bank',
  'Amami Shinkumi',
  'Amamioshima Shinkin bank',
  'Anan Shinkin bank',
  'Aoi Mori Shinkin bank',
  'Aomoriken Credit Union',
  'Aozora Bank, Ltd.',
  'Arai Shinkin bank',
  'Asahi Shimbun Credit Cooperative',
  'Asahi Shinkin bank',
  'Asahikawa Shinkin Bank',
  'ASHIKAGA BANK',
  'ASHIKAGAOYAMA SHINKIN BANK',
  'ASUKA CREDIT COOPERATIVE',
  'au Jibun Bank Corporation.',
  'Awa Bank',
  'Awaji Shinkin bank',
  'Bank of America',
  'Bank of The Ryukyus,Ltd.',
  'Bank of Toyama',
  'Banshu Shinkin bank',
  'Bihoku Shinkin Bank',
  'Bingo Credit Union',
  'Bisai Shinkin bank',
  'Bizen Hinase Shinkin bank',
  'Bnp Paribas',
  'Boshin',
  'Bunka-Sangyou Credit Union',
  'Chiba Bank',
  'Chiba Kogyo Bank',
  'CHIBA Shinkin bank',
  'Chikugo Shinkin bank',
  'Chikuho Bank',
  'Chita Shinkin Bank',
  'Chogin-Nishi Shinkumi',
  'Choshi Shoko Credit Union',
  'Chuei Shinkin bank',
  'Chugoku Bank',
  'Chugoku Rokin',
  'Chunan Shinkin bank',
  'CHUNICHI SHINKIN BANK',
  'Chuo Labour Bank(Chuo Rokin)',
  'Chuo Shinkumi',
  'Citibank, N.A.',
  'Community Bank Senpoku',
  'Community Bank Tokyokosei',
  'Custody Bank of Japan, Ltd.',
  'Daichi Mirai Shinkin bank',
  'Daido Credit Union',
  'Dai-Ichi Kangyo Credit Cooperative',
  'Daishi Hokuetsu Bank, Ltd.',
  'Daitokyo Credit Union',
  'Daiwa Next Bank, Ltd.',
  'Danyo Credit Union',
  'Date Shinkin Bank',
  'Deutsche Bank',
  'Donan Umimachi Shinkin bank',
  'Echigo Joetsu',
  'Echizen',
  'Ehime Shinkin Bank',
  'Engaru Shinkin bank',
  'First Bank Miyashin',
  'First Bank of Toyama,Ltd.',
  'Fuji Shinkin Bank',
  'Fujinomiya',
  'Fukue Credit Union',
  'Fukui Bank',
  'Fukuiken Ishi Credit Union',
  'Fukuoka Bank',
  'Fukuoka Hibiki Shinkin bank',
  'Fukuoka Ishi Credit Union',
  'Fukuoka Shinkin bank',
  'Fukuokaken Cho Credit Union',
  'Fukuokaken Shinkumi',
  'Fukusen Shinkumi',
  'Fukushima Bank.',
  'Fukushima Prefecture Commerce and industry',
  'Fukushima Shinkin bank',
  'Furukawa Credit Cooperative',
  'Gamagori Shinkin bank',
  'Gifu Ishi Shinkumi',
  'Gifu Shinkin bank',
  'Gifu Shoko Shinkumi',
  'GMO Aozora Net Bank, Ltd',
  'Gunma Bank',
  'Gunmaken Credit Union',
  'Gunmaken Ishi Credit Union',
  'Gunmamirai Shinkumi Bank',
  'Habataki Shinkumi',
  'Hachijuni Bank',
  'Hachiman Shinkin bank',
  'Hagi・Yamaguchi Shinkin bank',
  'Hakodate Shoko Credit Union',
  'Hakusan Shinkin Bank',
  'HAMAMATSU IWATA SHINKIN BANK',
  'Hana Credit Union',
  'Hanamaki Shinkin bank',
  'Handa Shinkin Bank',
  'Hanno Shinkin Bank',
  'Hata Shinkin bank',
  'Hida Shinkumi',
  'Hidaka Shinkin bank',
  'Higashiyamaguchi-Shinkinbank',
  'Higo Bank',
  'Himawari Shinkin bank',
  'Himeji Shinkin Bank',
  'Himifushiki',
  'Hirakata Shinkin bank',
  'Hiroshima Bank',
  'Hiroshima Midori Shinkin bank',
  'Hiroshima Shogin Credit Union',
  'Hiroshimaken Credit Cooperative',
  'Hiroshimashi Credit Cooperative',
  'Hiroshimashinkin bank',
  'Hita Shinkin bank',
  'Hokkaido Rokin',
  'Hokkaido Shinkin bank',
  'Hokkoku Bank',
  'Hokumon Shinkin bank',
  'Hokuoh Shinkumi',
  'Hokuriku Bank',
  'Hokuriku Labour Bank(Hokuriku Rokin)',
  'Hokusei Shinkin bank',
  'HSBC Japan',
  'Hyakugo Bank',
  'Hyakujushi Bank',
  'Hyogo Credit Union',
  'Hyogo Himawari Shinkumi Bank',
  'Hyogo Shinkin bank',
  'Hyogoken Iryou Credit Union',
  'Hyogoken Police Credit Union',
  'Ibaraki Shinkumi',
  'Ichii Shinkin bank',
  'Ichinoseki Shinkin bank',
  'Imari shinkin bank',
  'Io Shinkumi Bank',
  'Ishikawaken Ishi Credit Cooperative',
  'Ishinomaki Shinkin bank',
  'Ishinomaki Syokou Credit Union',
  'Isurugi',
  'Itoigawa Shinkumi',
  'Iwaki Shinkumi',
  'Iwate Doctor Credit Union',
  'Iyo Bank',
  'JA Agatsuma',
  'JA Aichi Ama',
  'JA Aichi Chita',
  'JA Aichi Higashi',
  'JA Aichi Kita',
  'JA Aichi Mikawa',
  'JA Aichi Minami',
  'JA Aichi Nishi',
  'JA Aichi Toyota',
  'JA Aichibito',
  'JA Aichichuo',
  'JA AIOI',
  'JA Aira',
  'JA Aizu Yotsuba',
  'JA Akagi Tachibana',
  'JA Akan',
  'JA Akashi',
  'JA Aki',
  'JA Akikawa',
  'JA Akita Furusato',
  'JA Akita Kita',
  'JA Akita Kotoh',
  'JA Akita Namahage',
  'JA Akita Obako',
  'JA Akita Shinsei',
  'JA Akita Shirakami',
  'JA Akita Takanosu',
  'JA Akita Yamamoto',
  'JA Alps',
  'JA Amahigashi',
  'JA Amakusa',
  'JA Amami',
  'JA Amarume',
  'JA Anan',
  'JA Aoba',
  'JA Aomori',
  'JA Aozora',
  'JA Arida',
  'JA Asahikawa',
  'JA Asakano',
  'JA Ashikaga',
  'JA Ashikita',
  'JA Ashoro',
  'JA Aso',
  'JA Atsugi',
  'JA Awa',
  'JA Awa Miyoshi',
  'JA Awaji Hinode',
  'JA AWAJISHIMA',
  'JA Awashi',
  'JA Ayacho',
  'JA Azumi',
  'JA Bank Aichi',
  'JA Bank Ehime',
  'JA Bank Fukui',
  'JA Bank Fukuoka',
  'JA Bank Gifu',
  'JA Bank Hiroshima',
  'JA Bank Hokkaido',
  'JA Bank Hyogo',
  'JA Bank Ibaraki',
  'JA Bank Ishikawa',
  'JA Bank Iwate',
  'JA Bank Kagawa',
  'JA Bank Kagoshima',
  'JA Bank Kanagawa',
  'JA Bank Kochi',
  'JA Bank Kyoto',
  'JA Bank Mie',
  'JA Bank Miyazaki',
  'JA Bank Nagano',
  'JA Bank Niigata',
  'JA Bank Oita',
  'JA Bank Osaka',
  'JA Bank Saga',
  'JA Bank Saitama',
  'JA Bank Shiga',
  'JA Bank Shizuoka',
  'JA Bank Tokushima',
  'JA Bank Tokyo',
  'JA Bank Tottori',
  'JA Bank Wakayama',
  'JA Bank Yamaguchi',
  'JA Beppu Hiji',
  'JA Bibai',
  'JA Biei',
  'JA Bihoro',
  'JA Biratori',
  'JA CERESA KAWASAKI',
  'JA Chiba Midori',
  'JA Chiba Mirai',
  'JA Chiba Toukatsu',
  'JA Chichibu',
  'JA Chikuma',
  'JA Chikuzen Asakura',
  'JA Chokuan',
  'JA Chosei',
  'JA Churui',
  'JA Crane',
  'JA Daihoku',
  'JA Date',
  'JA Dohoku Nayoro',
  'JA DOUOU',
  'JA DOUTOU ASAHI',
  'JA Ebinoshi',
  'JA Echigo Chuo',
  'JA Echigo Nagaoka',
  'JA Echigo Ojiya',
  'JA Echigo Santou',
  'JA Echizen Takefu',
  'JA Ehime Chuo',
  'JA Ehime Minami',
  'JA Ehime Mirai',
  'JA Ehime Taiki',
  'JA Enshu Chuo',
  'JA Enshuyumesaki',
  'JA Enyu',
  'JA Fruits Yamanashi',
  'JA Fuefuki',
  'JA Fukaya',
  'JA Fukuiken',
  'JA Fukumitsu',
  'JA Fukuoka Kaho',
  'JA Fukuoka Keichiku',
  'JA Fukuoka Ooki',
  'JA Fukuoka Yame',
  'JA FUKUOKASHI',
  'JA FUKUOKASHI TOUBU',
  'JA Fukushima Sakura',
  'JA Fukuyama',
  'JA Furano',
  'JA Furukawa',
  'JA Gamagori',
  'JA Geinan',
  'JA Gifu',
  'JA Goshotsugaru',
  'JA Goto',
  'JA Green Nagano',
  'JA Green Ohmi',
  'JA Green Osaka',
  'JA Hachinohe',
  'JA Hachioji',
  'JA Hadano',
  'JA Hagano',
  'JA Hagukumi',
  'JA Hainan',
  'JA Hakodate-Kameda',
  'JA Hakui',
  'JA Hakusan',
  'JA Hamanaka',
  'JA Hamayu',
  'JA Hamochi',
  'JA Hanamaki',
  'JA Hanazono',
  'JA HARENOKUNIOKAYAMA',
  'JA Harima',
  'JA Hida',
  'JA Higashi Asahikawa',
  'JA Higashi Biwako',
  'JA Higashi Soya',
  'JA Higashi Tokushima',
  'JA Higashikagura',
  'JA Higashikawa',
  'JA Higashimino',
  'JA Higashine',
  'JA Higashinotogawa',
  'JA Higashiuwa',
  'JA Himawari',
  'JA Himi',
  'JA Hiroo',
  'JA Hiroshima Yutaka',
  'JA Hiroshimachuo',
  'JA Hiroshimahokubu',
  'JA Hiroshimashi',
  'JA Hisui',
  'JA Hitachi',
  'JA Hitachishitaga',
  'JA Hokota',
  'JA Hokusai',
  'JA Hombetsu',
  'JA Hondo Ituwa',
  'JA Horonobecho',
  'JA Hyogo Minami',
  'JA Hyogo Mirai',
  'JA Hyogo Nishi',
  'JA Hyogo Rokko',
  'JA Hyuga',
  'JA Ibaraki Asahi',
  'JA Ibaraki Minami',
  'JA Ibaraki Mutsumi',
  'JA IBARAKISHI',
  'JA Ibigawa',
  'JA Ibusuki',
  'JA Ichihara',
  'JA Ichikawa',
  'JA Iga Furusato',
  'JA Iki-shi',
  'JA IMABARITACHIBANA',
  'JA Imakane',
  'JA Imari',
  'JA Imizuno',
  'JA Inaba',
  'JA Inashiki',
  'JA Irumano',
  'JA Ise',
  'JA Ishikari',
  'JA Ishikawa Kahoku',
  'JA Ishinomaki',
  'JA Isumi',
  'JA ITANOGUN',
  'JA Itoshima',
  'JA Iwai',
  'JA Iwamizawa',
  'JA Iwanuma',
  'JA Iwate Esashi',
  'JA Iwate Furusato',
  'JA Iwate Hiraizumi',
  'JA Iwatechuoh',
  'JA Izumino',
  'JA JA Topia-Hamamatsu',
  'JA Jyousou Hikari',
  'JA Kaga',
  'JA Kagawaken',
  'JA Kagoshima izumi',
  'JA KAGOSHIMA KIMOTSUKI',
  'JA Kagoshima Mirai',
  'JA Kaifu',
  'JA Kakegawa',
  'JA Kakogawaminami',
  'JA Kami Yotsuba',
  'JA Kamihayashi',
  'JA KAMIKAWA CHUO',
  'JA Kamimashiki',
  'JA Kamishihoro',
  'JA Kamituga',
  'JA Kamoto',
  'JA Kanagawa Seisho',
  'JA Kanagawa Tsukui',
  'JA Kanazawa',
  'JA Kanazawa Chuo',
  'JA Kaneyama',
  'JA Kanra Tomioka',
  'JA Karatsu',
  'JA Kashiwazaki',
  'JA Kasuya',
  'JA Katori',
  'JA Kazuno',
  'JA Kenebetsu',
  'JA Kenouaikawa',
  'JA Kihoku kawakami',
  'JA KIKUCHI',
  'JA Kimitsu',
  'JA KIMOTSUKI-AIRA',
  'JA Kinan',
  'JA Kino',
  'JA Kinosato',
  'JA Kisarazu',
  'JA Kiso',
  'JA Kisyuu',
  'JA Kita Biwako',
  'JA Kita Hibiki',
  'JA Kita Ibuki',
  'JA Kita Ishikari',
  'JA Kita Okhotsk',
  'JA Kita Souya',
  'JA Kita Tsukuba',
  'JA Kita Uonuma',
  'JA Kitaechigo',
  'JA Kitafuji',
  'JA Kitagun Shibukawa',
  'JA Kitaharuka',
  'JA Kitahiyama',
  'JA Kitakawachi',
  'JA Kitakyu',
  'JA Kitamirai',
  'JA Kitaosaka',
  'JA KITASATSUMA',
  'JA Kitasorachi',
  'JA Kiyosato',
  'JA Kobayashi',
  'JA Kochi ken',
  'JA Kochishi',
  'JA Komachi',
  'JA Komatsu',
  'JA Koshigaya',
  'JA Koshimizu',
  'JA Koto',
  'JA Kouka',
  'JA Koyu',
  'JA KUKASYO',
  'JA Kuma',
  'JA Kumagaya',
  'JA Kumamoto Uki',
  'JA Kumamotoshi',
  'JA Kure',
  'JA Kurobe',
  'JA Kurume',
  'JA Kushimashiotsuka',
  'JA Kushiro Ota',
  'JA Kushiro Tancho',
  'JA Kyoto',
  'JA Kyoto Chuo',
  'JA Kyoto City',
  'JA Kyoto Ninokuni',
  'JA Kyoto Yamashiro',
  'JA Kyouwa',
  'JA Lake Ibuki',
  'JA Lake Shiga',
  'JA Machida',
  'JA Maebashi',
  'JA Makubetsu',
  'JA MASHUUKO',
  'JA Matsumoto Highland',
  'JA Matsuyama',
  'JA Matto',
  'JA Megumino',
  'JA Memanbetsu',
  'JA Memuro',
  'JA Michinoku Murayama',
  'JA Midori',
  'JA Mie Kita',
  'JA Mienaka',
  'JA Mihara',
  'JA Mii',
  'JA Mikkabi',
  'JA Mikumano',
  'JA Mima',
  'JA Minaho',
  'JA Minami Chikugo',
  'JA Minami Shinshu',
  'JA Minami Uonuma',
  'JA Minami-Alps-City',
  'JA Minamisatsuma',
  'JA Minds',
  'JA Minenobu',
  'JA Minori',
  'JA Mito',
  'JA Mitsuishi',
  'JA Miura',
  'JA MIYAGI SENNAN',
  'JA Miyagi Tome',
  'JA Miyagi Watari',
  'JA Miyakonojyo',
  'JA Miyazaki Chuo',
  'JA Miyoshi',
  'JA Mizuma',
  'JA Mogami-Chuo',
  'JA Monbetsu',
  'JA Munakata',
  'JA MYOUZAI',
  'JA Nagamine',
  'JA Nagano',
  'JA Nagano Yatsugatake',
  'JA Naganuma',
  'JA NAGASAKI KENOH',
  'JA Nagasaki Shihi',
  'JA NAGASAKISAIKAI',
  'JA Nagoya',
  'JA Nakanoshi',
  'JA Nakasatsunaimura',
  'JA Nakashibetsu',
  'JA Nakashun',
  'JA Namegatashiosai',
  'JA Namporo',
  'JA Nanohana',
  'JA Nansai',
  'JA Nansun',
  'JA Nanto',
  'JA Naraken',
  'JA Narita',
  'JA Narusawamura',
  'JA Nasu Minami',
  'JA Nasuno',
  'JA Natori Iwanuma',
  'JA Negami',
  'JA Niigata',
  'JA Niigata Iwafune',
  'JA Niigata Nankan',
  'JA Niji',
  'JA Nishi Inba',
  'JA Nishi Mino',
  'JA Nishi Tokyo',
  'JA Nishikasugai',
  'JA Nishimikawa',
  'JA Nishitama',
  'JA Nishiuwa',
  'JA Nitta Midori',
  'JA Nobeoka',
  'JA Nomi',
  'JA Nonoichi',
  'JA Noto Wakaba',
  'JA Obihiro Kawanishi',
  'JA Obihiro Taisho',
  'JA Ochi Imabari',
  'JA Oegun',
  'JA Ofunato',
  'JA OGATAMURA',
  'JA OHOTSUKU HAMANASU',
  'JA Ohzora',
  'JA Oirase',
  'JA Oita',
  'JA Oita-Oyama',
  'JA OKAYAMA',
  'JA Okhotsk Abashiri',
  'JA Okinawa',
  'JA Onomichi',
  'JA Ooigawa',
  'JA Ootsu Matsushige',
  'JA OSAKA NAKAKAWACHI',
  'JA Osakahokubu',
  'JA OSAKAMINAMI',
  'JA OSAKASENSYU',
  'JA OSAKASHI',
  'JA OSAKATOBU',
  'JA OSUZU',
  'JA Ota',
  'JA Otofuke',
  'JA Oura Tatebayashi',
  'JA Owari-chuoh',
  'JA Oyama',
  'JA Pinne',
  'JA Pippu',
  'JA Reihoku',
  'JA RIhoku',
  'JA Rikubetsu',
  'JA Saba Isezaki',
  'JA Sado',
  'JA Saga',
  'JA Sagae Nishimurayama',
  'JA Sagami',
  'JA Sagashi Cyuou',
  'JA Saikatsu',
  'JA Saikichuo',
  'JA Saitama',
  'JA Saitama Hibikino',
  'JA Saitama Mizuho',
  'JA Saitama Okabe',
  'JA SAITAMA-CHUO',
  'JA Saito',
  'JA Sakai City',
  'JA Sakata Sodeura',
  'JA Saku Asama',
  'JA Sano',
  'JA Sapporo',
  'JA Sarabetsu',
  'JA Saroma',
  'JA Satoura',
  'JA Satsuma Hioki',
  'JA Satsunai',
  'JA Satsuraku',
  'JA Seba',
  'JA Sendai',
  'JA Setagaya Meguro',
  'JA Shari',
  'JA Shibecha',
  'JA Shibetsu',
  'JA Shiga Gamou',
  'JA Shihoro',
  'JA Shika',
  'JA Shikaoi',
  'JA SHIMABARAUNZEN',
  'JA Shimane',
  'JA Shimizu',
  'JA SHIMOTSUKE',
  'JA Shin Hakodate',
  'JA Shin Hitachino',
  'JA Shin Iwate',
  'JA Shin Miyagi',
  'JA Shin Otaru',
  'JA Shin Sunagawa',
  'JA Shinjosi',
  'JA SHINSHINOTSU',
  'JA Shinshu Suwa',
  'JA Shinshu Ueda',
  'JA Shintoku',
  'JA Shionoya',
  'JA Shizuokashi',
  'JA Shobara',
  'JA Shonai Midori',
  'JA Shonai Tagawa',
  'JA Shonan',
  'JA Soo Kagoshima',
  'JA Sorachi Minami',
  'JA Soumamura',
  'JA Soya Minami',
  'JA SUIGOU TSUKUBA',
  'JA Suzu',
  'JA Suzuka',
  'JA Syuso',
  'JA Tagawa',
  'JA TAIKI',
  'JA Tainai',
  'JA Taisetsu',
  'JA Tajima',
  'JA Takachiho',
  'JA Takaoka',
  'JA Takasaki',
  'JA Takatsuki',
  'JA Takigun',
  'JA Takikawa',
  'JA Tamana',
  'JA Tamba Hikami',
  'JA Tamba Sasayama',
  'JA Taneyaku',
  'JA Tano Fujioka',
  'JA Tempakushinyo',
  'JA Tendo',
  'JA Tohma',
  'JA Tokachi Ikedacho',
  'JA Tokachi Shimizu',
  'JA Tokamachi',
  'JA Tokoro',
  'JA Tokushima Kita',
  'JA Tokushimashi',
  'JA Tokyo Aoba',
  'JA Tokyo Chuo',
  'JA Tokyo Midori',
  'JA Tokyo Mirai',
  'JA Tokyo Musashi',
  'JA Tokyo Smile',
  'JA Tokyominami',
  'JA TOMAKOMAI-KOUIKI',
  'JA Tomisato',
  'JA Tonamino',
  'JA Tone Numata',
  'JA Tosa Kuroshio',
  'JA Tottori Inaba',
  'JA Tottorichuou',
  'JA Tottoriseibu',
  'JA TOUKATSUCHUOU',
  'JA Touto',
  'JA Touzai Shirakawa',
  'JA Towada Oirase',
  'JA Toyako',
  'JA Toyama',
  'JA Toyohashi',
  'JA Toyokoro',
  'JA Tsuage',
  'JA Tsubetsu',
  'JA Tsugaru Hirosaki',
  'JA Tsugaru Mirai',
  'JA Tsugaru Nishikita',
  'JA Tsukigata',
  'JA Tsukubashi',
  'JA Tsukubashiyatabe',
  'JA Tsumagoi',
  'JA Tsunan',
  'JA Tsuruoka',
  'JA Tsushima',
  'JA Uchiura',
  'JA Ugo',
  'JA Ukawa',
  'JA Uma',
  'JA Uozu',
  'JA Urahoro',
  'JA Usui Annaka',
  'JA Utsunomiya',
  'JA WAKAYAMA',
  'JA Wakkanai',
  'JA Yachiyo',
  'JA Yamadamura',
  'JA Yamagata',
  'JA Yamagata Okitama',
  'JA Yamagata-shi',
  'JA YAMAGUCHI',
  'JA Yamanashi Mirai',
  'JA Yanagawa',
  'JA Yasato',
  'JA Yatsushiro',
  'JA Yoichi',
  'JA Yokohama',
  'JA Yokosuka Hayama',
  'JA Youtei',
  'JA Yubari',
  'JA Yubetsu',
  'JA Yume Minami',
  'JA Yuuki Aomori',
  'JAFukushima Mirai',
  'JA-KAMIINA',
  'Japan Post Bank.',
  'JA-Rumoi',
  'JF Marine Bank Aichi',
  'JF Marine Bank Chiba',
  'JF Marine Bank Ehime',
  'JF Marine Bank Fukuoka',
  'JF Marine Bank Fukushima',
  'JF Marine Bank Hiroshima',
  'JF Marine Bank Hokkaido',
  'JF Marine Bank Kagawa',
  'JF Marine Bank Kochi',
  'JF Marine Bank Kyoto',
  'JF Marine Bank Miyagi',
  'JF Marine Bank Nagisa',
  'JF Marine Bank Oita',
  'JF Marine Bank Shimane',
  'JF Marine Bank Tokushima',
  'JF Marine Bank Tottori',
  'JF Marine Bank Yamaguchi',
  'Joetsu Shinkin bank',
  'Johnan Shinkin bank',
  'JP Morgan Bank',
  'Juhachi-Shinwa Bank, Ltd.',
  'Juroku Bank',
  'Kagawaken Shinkumi Bank',
  'Kagoshima Bank',
  "Kagoshima Doctor's Credit Cooperative",
  'Kagoshima Kougyo Credit Union',
  'Kagoshima Shinkin bank',
  'Kagoshima Sogo Shinkin bank',
  'Kamo Shinkin bank',
  'Kanagawa Ishi Credit Union',
  'Kanagawaken Shikaishi Credit Union',
  'Kanazawa',
  'Kanazawa Chuo Shinkumi',
  'Kanonji Shinkin bank',
  'Kansai Mirai Bank, Ltd.',
  'Kanumasogo Shinkin bank',
  'KARASUYAMA SHINKIN BANK',
  'Karatsu Shinkin bank',
  'Kasaoka Shinkumi Bank',
  'Kashiwazaki Shinkin bank',
  'Kawaguchi-Shinkin Bank',
  'Kawanoe Shinkin bank',
  'Keiji Credit Union',
  'Keishicho―Shokuin Credit Cooperative',
  'Kesennuma Shinkin bank',
  'Kibi Shinkin Bank',
  'Kihoku Shinkin bank',
  'Kimitsu Credit Union',
  'Kinki Rokin',
  'Kinkisangyo Shinkumi Bank',
  'Kinokuni Shinkin bank',
  'Kiraboshi Bank',
  'Kirayaka Bank,Ltd.',
  'KIRYU　SHINKIN　BANK',
  'Kitagun Shinkumi',
  'kitaiseueno-shinkin bank',
  'Kitakami Shinkin bank',
  'Kitakyushu Bank',
  'Kitami Shinkin bank',
  'Kitaosaka Shinkin Bank',
  'Kitasorachi Shinkin Bank',
  'Kiyo Bank',
  'KOBE shinkin bank',
  'Kobeshishokuin Credit Cooperation',
  'Kochi Shinkin bank',
  'KOMATSUGAWA SHINKIN BANK',
  'Kono',
  'Koriyama Shinkin bank',
  'Koto Shinkin bank',
  'Kouei Credit Union',
  'Koutou Shinkumi',
  'Koza Shinkin bank',
  'Kumagaya Shouko Credit Union',
  'Kumamoto Chuo Shinkin bank',
  'Kumamoto Credit Union',
  'Kumamoto Dai-ichi Shinkin bank',
  'Kumamoto Shinkin bank',
  "Kumamotoken Doctor's Credit Cooperative",
  'Kurayoshi Shinkin bank',
  'Kure Shinkin Bank',
  'Kureshi Shokuin Credit Union',
  'Kushiro Shinkin bank',
  'Kushiro Shinyoukumiai Bank',
  'Kuwana Mie Shinkin bank',
  'Kyoei Shinkumi',
  'Kyoritsu Credit Cooporative',
  'Kyoto Chuo Shinkin Bank',
  'Kyoto Hokuto Shinkin Bank',
  'Kyushu Labor Bank',
  'Lawson Bank',
  'Mainichi Shinkumi',
  'Maki Shinkumi',
  'Maruhachi Credit Cooperative',
  'Masuda Shinkumi',
  'Matsumoto',
  'Meguro Shinkin Bank',
  'Mie Bank',
  'Minami Nippon Bank,Ltd.',
  'Minna Bank, Ltd.',
  'Mire Credit Union',
  'Mishima',
  'Mito Shinkin Bank',
  'Mitsubishi UFJ Trust and Banking Corporation',
  'Mitsui Sumitomo Bank',
  'Miyako Shinkin Bank',
  'Miyazaki Bank',
  'Miyazaki Dai-ichi Shinkin bank',
  'Miyazaki Nanbu Credit Union',
  'Mizuho Bank,Ltd.',
  'Mizuho Trust and Banking Co.,Ltd.',
  'Mizusawa Shinkin bank',
  'Mizushima Shinkin bank',
  'Moka Credit Union',
  'Momiji Bank,Ltd',
  'MORI NO MIYAKO SHINKIN BANK',
  'Morioka Shinkin Bank',
  'MUFG Bank, Ltd.',
  'Murakami Shinkin bank',
  'Muroran Shinkin Bank',
  'Musashino Bank',
  'Nagahama Shinkin bank',
  'Nagano Bank,Ltd.',
  'Nagano Rokin',
  'Nagano-ken Shinkumi Bank',
  'Nagaoka Shinkin bank',
  'Nagasaki Ishin Shinkumi',
  'Nagasaki Mitsubishi Shinkumi',
  'Nagoya Seikabutu Credit Union',
  'Nakahyogo Shinkin bank',
  'Nakanogo Shinkumi',
  'Nanto Bank',
  'Nara Chuo Shinkin bank',
  'Nara Shinkin bank',
  'Nasu Credit Union',
  'Nihonkai Shinkin bank',
  'Nihonmatsu Shinkin bank',
  'Niigata Credit Union',
  'Niigata Railway Credit Union',
  'Niigata Rokin',
  'Niigata Shinkin bank',
  'Niigata-Daei Shinkumi',
  'Niikawa',
  'NISHI CHUGOKU SHINKIN BANK',
  'NISHIHYOGO SHINKIN BANK',
  'Nishi-Nippon City Bank',
  'Nisshin Shinkin bank',
  'Nobeoka Shinkin bank',
  'North Pacific Bank,LTD.',
  'Notokyouei',
  'Nozomi Credit Union',
  'Numazu',
  'Obama',
  'obihiro shinkin bank',
  'Odawara Daiichi Credit Union',
  'Ogaki Kyoritsu Bank',
  'OGAKI SEINO SHINKIN BANK',
  'Ohkawa Shinkin bank',
  'Ohtawara Shinkin bank',
  'Oita Bank',
  'Oita Credit Union',
  'Oita Mirai Shinkin bank',
  'Oita Shinkin bank',
  'Okayama Shinkin bank',
  'Okazaki Shinkin bank',
  'Okinawa Kaiho Bank.',
  'Okinawa Rokin',
  'Ome Shinkin Bank',
  'Omuta Yanagawa Shinkin bank',
  'Onga Shinkin bank',
  'ORIX Bank Corporation',
  'Osaka Chochiku Credit Union',
  'Osaka Doctors Credit Union',
  'Osaka Kyoei Credit Union',
  'Osaka Police Credit Union',
  'Osaka Shinkin Bank',
  'Osaka-shoko Shinkin Bank',
  'Oshima Shinkin bank',
  'PayPay Bank Corporation',
  'Paypay Bank Corporation',
  'Rakuten Bank, Ltd.',
  'Resona Bank,Ltd.',
  'Rumoi Shinkin bank',
  'Ryoubi Credit Union',
  'Saga Higashi Credit Union',
  'Saga Nishi Shinkumi',
  "Saga Prefecture Doctor's Credit Cooperative",
  'Saga Shinkin bank',
  'Sagami Shinkin bank',
  'Sagamihara Agricultural Cooperatives',
  'Saikai Mizuki Credit Union',
  'Saitama Credit Union',
  'Saitama Doctor Bank',
  'Saitama Resona Bank,Ltd.',
  'Saitamaken Shinkin bank',
  'Sammugun Agricultural Cooperative',
  'San ju San Bank',
  'Sanjyou Credit Union',
  'SANO SHINKIN BANK',
  'Sapporo Chuo Credit Union',
  'SBI Sumishin Net Bank, Ltd.',
  'SBJ Bank',
  'Seikyo Shinkumi',
  'Seishin',
  'Seiwa Credit Union',
  'Seki Shinkin bank',
  'Sennan Shinkin bank',
  'Seto Shinkin bank',
  'Seven Bank,Ltd.',
  'Shibata Shinkin Bank',
  'Shichitou Credit Association',
  'Shiga Bank',
  'Shigachuo Shinkin bank',
  'Shigaken Credit Union',
  'Shigaken Min Shinkumi',
  'Shikoku Bank',
  'Shikoku Labour Bank(Shikoku Rokin)',
  'Shimada Kakegawa Shinkin Bank',
  'Shimanami Shinkin bank',
  'Shimane Chuo Shinkin bank',
  'Shimane Masuda Credit Union',
  'Shimane Shinkin bank',
  'Shimizu Bank',
  'Shingu Shinkin bank',
  'Shinhan Bank Japan',
  'Shinjo Shinkin bank',
  'Shinkin Central Bank',
  'Shinkumi Federation Bank',
  'Shinminato',
  'Shinsei Bank,Ltd.',
  'Shiozawa Shinkumi',
  'Shirakawa Shinkin bank',
  'Shizuoka Bank',
  'Shizuoka Rokin',
  'SHIZUOKA YAIZU SHINKIN BANK',
  'Shizuokaken-ishi Credit Cooperative',
  'Shoko Chukin Bank',
  'Shonan Shinkin Bank',
  'SHOWA SHINKIN BANK',
  'SMBC Trust Bank Ltd.',
  'Soai Shinkumi',
  'Sony Bank Inc.',
  'Sorachi Shinkin bank',
  'Sorachi Shinkumi',
  'Soso Shinkumi',
  'Sugamo shinkin bank',
  'Sukagawa Shinkin bank',
  'Sukumo Shogin',
  'Sumitomo Mitsui Banking Corporation.',
  'Sumitomo Mitsui Trust Bank, Limited',
  'Suruga Bank',
  'Suwa',
  'Tachibana Shinkin bank',
  'Tagawa Shinkin Bank',
  'Tajima Bank',
  'Tajima Shinkin bank',
  'Takamatsu Shinkin Bank',
  'Takanabe Shinkin Bank',
  'Takaoka',
  'Takayama Shinkin bank',
  'TAKINOGAWA SHINKIN BANK',
  'TAMA SHINKIN BANK',
  'Tamashima Shinkin bank',
  'Tanyo Shinkin Bank',
  'TATEBAYASHI SHINKIN BANK',
  'Tateyama shinkin bank',
  'The 77 Bank,Ltd.',
  'THE ADACHI SEIWA SHINKIN BANK',
  'The Aichi Bank, Ltd.',
  'THE AIOH　SHINKIN　BANK',
  'The Akita Bank,Ltd.',
  'THE AOKI SHINKIN BANK',
  'The Aomori Bank, Ltd.',
  'The Ashikaga Bank,Ltd.',
  'The Awa Bank,Ltd.',
  'The Bank of Fukuoka,Ltd.',
  'The Bank of Iwate,Ltd.',
  'THE BANK OF KOCHI, LTD',
  'The Bank of Kyoto,Ltd.',
  'THE BANK OF NAGASAKI, Ltd.',
  'THE BANK OF NAGOYA, Ltd.',
  'The Bank of Okinawa,Ltd.',
  'The Bank of Saga,Ltd.',
  'The Bank of Yokohama, Ltd.',
  'The Chiba Bank,Ltd.',
  'The Chiba Kogyo Bank,Ltd.',
  'The Chikuho Bank,Ltd.',
  'The Choshi Shinkin bank',
  'The Chugoku Bank,Ltd.',
  'The Chukyo Bank, Ltd.',
  'The Daito Bank, Ltd.',
  'The Ehime Bank, Ltd.',
  'The Eiwa Shinkin bank',
  'The Enshu Shinkin bank',
  'THE FUKUHO BANK, LTD.',
  'The Fukui Bank,Ltd.',
  'THE FUKUOKA CHUO BANK,LTD.',
  'The Gunma Bank,Ltd.',
  'The Hachijuni Bank,Ltd.',
  'The Hekikai Shinkin bank',
  'The Higashi-Nippon Bank,Ltd.',
  'The Higo Bank,Ltd.',
  'The Hiratsuka Shinkin bank',
  'The Hiroshima Bank,Ltd.',
  'THE HOKKAIDO BANK, LTD.',
  'The Hokkoku Bank,Ltd.',
  'The Hokuriku Bank,Ltd.',
  'The Hokuto Bank,Ltd.',
  'The Hongkong and Shanghai Banking Corporation Limited',
  'The Howa Bank, Ltd.',
  'The Hyakugo Bank,Ltd.',
  'The Hyakujushi Bank,Ltd.',
  'THE IIDA SHINKIN BANK',
  'The Iizuka Shinkin bank',
  'The Iyo Bank,Ltd.',
  'The Johoku Shinkin bank',
  'The Joyo Bank,Ltd.',
  'The Juroku Bank,Ltd.',
  'The Kagawa Bank, Ltd.',
  'The Kagoshima Bank,Ltd.',
  'THE KAMEARI SHINKIN BANK',
  'The Kanagawa Bank, Ltd.',
  'The Kanagawa Shinkin Bank',
  'THE KAWASAKI SHINKIN BANK',
  'The Keiyo Bank, Ltd.',
  'The Kitagunma Shinkin bank',
  'The Kitakyushu Bank,Ltd.',
  'The Kita-Nippon Bank, Ltd.',
  'The Kiyo Bank,Ltd.',
  'THE KOFU SHINKIN BANK',
  'THE KOSAN SHINKIN BANK',
  'The Kumamoto Bank, Ltd.',
  'THE KYOTO SHINKIN BANK',
  'The Kyushuhizen Shinkin bank',
  'The Master Trust Bank of Japan, Ltd.',
  'The Michinoku Bank,Ltd.',
  'The Minato Bank, Ltd.',
  'The Miyazaki Bank,Ltd.',
  'THE MIYAZAKI TAIYO BANK，LTD．',
  'The Musashino Bank,Ltd.',
  'THE NAGANO SHINKIN BANK',
  'The Nanto Bank,Ltd.',
  'The Nishi-Nippon City Bank,Ltd.',
  'The Nishio Shinkin bank',
  'The Nomura Trust and Banking Co.,Ltd.',
  'The Norinchukin Bank',
  'The Ogaki Kyoritsu Bank,Ltd.',
  'The Oita Bank,Ltd.',
  'The Oita Mirai Shinkin Bank',
  'The Osaka City Shinkin Bank',
  'The Osaka Kosei Shinkin bank',
  'The Rokinren Bank',
  'THE SAGA KYOEI BANK, LTD.',
  'The Saikyo Bank, Ltd.',
  'THE SAIKYO SHINKIN BANK',
  'THE SAN-IN GODO BANK,LTD．',
  'THE SANJO SHINKIN BANK',
  'The Sawara Shinkin bank',
  'The Sawayaka Shinkin Bank',
  'THE SEIBU SHINKIN BANK',
  'The Sendai Bank, Ltd.',
  'The Senshu Ikeda Bank, Ltd.',
  'THE SETAGAYA SHINKIN BANK',
  'The Shiba Shinkin Bank',
  'The Shiga Bank,Ltd.',
  'The Shikoku Bank,Ltd.',
  'The Shimane Bank, Ltd.',
  'The Shimizu Bank,Ltd.',
  'THE SHINONOME SHINKIN BANK',
  'The Shizuoka Bank,Ltd.',
  'THE SHIZUOKA CHUO BANK, LTD.',
  'THE SHONAI BANK, Ltd.',
  'The Suruga Bank,Ltd.',
  'The Taiko Bank, Ltd.',
  'The Tajima Bank,Ltd.',
  'THE TAKASAKI SHINKIN BANK',
  'The Tochigi Bank, Ltd.',
  'THE TOHO BANK, LTD.',
  'The Tokushima Taisho Bank,Ltd.',
  'THE TOKYO HIGASHI SHINKIN BANK',
  'THE TOKYO SANKYO SHINKIN BANK',
  'The Tokyo Shinkin bank',
  'The Tokyo Star Bank,Ltd.',
  'The Tono Shinkin bank',
  'THE TOO SHINKIN BANK',
  'The Tottori Bank,Ltd.',
  'The Towa Bank, Ltd.',
  'The Toyohashi Shinkin bank',
  'The Toyokawa Shinkin bank',
  'The Tsuruga',
  'The Uri Shinkumi Bank',
  'THE YAMAGATA BANK LTD.',
  'The Yamaguchi Bank,Ltd.',
  'The Yamanashi Chuo Bank,Ltd.',
  'The Yokohama Shinkin bank',
  'Tochigi Shinkin bank',
  'Toei Shinkin bank',
  'Tohoku Bank',
  'Tohoku Rokin',
  'Tokachi Shinkumi',
  'Tokai Rokin',
  'Tokushima Shinkin bank',
  'TOKYO BAY Shinkin bank',
  'Tokyo City Shinkin Bank',
  'Tokyo Fire Credit Cooperative',
  'Tokyo Higashi Shinkin Bank',
  'Tokyo Syoken Credit Union',
  'Tokyotoshokuin Credit Cooperative',
  'Tomakomai Shinkin Bank',
  'Tomato Bank, Ltd.',
  'Tonami',
  'Tonegun Shinkin bank',
  'Toryo Shinkumi',
  'Tosa Credit Cooperative',
  'Toshun Shinkin Bank',
  'Tottori Bank',
  'Tottori Shinkin bank',
  'Touyoku Shinkumi',
  'Toyama',
  'Toyama Bank',
  'Toyamaken Credit Union',
  'Toyamaken Ishi Credit Union',
  'Toyo Shinkin bank',
  'Toyohashi Shoko Credit Union',
  'TOYOTA SHINKIN Bank',
  'Tsu Shinkin bank',
  'Tsukuba Bank',
  'Tsuru Shinkumi',
  'Tsuruoka Shinkin bank',
  'Tsuyama Shinkin Bank',
  'Ueda',
  'Ugo Shinkin bank',
  'Ui Bank Co Ltd',
  'Uwajima Shinkin bank',
  'Wakayama Ishi Credit Union',
  'Wakkanai Shinkin bank',
  'Yamagata Chuo Credit Union',
  'Yamagata Shinkin bank',
  'Yamagatadaiichi Shinkumi',
  'Yamaguchi Bank',
  'Yamaguchiken Shinkumi',
  'Yamanashi Chuo Bank',
  'Yamanashi Shinkin bank',
  'Yamanashikenmin Credit Union',
  'Yamato Shinkin bank',
  'YJA Bank amanashi',
  'YokohamaKagin Credit Union',
  'YokohamaKougin Credit Union',
  'Yonago Shinkin bank',
  'Yonezawa Shinkin bank',
  'Yuki Shinkin Bank',
  'Zentouei Credit Union',
];

export const thaBankList = [
  'SIAM COMMERCIAL BANK',
  'BANGKOK BANK',
  'KASIKORN BANK',
  'THE ROYAL BANK OF SCOTLAND N.V.,',
  'KRUNG THAI BANK',
  'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
  'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.',
  'TMBThanachart Bank',
  'Bank of Thailand',
  'Mega International Commercial Bank PCL',
  'THE SIAM CITY BANK',
  'CITIBANK',
  'SUMITOMO MITSUI BANKING CORPORATION',
  'STANDARD CHARTERED BANK (THAI)',
  'CIMB THAI BANK',
  'UNITED OVERSEAS BANK (THAI)',
  'BANK OF AYUDHYA PUBLIC COMPANY LTD.',
  'BANK OF AMERICA, NATIONAL ASSOCIATION',
  'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK',
  'THE GOVERNMENT SAVINGS BANK',
  'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.',
  'DEUTSCHE BANK AG.',
  'THE GOVERNMENT HOUSING BANK',
  'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES',
  'MIZUHO CORPORATE BANK, LTD.',
  'BNP PARIBAS',
  'BANK OF CHINA LIMITED',
  'ISLAMIC BANK OF THAILAND',
  'TISCO BANK',
  'KIATNAKIN BANK',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI)',
  'THE THAI CREDIT RETAIL BANK',
  'LAND AND HOUSES PUBLIC COMPANY LIMITED',
  'ANZ BANK (THAI) PUBLIC COMPANY LIMITED',
  'INDIAN OVERSEA BANK',
  'RHB BANK BERHAD',
  'EXPORT-IMPORT BANK OF THAILAND',
  'OVER SEA-CHINESE BANKING CORPORATION LIMITED',
  'SMALL AND MEDIUM ENTERPRISE DEVELOPMENT BANK OF THAILAND',
];

export const canBankList = [
  'ADS Canadian Bank',
  'B2B Bank',
  'Bank of Montreal',
  'The Bank of Nova Scotia',
  'Bridgewater Bank',
  'Caisse populaire acadienne ltee',
  'Canadian Imperial Bank of Commerce',
  'Canadian Tire Bank',
  'Canadian Western Bank',
  'Concentra Bank',
  'Continental Bank of Canada',
  'CS Alterna Bank',
  'DirectCash Bank',
  'Equitable Bank',
  'Exchange Bank of Canada',
  'First Nations Bank of Canada',
  'Home Bank',
  'General Bank of Canada',
  'HomEquity Bank',
  'Citco Bank Canada',
  'Laurentian Bank of Canada',
  'Manulife Bank of Canada',
  'National Bank of Canada',
  'VersaBank',
  'Presidents Choice Bank',
  'M & T Bank',
  'Rogers Bank',
  'Royal Bank of Canada',
  'Street Capital Bank of Canada',
  'Tangerine Bank',
  'The Toronto-Dominion Bank',
  'Vancity Community Investment Bank',
  'Wealth One Bank of Canada',
  'Zag Bank',
  'Amex Bank of Canada',
  'Bank of China (Canada)',
  'BofA Canada Bank',
  'Cidel Bank Canada',
  'Citibank Canada',
  'CTBC Bank Corp. (Canada)',
  'Habib Canadian Bank',
  'HSBC Bank Canada',
  'ICICI Bank Canada',
  'Industrial and Commercial Bank of China (Canada)',
  'J.P. Morgan Bank Canada',
  'KEB Hana Bank Canada',
  'Mega International Commercial Bank (Canada)',
  'SBI Canada Bank',
  'Shinhan Bank Canada',
  'Societe Generale (Canada Branch)',
  'UBS Bank (Canada)',
  'Walmart Canada Bank',
  'Bank of Canada',
  'Atlantic Central',
  'Caisse Populaire Group Financier Lt\u00E9e',
  'Central 1 Credit Union Vancouver',
  'Central 1 Credit Union Burlington',
  'Credit Union Central of Manitoba Limited',
  'Credit Union Central Alberta Limited',
  'Credit Union Central of Saskatchewan',
  'F\u00E9d\u00E9ration des caisses Desjardins du Quebec',
  'La F\u00E9d\u00E9ration des caisses populaires de lOntario Inc.',
  'L Alliance des caisses populaires de l Ontario, Lt\u00E9e.',
  'Golden Horseshoe Credit Union Limited',
  'Latvian Credit Union Limited',
  'ATB Financial',
  'Edward Jones',
  'The Canada Trust Company',
  'CIBC Trust Corporation',
  'Effort Trust Company',
  'Home Trust Company',
  'HSBC Mortgage Corporation (Canada)',
  'Investors Group Trust Co. Ltd.',
  'Trust La Laurentienne du Canada Inc.',
  'Manulife Trust Company',
  'Montreal Trust Company of Canada',
  'National Trust Company',
  'Peace Hills Trust Company',
  'Peoples Trust Company',
  'The Royal Trust Company',
  'Royal Trust Corporation of Canada',
  'Scotia Mortgage Corporation',
  'Sun Life Financial Trust Inc.',
  'TD Mortgage Corporation',
  'TD Pacific Mortgage Corporation',
  'Community Trust Company',
];

export const idnBankList = [
  'BANK MANDIRI, TBK.',
  'BANK RAKYAT INDONESIA, TBK.',
  'BANK NEGARA INDONESIA, TBK.',
  'BANK CENTRAL ASIA, TBK.',
  'BANK CHINA CONSTRUCTION BANK INDONESIA, TBK.',
  'BANK CHINATRUST INDONESIA',
  'BANK CIMB NIAGA, TBK',
  'BANK COMMONWEALTH',
  'BANK DANAMON INDONESIA, TBK.',
  'BANK DBS INDONESIA',
  'BANK DINAR INDONESIA',
  'BANK DKI',
  'BANK EKSPOR INDONESIA',
  'BANK FAMA INTERNATIONAL',
  'BANK GANESHA',
  'BANK HANA',
  'BANK HARDA INTERNASIONAL',
  'BANK ICBC INDONESIA',
  'BANK INA PERDANA',
  'BANK INDEX SELINDO',
  'BANK INDIA',
  'BANK J TRUST INDONESIA, TBK.',
  'BANK JASA JAKARTA',
  'BANK KESEJAHTERAAN EKONOMI',
  'BANK MANDIRI TASPEN POS',
  'BANK NUSANTARA PARAHYANGAN',
  'BANK OCBC NISP, TBK.',
  'BANK OF AMERICA MERRILL-LYNCH',
  'BANK OF CHINA LIMITED',
  'BANK OF TOKYO MITSUBISHI UFJ, LTD.',
  'BANK OKE INDONESIA',
  'BANK PANIN',
  'BANK PANIN DUBAI SYARIAH',
  'BANK PEMBANGUNAN DAERAH BANTEN',
  'BANK PERMATA, TBK.',
  'BANK QNB INDONESIA',
  'BANK RABOBANK INTERNATIONAL INDONESIA',
  'BANK MASPION INDONESIA',
  'BANK MAYAPADA INTERNATIONAL, TBK.',
  'BANK MAYBANK INDONESIA SYRIAH TBK.',
  'BANK MAYBANK INDONESIA TBK.',
  'BANK MAYORA',
  'BANK MEGA, TBK.',
  'BANK MESTIKA DHARMA',
  'BANK MITRA NIAGA',
  'BANK MIZUHO INDONESIA',
  'BANK MNC INTERNASIONAL, TBK.',
  'BANK MUAMALAT INDONESIA TBK',
  'BANK MULTI ARTA SENTOSA',
  'BANK NATIONALNOBU',
  'BANK RBS',
  'BANK RESONA PERDANIA',
  'BANK ROYAL INDONESIA',
  'BANK SAHABAT SAMPOERNA',
  'BANK SBI INDONESIA',
  'BANK SHINHAN INDONESIA',
  'BANK SINARMAS',
  'BANK SUMITOMO MITSUI INDONESIA',
  'BANK SYARIAH BUKOPIN',
  'BANK SYARIAH MEGA INDONESIA',
  'BANK TABUNGAN NEGARA, TBK.',
  'BANK TABUNGAN PENSIUNAN NASIONAL',
  'BANK UOB INDONESIA',
  'BANK VICTORIA INTERNASIONAL',
  'BANK VICTORIA SYARIAH',
  'BANK WOORI SAUDARA ,TBK.',
  'BANK YUDHA BHAKTI',
  'BPD ACEH',
  'BPD BALI',
  'BPD BENGKULU',
  'BPD DAERAH ISTIMEWA YOGYAKARTA',
  'BPD JAMBI',
  'BPD JAWA TENGAH',
  'BPD JAWA TIMUR',
  'BPD KALIMANTAN BARAT',
  'BPD KALIMANTAN SELATAN',
  'BPD KALIMANTAN TENGAH',
  'BPD KALIMANTAN TIMUR',
  'BPD LAMPUNG',
  'BPD MALUKU',
  'BPD NUSA TENGGARA BARAT',
  'BPD NUSA TENGGARA TIMUR',
  'BPD PAPUA',
  'BPD RIAU DAN KEPRI',
  'BPD SULAWESI TENGAH',
  'BPD SULAWESI TENGGARA',
  'BPD SULSELBAR',
  'BPD SULUT',
  'BPD SUMATERA BARAT',
  'BPD SUMSEL DAN BABEL',
  'BPD SUMUT',
  'CENTRATAMA NASIONAL BANK',
  'CITIBANK N.A.',
  'DEUTSCHE BANK AG',
  'HONGKONG AND SHANGHAI BANK CORPORATION',
  'JP MORGAN CHASE BANK, N.A',
  'MANDIRI E-CASH',
  'PRIMA MASTER BANK',
  'STANDARD CHARTERED BANK',
  'Bank Artha Graha',
  'Bank HSBC',
  'MUFG Bank',
  'DBS',
  'Standard Chartered Bank (SCB)',
  'Bank UOB',
  'Bank Rabobank',
  'Bank Woori Indonesia',
  'Bank Ekonomi Raharja Tbk',
  'Bank Antardaerah',
  'J Trust Bank',
  'Bank Mayapada',
  'Bank Jabar',
  'Bank BPD DIY',
  'BPD Jateng',
  'Bank Jatim',
  'Bank Aceh',
  'Bank Sumut',
  'Bank Nagari',
  'Bank BPD Riau',
  'Bank Lampung',
  'BPD Kalsel',
  'BPD Kalbar',
  'BPD Kaltim',
  'BPD Kalteng',
  'BPD Sulsel',
  'Bank Sulut',
  'Bank NTB',
  'Bank NTT',
  'Bank Bengkulu',
  'BPD Sulteng',
  'Bank Sultra',
  'Bank Swadesi',
  'Bank Muamalat',
  'Bank Mestika',
  'Bank Maspion',
  'Bank Windu Kentjana',
  'Bank QNB Indonesia(Kesawan)',
  'BTN (Bank Tabungan Negara)',
  'BANK SYARIAH INDONESIA',
  'KEB Hana Indonesia',
  'Bank Agro',
  'BPD Banten',
  'CNB',
  'Bank Harda Internasional Tbk',
  'BPR LSB',
  'BPR KS',
  'BPR EKA',
  'CTBC Indonesia(China Trust)',
  'BANGKOK BANK PUBLIC CO. LTD.',
  'BANK AGRIS',
  'BANK AMAR INDONESIA',
  'BANK ANZ INDONESIA',
  'BANK ARTA NIAGA KENCANA',
  'BANK ARTHA GRAHA INTERNATIONAL, TBK.',
  'BANK ARTOS INDONESIA',
  'BANK BCA SYARIAH',
  'BANK BISNIS INTERNASIONAL',
  'BANK BJB',
  'BANK BJB SYARIAH',
  'BANK BNI SYARIAH',
  'BANK BNP PARIBAS INDONESIA',
  'BANK BRI AGRONIAGA TBK.',
  'BANK BTPN SYARIAH, TBK.',
  'BANK BUKOPIN',
  'BANK BUMI ARTA',
  'BANK CAPITAL INDONESIA',
];

export const sgpSwiftCodeList = [
  'Deutsche Bank',
  'ANZ Bank',
  'BANGKOK BANK PUBLIC COMPANY LIMITED',
  'BANK OF AMERICA, NATIONAL ASSOCIATION',
  'Bank of China',
  'BANK OF EAST ASIA LTD',
  'BANK OF INDIA',
  'Bank of Singapore',
  'BNP Paribas',
  'CHINATRUST COMMERCIAL BANK CO LTD',
  'CIMB Bank',
  'Citibank',
  'COMMERZBANK AKTIENGESELLSCHAFT',
  'CreDIT?AGRICOLE CORPORATE AND INVESTMENT BANK',
  'DBS Bank',
  'DnB?BANK ASA',
  'Far Eastern Bank',
  'FIRST COMMERCIAL BANK',
  'HL Bank',
  'HSBC Bank',
  'INDIAN BANK',
  'INDIAN OVERSEAS BANK',
  'INTESA SANPAOLO S.p.A.',
  'KOREA EXCHANGE BANK',
  'Malayan Banking (Maybank)',
  'Mizuho Bank',
  'NATIONAL AUSTRALIA BANK LTD',
  'NORDEA BANK FINLAND PLC',
  'OCBC Bank',
  'POSB Bank',
  'PT BANK NEGARA INDONESIA (PERSERO) TBK',
  'Qatar National Bank SAQ',
  'RHB Bank',
  'SKANDINAVISKA ENSKILDA BANKEN AB',
  'SOCIETE GENERALE',
  'Standard Chartered Bank',
  'State Bank of India',
  'Sumitomo Mitsui Banking Corporation',
  'SVENSKA HANDELSBANKEN AB',
  'The Bank of Tokyo-Mitsubishi UFJ',
  'UBS AG',
  'UCO BANK',
  'UOB BANK',
];

export const mngBankList = [
  'Khan bank (Хаан банк)',
  'Golomt Bank (Голомт банк)',
  'The State bank (Төрийн банк)',
  'XacBank (Хас банк)',
  'Chinggis Khaan Bank (Чингис хаан банк)',
  'Capital Bank of Mongolia (Капитал банк)',
  'National Investment Bank (Үндэсний хөрөнгө оруулалтын банк)',
  'Arig Bank (Ариг банк)',
  'Bogd bank (Богд банк)',
  'TransBank of Mongolia (Тээвэр хөгжлийн банк)',
  'Development Bank of Mongolia (Хөгжлийн банк)',
  'MobiFinance NBFI',
  'Ard Credit NBFI',
  'Hi payment solution LLC',
  'Trade and Development Bank of Mongolia (Худалдаа хөгжлийн банк)',
];

export const phlBankList = [
  'Metrobank',
  'Al Amanah Islamic Investment Bank',
  'ANZ Bank',
  'Asia United Bank',
  'Bank Of America',
  'Bank of China',
  'BDO - BANCO DE ORO',
  'BOC - BANK OF COMMERCE',
  'BPI - BANK OF THE PHILIPPINE ISLANDS',
  'BPI FAMILY SAVINGS BANK',
  'China Bank',
  'China Bank Savings',
  'Chinatrust Bank',
  'CITIBANK',
  'CITY SAVINGS BANK',
  'DBP - DEVELOPMENT BANK OF THE PHILIPPINES',
  'Duetsche Bank',
  'East West Bank',
  'Equicom Savings Bank',
  'First Consolidate Bank',
  'HSBC',
  'HSBC Savings Bank',
  'IBK-INDUSTRIAL BANK OF KOREA',
  'ISLA Bank',
  'JP Morgan Chase Bank',
  'KEB HANA BANK',
  'Malayan Bank',
  'Maybank Philippines',
  'Mega INTL COMML Bank Co LTD (ICBC)',
  'Mizuho Bank Ltd (Fuji Bank)',
  'One Network Bank',
  'Overseas Filipino Bank',
  'PBCOM - PHILIPPINE BANK OF COMMUNICATIONS',
  'Phil Business Bank',
  'Philippine Veterans Bank',
  'Philtrust Bank',
  'Security Bank',
  'Standard Chartered Bank',
  'Sterling Bank of Asia',
  'Shinhan Bank',
  'SUN SAVINGS BANK, INC.',
  'Yuanta Saving Bank Phils Inc (FKA TongYang)',
  'Union Bank of the Philippines',
  'UCPB - UNITED COCONUT PLANTERS BANK',
  'United Overseas Bank',
  'Wealth Development Bank',
  'ABN AMRO BANK',
  'ALLBANK',
  'ALLIED BANK',
  'ALLIED SAVINGS BANK',
  'AMA BANK',
  'AMANAH ISLAMIC BANK',
  'AUSTRALIA AND NEW ZEALAND BANK',
  'BANGKO MABUHAY',
  'BANK OF TOKYO',
  'BANK ONE SAVINGS',
  'Cebuana Lhuillier Rural Bank, Inc',
  'CTBC Bank (Philippines) Corporation',
  'CIMB BANK PHILS, INC',
  'CITIBANK',
  'CITY SAVINGS BANK',
  'DUNGGANON BANK, INC',
  'FAR EASTERN BANK',
  'FILIPINO SAVERS BANK',
  'FIRST ALLIED BANK',
  'FIRST MACRO BANK',
  'GUAGUA SAVERS BANK',
  'ING BANK N.V.',
  'LAND BANK OF THE PHILIPPINES',
  'LUZON DEVELOPMENT BANK',
  'MUFG BANK LTD (Bank Of Tokyo)',
  'PEN BANK',
  'PHILIPPINE BUSINESS BANK',
  'PSBANK - PHILIPPINE SAVINGS BANK',
  'PNB - PHILIPPINE NATIONAL BANK',
  'POSTAL BANK',
  'PREMIER DEVELOPMENT BANK',
  'PRODUCERS SAVINGS BANK CORP',
  'QUEZON CAPITAL RURAL BANK',
  'RCBC - RIZAL COMMERCIAL BANKING CORP',
  'RCBC Savings Bank',
  'ROBINSON SAVINGS BANK',
  'SECURITY SAVINGS BANK',
  'STERLING BANK',
  'SUMITOMO MITSUI BANKING CORP',
  'SUMMIT BANK',
  'TONG YANG SAVINGS BANK INC',
  'UCPB - UNITED COCONUT PLANTERS BANK',
  'UCPB SAVINGS',
  'UNION BANK OF THE PHILIPPINES',
  'UNIVERSITY SAVINGS BANK',
  'VETERANS BANK',
];

export const chlBakList = [
  'BANCO INTERNACIONAL [BICHCLRM]',
  'Banco Bice [028]',
  'Banco Consorcio [055]',
  'Banco Credito e Inversiones (BCI) [016]',
  'Banco Falabella [051]',
  'Banco HSBC [031]',
  'Banco Internacional [009]',
  'Banco Itau Chile [039]',
  'Banco Ripley [053]',
  'Banco Santander [037]',
  'Banco Security [049]',
  'Banco de Chile [001]',
  'Banco del Estado de Chile [012]',
  'HSBC BANK (CHILE) [BLICCLRM]',
  'SCOTIABANK CHILE [BKSACLRMXXX]',
  'Scotiabank Chile [014]',
];

export const rusCashPickupList = ['CONTACT', 'ZOLOTAYA KORONA', 'NONE'];

import { useState } from 'react';
import styled from 'styled-components';

import { Spin } from 'animation';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import FlexWrap from 'components/_common/FlexWrap';
import SendingReceiptMail from 'components/_common/SendingReceiptMail';
import TextButton from 'components/_common/TextButton';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import ReceiptAccordionContent from 'components/Home/ReceiptAccordionContent';

function ReceiptInquiry() {
  const { nationality, refundAfter, isUploadRequired } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [sendingStatus, setSendingStatus] = useState<'DEFAULT' | 'IS_SENDING'>(
    'DEFAULT'
  );

  const onClickReSending = () => {
    setSendingStatus('IS_SENDING');
  };
  return (
    <>
      <ReceiptLayout>
        <TitleWrapper dir='column' refundAfter={refundAfter}>
          <Title>
            {nationality === 'KOR'
              ? '모바일 Tax Refund 영수증'
              : 'Refund statement inquiry'}
          </Title>
          <Description>
            {`1. 이메일로 전송된 Tax Refund 영수증을 출력해주세요.\n2. 구매한 물건과 Tax Refund 영수증을 세관에 보여주세요.\n3. Tax Refund 영수증에 세관 확인 도장을 받은 후 사진을\n  올려주세요.`}
          </Description>
        </TitleWrapper>
        {isUploadRequired && (
          <>
            {sendingStatus === 'IS_SENDING' ? (
              <ImgButton disabled>
                <LoadingSpinner />
              </ImgButton>
            ) : (
              <TextButton
                title='이메일로 영수증 전송'
                onClick={onClickReSending}
                margin='20px'
              />
            )}
          </>
        )}
        <ReceiptAccordionContent
          style={{
            paddingTop: 20,
            marginTop: 18,
            width: '100%',
          }}
        />
        {sendingStatus === 'IS_SENDING' && (
          <SendingReceiptMail
            setSendingStatus={setSendingStatus}
            isActiveLoadingView={false}
          />
        )}
      </ReceiptLayout>
    </>
  );
}

const TitleWrapper = styled(FlexWrap)<{ refundAfter: boolean }>`
  width: 100%;
`;

const Title = styled.h1`
  color: #1856cd;
  font-size: 21px;
  line-height: 150%;
  text-align: left;
  font-weight: 600;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 16px;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
  color: #3a3b3e;
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 5px solid #fff;
  margin: 0 auto;
  border-top: 5px solid #246cf6;
`;
const ImgButton = styled.button`
  width: 335px;
  height: 52px;
  background-color: #e9f0fe;
  border-radius: 8px;
  cursor: default;
  :hover {
    opacity: 1;
  }
  margin: 20px;
`;

export default ReceiptInquiry;

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { IGlobalState, initialGlobalState } from 'store';
import styled from 'styled-components';

import { updateDepartureInfo, verifyPassport } from 'api';
import DepartureInfoForm from 'components/PassportValidate/DepartureInfoForm';
import PassportInputForm from 'components/PassportValidate/PassportInputForm';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import SendingReceiptMail from 'components/_common/SendingReceiptMail';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import {
  IUpdateDepartureInfoPayload,
  IVerifyPassportPayload,
  IVerifyPassportResponse,
} from 'model/types';
import { debounce } from 'lodash-es';

export interface IErrorState {
  message: string;
}
function PassportValidate() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    passportNumber: currPassportNumber,
    departureDate: currDepartureDate,
    email: prevEmail,
    isUploadRequired,
    refundCondition,
  } = useAppSelector((state) => state) as IGlobalState;

  const formMethod = useForm({
    defaultValues: {
      passportNumber: '',
      nationality: '',
    },
  });
  const [sendingStatus, setSendingStatus] = useState<'DEFAULT' | 'IS_SENDING'>(
    'DEFAULT'
  );
  const [departureDate, setDepartureDate] = useState<any>('');
  const [email, setEmail] = useState<string>(prevEmail || '');
  const { passportNumber } = formMethod.watch();
  const loginToken = localStorage.getItem('login');
  const departureDateToken = localStorage.getItem('departureDate');
  const customerNationToken = localStorage.getItem('customerNation');
  const [error, setError] = useState<IErrorState | undefined>();
  const isLogin = Boolean(localStorage.getItem('login'));

  const { mutate: passportMutation } = useMutation<
    IVerifyPassportResponse,
    AxiosError,
    IVerifyPassportPayload
  >((payload) => verifyPassport(payload), {
    retry: false,
    onSuccess: ({
      departureDate,
      register,
      customerNation,
      read,
      receiptUpload,
      uploadRequired,
      departureStatus,
      showCatUi,
      email,
      refundCondition,
      hasOverPrice,
    }) => {
      departureDate && localStorage.setItem('departureDate', departureDate);
      customerNation && localStorage.setItem('customerNation', customerNation);
      dispatch({
        type: 'UPDATE_USER_INFO',
        payload: {
          passportNumber: passportNumber.toUpperCase(),
          departureDate,
          isRefundInfoRegistered: register,
          nationality: customerNation,
          haveSeenReceipt: read,
          isReceiptImageUploaded: receiptUpload,
          isUploadRequired: uploadRequired,
          isCustomerLeft: departureStatus === 'DEPARTURE_COMPLETE',
          showCatUi: false, //원래 showCatUi가 전달되어야하는데 서버 문제로 일단 false 고정으로 함
          email,
          refundCondition,
          refundAfter: refundCondition !== 'REFUND_ONLY',
          hasOverPrice,
        },
      });
      setError(undefined);
      localStorage.setItem('login', passportNumber);
      if (refundCondition === 'REFUND_ONLY') {
        history.push(routes.home);
        return;
      }
      if (!departureDate) {
        return;
      }
      history.push(routes.home);
    },
    onError: () => {
      setError({
        message: 'Please verify your Passport Number and try again.',
      });
      dispatch({
        type: 'UPDATE_HOME_MODAL_STATE',
        payload: {
          isActive: true,
          type: 'CUSTOM',
          data: {
            title: 'Unable to Verify passport.',
            content: `${passportNumber.toUpperCase()}\n\nDid you enter passport number correctly?\nIf yes, press [Confirm] to continue,`,
            subBtnText: 'Cancel',
            btnText: 'Confirm',
            btnCallback: onClickCofirm,
          },
        },
      });
    },
  });
  const departureMutation = useMutation(
    ['deaprtureDate'],
    (payload: IUpdateDepartureInfoPayload) => updateDepartureInfo(payload),
    {
      onSuccess: async () => {
        await dispatch({
          type: 'UPDATE_USER_INFO',
          payload: { departureDate, email },
        });
        if (isUploadRequired) {
          setSendingStatus('IS_SENDING');
        } else {
          if (refundCondition === 'REFUND_ONLY') {
            history.push(routes.home);
          } else {
            history.push(routes.refundProcedure);
          }
        }
      },
      onError: () => {
        alert('서버와 통신이 원활하지 않습니다.');
      },
    }
  );

  // 여러번 여권조회 중복호출 방지
  const debouncedMutation = useCallback(
    debounce((formattedPassportNumber) => {
      passportMutation({ passportNumber: formattedPassportNumber });
    }, 500),
    [passportMutation]
  );

  const onSubmitPassportNumber = () => {
    if (!passportNumber) {
      return;
    }
    const formattedPassportNumber = passportNumber
      .toUpperCase()
      .replace(/\s+/g, '');
    // 여권조회 호출
    debouncedMutation(formattedPassportNumber);
  };

  const onSubmitDepartureInfo: FormEventHandler<HTMLFormElement> | undefined = (
    e
  ) => {
    e.preventDefault();
    if (
      !email.match(
        /[a-zA-Z0-9]{1,}@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
      )
    ) {
      setError({ message: 'Please enter a valid email.' });
      return;
    } else {
      setError(undefined);
    }
    departureMutation.mutate({
      passportNumber: currPassportNumber || loginToken,
      departureDate: format(departureDate, 'yyyy-MM-dd'),
      email,
    });
  };

  const onClickCofirm = () => {
    history.push(routes.refundInfoLeaflet, { passportNumber });
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (currPassportNumber) {
        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: initialGlobalState,
        });
        localStorage.removeItem('login');
        localStorage.removeItem('departureDate');
        localStorage.removeItem('customerNation');
      }
      window.history.go(1);
    };
    window.addEventListener('popstate', handleBackButton);
    return () => window.removeEventListener('popstate', handleBackButton);
  }, []);

  useEffect(() => {
    if (customerNationToken === 'KOR') {
      return;
    }
    if (isMobile) {
      if (loginToken && departureDateToken) {
        history.push('/home');
      }
    } else {
      if (currPassportNumber && currDepartureDate) {
        history.push('/home');
      }
    }
  }, []);

  return (
    <ReceiptLayout>
      <Wrapper>
        {(isMobile ? isLogin : currPassportNumber) ? (
          <>
            <DepartureInfoForm
              setDepartureDate={setDepartureDate}
              email={email}
              setEmail={setEmail}
              onSubmit={onSubmitDepartureInfo}
              departureDate={departureDate}
              {...(error && { error })}
            />
            {sendingStatus === 'IS_SENDING' && (
              <SendingReceiptMail
                setSendingStatus={setSendingStatus}
                callback={() => history.push(routes.refundProcedure)}
              />
            )}
          </>
        ) : (
          <FormProvider {...formMethod}>
            <PassportInputForm
              onSubmit={onSubmitPassportNumber}
              {...(error && { error })}
            />
          </FormProvider>
        )}
      </Wrapper>
    </ReceiptLayout>
  );
}

export default PassportValidate;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0 100px;
`;

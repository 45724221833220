import { AxiosError } from 'axios';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import Leaflet from 'assets/uploadImage/leaflet.png';
import FlexWrap from 'components/_common/FlexWrap';
import LoadingView from 'components/_common/LoadingView';
import UploadItem from 'components/UploadImage/UploadItem';
import { useAppSelector } from 'hooks/useReduxHooks';
import { getRefundList } from 'api';
import { IGetRefundListPayload, IGetRefundListResponse } from 'model/types';
import { IGlobalState } from 'store';

type Props = {
  refetchHome: () => void;
};
function UploadReceiptImage({ refetchHome }: Props) {
  const { passportNumber } = useAppSelector((state) => state) as IGlobalState;
  const [isLatest, setIsLatest] = useState(true);
  const [data, setData] = useState<null | IGetRefundListResponse>(null);

  const onClickFilter: MouseEventHandler<HTMLButtonElement> = (e: any) => {
    setIsLatest(e.target.name === 'LATEST');
  };

  const getRefundListMutation = useMutation<
    IGetRefundListResponse,
    AxiosError,
    IGetRefundListPayload
  >((payload) => getRefundList(payload), {
    retry: false,
    onSuccess: (list) => {
      setData(list);
    },
    onError: () => {
      alert(`[error code:0000 ]서버요청에 실패하였습니다. 다시 시도해주세요.`);
    },
  });

  useEffect(() => {
    getRefundListMutation.mutate({
      passportNumber,
      refundAfter: true,
      latest: isLatest,
    });
  }, [isLatest]);

  return (
    <>
      <Title>세관 확인 도장</Title>
      <Desc>
        출력한 Tax Refund 영수증에 아래와 같이
        <br />
        세관 확인 도장을 받으세요.
      </Desc>
      <Image src={Leaflet} />
      {getRefundListMutation.isLoading ? (
        <LoadingView />
      ) : (
        <>
          <FilterBox justifyContent='flex-end'>
            <FilterBtn name='LATEST' active={isLatest} onClick={onClickFilter}>
              최신순
            </FilterBtn>
            <FilterBtn name='PAST' active={!isLatest} onClick={onClickFilter}>
              과거순
            </FilterBtn>
          </FilterBox>
          {data && data.length > 0 ? (
            data?.map((item, idx) => (
              <UploadItem {...item} key={idx} refetchHome={refetchHome} />
            ))
          ) : (
            <NoContent>There is no purchase history.</NoContent>
          )}
        </>
      )}
    </>
  );
}
const Title = styled.h3`
  font-size: 18px;
  line-height: 150%;
  margin-top: 8px;
  padding: 20px 0 0;
  border-top: 1px solid #cbccce;
`;
const Desc = styled.p`
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
`;
const Image = styled.img`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #cbccce;
`;
const FilterBox = styled(FlexWrap)`
  width: 100%;
  margin-top: 20px;
`;
const FilterBtn = styled.button<{ active: boolean }>`
  color: ${(props) => (props.active ? '#000000' : '#9FA1A7')};
  font-size: 14px;
  :last-child {
    ::before {
      content: ' | ';
      margin: 0 8px;
      color: '#9FA1A7';
    }
  }
`;
const NoContent = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: #9fa1a7;
  margin: 222px 0;
  @media ${(props) => props.theme.mobile} {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin: 222px 8px;
  }
`;
export default UploadReceiptImage;

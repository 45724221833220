import React, { useState, useEffect, useRef } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import styled from 'styled-components';

import ArrowDown from 'assets/common/arrow_down.png';
import { guideLocations, guidePlaces } from 'constant';
import GuideFilter from 'components/Home/GuideFilter';
import GuideDetail from 'components/Home/GuideDetail';

export interface IGuideDetail {
  id: number;
  location: string;
  name: string;
  places: IPlaces[];
  isActive: boolean;
}

export interface IPlaces {
  id: number;
  placeDetail: string;
  placeImg: string;
  refundPrintImg: string;
}

function PrinterLocation() {
  const [isActive, setIsActive] = useState(true);
  const [guideDetailInfo, setGuideDetailInfo] = useState<IGuideDetail[]>([]);
  const [activeFilter, setActiveFilter] = useState('인천 T1');
  const guideFilterRef = useRef<any>(null);
  let currentPosition = useRef<any>(null);
  let startPosition = 0;
  let movePosition = 0;
  let endPosition = 0;

  const onMouseUp = () => {
    currentPosition.current = endPosition;
    document.onmousemove = null;
  };
  const onMouseDown = (e: any) => {
    e.preventDefault();
    if (!e.clientX) {
      return;
    }
    startPosition = isMobile ? e.clientX : e.clientX;
    document.onmousemove = onMouseMove;
  };
  const onTouchDown = (e: any) => {
    if (!e.touches[0]) {
      return;
    }
    startPosition = e.touches[0].clientX;
    document.ontouchmove = onTouchMove;
  };
  const onMove = () => {
    if (!guideFilterRef.current) {
      return;
    }
    endPosition = currentPosition.current + startPosition - movePosition;
    endPosition = endPosition < 0 ? 0 : endPosition > 65 ? 65 : endPosition;
    guideFilterRef.current.style.transform = `translateX(${-endPosition}px)`;
  };
  const onMouseMove = (e: any) => {
    movePosition = e.clientX;
    onMove();
  };
  const onTouchMove = (e: any) => {
    if (!e.touches[0]) {
      return;
    }
    movePosition = e.touches[0].clientX;
    onMove();
  };
  const onClickFilter = (location: string) => {
    setActiveFilter(location);
    if (location === '김해') {
      onNextMove();
    } else if (location === '인천 T1') {
      onPreviewMove();
    }
  };

  const onClickToggle = () => {
    setIsActive((prev) => !prev);
  };

  guideLocations.forEach((item) => {
    if (item.location === activeFilter) {
      item.isActive = true;
    } else {
      item.isActive = false;
    }
  });

  useEffect(() => {
    const newArray = guidePlaces.filter(
      (item: IGuideDetail) => activeFilter === item.location
    );
    setGuideDetailInfo(newArray);
  }, [activeFilter]);

  const onPreviewMove = () => {
    guideFilterRef.current.style.transform = `translateX(0px)`;
    currentPosition.current = 0;
  };
  const onNextMove = () => {
    guideFilterRef.current.style.transform = `translateX(-65px)`;
    currentPosition.current = 65;
  };

  return (
    <>
      <TitleBtn onClick={onClickToggle}>
        공항 프린터기 위치 <ToggleIcon src={ArrowDown} isActive={isActive} />
      </TitleBtn>
      {isActive && (
        <ContentContainer>
          <GuideContainer>
            {isDesktop && (
              <ArrowButton style={{ left: 0 }} onClick={onPreviewMove}>
                {'<'}
              </ArrowButton>
            )}
            <Guide
              ref={guideFilterRef}
              onTouchStart={onTouchDown}
              onMouseDown={onMouseDown}
              onTouchEnd={onMouseUp}
              onMouseLeave={onMouseUp}
              onMouseUp={onMouseUp}
            >
              {guideLocations.map((location, index) => (
                <GuideFilter
                  key={index}
                  location={location.location}
                  isActive={location.isActive}
                  onClickFilter={onClickFilter}
                  onPreviewMove={onPreviewMove}
                  onNextMove={onNextMove}
                />
              ))}
            </Guide>{' '}
            {isDesktop && (
              <ArrowButton style={{ right: 0 }} onClick={onNextMove}>
                {'>'}
              </ArrowButton>
            )}
          </GuideContainer>
          <GuideDetailContainer>
            {guideDetailInfo.map((info, index) => (
              <GuideDetail key={index} info={info} />
            ))}
          </GuideDetailContainer>
        </ContentContainer>
      )}
    </>
  );
}
const TitleBtn = styled.button`
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f5f6f7;
  padding: 14px 0;
  color: #3a3b3e;
`;
const ToggleIcon = styled.img<{ isActive: boolean }>`
  width: 28px;
  height: 28px;
  transform: ${(props) => (props.isActive ? `rotate(180deg)` : `rotate(0deg)`)};
`;
const ContentContainer = styled.div``;
const GuideContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Guide = styled.div`
  display: flex;
  width: 400px;
  margin-top: 35px;
  gap: 12px;
  transition-duration: 70ms;
  touch-action: none;
`;
const GuideDetailContainer = styled.div``;
const ArrowButton = styled.button<{ left?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  width: 24px;
  height: 24px;
  background-color: rgba(159, 161, 167, 0.8);
  border-radius: 50%;
  color: #fff;
  z-index: 50;
  :hover {
    opacity: 0.8;
  }
`;
export default PrinterLocation;

import React from 'react';
import styled, { css } from 'styled-components';
import FlexWrap from 'components/_common/FlexWrap';
import DashedLineImg from 'assets/receipt/dashedLine.png';

export interface IRefundRow {
  title?: string;
  subTitle?: string;
  content?: string | null;
  isLast?: boolean;
  info?: string;
  hasComma?: boolean;
  isForPrinting?: boolean;
  refundAfter?: boolean;
  hasBarcode?: boolean;
}
function TableRow({
  title,
  subTitle,
  content,
  isLast,
  info,
  hasComma,
  isForPrinting = false,
  refundAfter,
  hasBarcode,
}: IRefundRow) {
  return (
    <>
      {!info && (
        <>
          <Row isForPrinting={isForPrinting} hasBarcode={hasBarcode}>
            <FirstTh>{title || ''}</FirstTh>
            <SecondTh>{subTitle || ''}</SecondTh>
            {isForPrinting && (
              <RightSortedRow>
                {hasComma ? (
                  <PriceWrap>
                    <StyledTd isForPrinting={isForPrinting} isAlignLeft>
                      {hasComma ? '₩' : ''}
                    </StyledTd>
                    <StyledTd isForPrinting={isForPrinting}>
                      {content || ''}
                    </StyledTd>
                  </PriceWrap>
                ) : (
                  <StyledTd isForPrinting={isForPrinting}>
                    {content || ''}
                  </StyledTd>
                )}
              </RightSortedRow>
            )}
          </Row>
          {!isForPrinting && content !== undefined && (
            <RightSortedRow>
              {hasComma ? (
                <PriceWrap>
                  <StyledTd isForPrinting={isForPrinting} isAlignLeft>
                    {hasComma ? '₩' : ''}
                  </StyledTd>
                  <StyledTd isForPrinting={isForPrinting}>
                    {content || ''}
                  </StyledTd>
                </PriceWrap>
              ) : (
                <StyledTd isForPrinting={isForPrinting}>
                  {content || ''}
                </StyledTd>
              )}
            </RightSortedRow>
          )}
        </>
      )}
      {info && <Info isForPrinting={isForPrinting}>{info}</Info>}
      {isLast && (
        <DashedLine
          src={DashedLineImg}
          isForPrinting={isForPrinting}
          refundAfter={refundAfter}
        />
      )}
    </>
  );
}
const Row = styled(FlexWrap)<{ isForPrinting: boolean; hasBarcode?: boolean }>`
  font-size: ${(props) => (props.isForPrinting ? '8px' : '14px')};
  line-height: ${(props) =>
    props.isForPrinting ? (props.hasBarcode ? '8px' : '18px') : '22px'};
  margin: ${(props) => (props.isForPrinting ? 0 : '4px 0')};
`;
const RightSortedRow = styled(FlexWrap)`
  justify-content: flex-end;
`;
const StyledTd = styled.div<{ isAlignLeft?: boolean; isForPrinting?: boolean }>`
  min-height: 16px;
  max-width: 200px;
  flex: 1;
  text-align: ${(props) => (props.isAlignLeft ? 'left' : 'right')};
  font-weight: 400;
  ${(props) =>
    props.isForPrinting
      ? css`
          font-size: 8px;
          line-height: 10px;
        `
      : css`
          font-size: 14px;
          line-height: 22px;
        `}
`;
const StyledTh = styled.div`
  min-height: 16px;
  line-height: inherit;
  font-size: inherit;
  text-align: left;
  font-weight: 500;
`;
const FirstTh = styled(StyledTh)`
  width: 100px;
`;
const SecondTh = styled(StyledTh)`
  flex: 1;
`;
const PriceWrap = styled(FlexWrap)`
  justify-content: space-between;
  align-items: center;
  width: 80px;
`;
const Info = styled.p<{ isForPrinting: boolean }>`
  color: #5f6165;
  font-size: ${(props) => (props.isForPrinting ? '10px' : '12px')};
  line-height: ${(props) => (props.isForPrinting ? '15px' : '18px')};
  text-align: start;
  margin-bottom: ${(props) => (props.isForPrinting ? '8px' : '20px')};
`;
const DashedLine = styled.img<{
  isForPrinting?: boolean;
  refundAfter?: boolean;
}>`
  width: 100%;
  height: 1px;
  margin: ${(props) => (props.isForPrinting ? '8px 0' : '30px 0')};
`;
export default TableRow;

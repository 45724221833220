import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import Arrow from 'assets/common/chevron_down_gray.png';
import ArrowActvice from 'assets/common/chevron_down_blue.png';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

type Props = {
  title: string;
  content: string;
  id: number;
};
function ToggleItem({ title, content, id }: Props) {
  const [isActive, setIsActive] = useState(false);
  const { isRefundInfoRegistered, nationality } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const history = useHistory();

  const onToggle = () => {
    setIsActive(!isActive);
  };
  const goToRefundInfo = () => {
    history.push(
      isRefundInfoRegistered ? routes.refundInfo : routes.customerRefund
    );
  };
  return (
    <Question>
      <QuestionWrapper onClick={onToggle} isActive={isActive}>
        <Title isActive={isActive}>{title}</Title>
        <ArrowIcon active={isActive} src={isActive ? ArrowActvice : Arrow} />
      </QuestionWrapper>
      <Answer isActive={isActive}>
        {content}
        {id === 2 && (
          <LinkBtn onClick={goToRefundInfo}>
            {nationality === 'KOR'
              ? `송금 정보 다시 한번 확인하기`
              : 'Check my account information'}
            <ArrowIcon
              src={ArrowActvice}
              style={{ transform: 'rotate(270deg)' }}
            />
          </LinkBtn>
        )}
      </Answer>
    </Question>
  );
}

const ArrowIcon = styled.img<{ active?: boolean }>`
  width: 24px;
  height: 24px;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
const Question = styled.li`
  position: relative;
  list-style: none;
  width: 100%;
`;

const QuestionWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e5e6e8;
  z-index: 5;
  padding: 12px 20px;
`;

const Title = styled.h2<{ isActive: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => (props.isActive ? '#1856CD' : '#3A3B3E')};
`;
const Answer = styled.div<{ isActive: boolean }>`
  background-color: #f5f6f7;
  z-index: 2;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: flex-start;
  ${(props) =>
    !props.isActive
      ? css`
          height: 0;
          overflow: hidden;
          opacity: 0;
          padding: 0 20px;
        `
      : css`
          overflow: visible;
          opacity: 1;
          padding: 14px 12px;
        `}
  color: #3a3b3e;
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
`;
const LinkBtn = styled.button`
  color: #5089f8;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 20px 0 8px;
`;
export default ToggleItem;

import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

import Receipt from 'assets/home/receipt_preview.png';
import FlexWrap from 'components/_common/FlexWrap';
import { IData } from 'components/Receipt/RefundReceipt';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IReceipt } from 'model/types';
import { IGlobalState } from 'store';
import { comma } from 'utils/format';

type Props = {
  data: IReceipt;
  order: number;
};
function ReceiptAccordionContentItem({
  order,
  data: { saleDate, totalAmount, totalRefund, franchiseeName },
}: Props) {
  const dispatch = useAppDispatch();
  const { printData } = useAppSelector((state) => state) as IGlobalState;
  const priceInfo = [
    ['Date of Purchase', format(new Date(saleDate), 'yyyy.MM.dd')],
    ['Payment', `₩ ` + comma(totalAmount)],
    ['Amount of Refund', `₩ ` + comma(totalRefund)],
  ];
  const onOpenPreview = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        type: 'PREVIEW_RECEIPT',
        isActive: true,
        data: { data: (printData as IData[])[order] },
      },
    });
  };

  return (
    <Container dir='column'>
      <Title>{franchiseeName}</Title>
      <ContentContainer>
        <PriceItem dir='column' justifyContent='center'>
          {priceInfo.map(([key, value]) => (
            <PriceItemText key={key} dir='column'>
              <LabelText>{key}</LabelText>
              <ValueText>{value}</ValueText>
            </PriceItemText>
          ))}
        </PriceItem>
        <ProductImage src={Receipt} onClick={onOpenPreview} />
      </ContentContainer>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  padding: 30px 0;
  border-bottom: 1px solid #cbccce;
  width: 100%;
  :last-child {
    margin-bottom: 24px;
  }
`;

const ContentContainer = styled(FlexWrap)``;
const PriceItem = styled(FlexWrap)`
  flex: 1;
`;
const PriceItemText = styled(FlexWrap)``;
const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 700;
`;
const ValueText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
`;
const LabelText = styled(ValueText)`
  margin: 0;
  color: #80848a;
`;

const ProductImage = styled.img`
  width: 137px;
  height: 126px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #9fa1a7;
  :hover {
    opacity: 70%;
  }
  transition: all 300ms ease-in;
`;

export default ReceiptAccordionContentItem;

import React from 'react';
import styled from 'styled-components';

import checkIcon from 'assets/uploadImage/check.png';
import FlexWrap from 'components/_common/FlexWrap';

type Props = {
  visiable: boolean;
};
function ToastPopUp({ visiable }: Props) {
  return (
    <ContentContainer alignItems='center' gap='6px' visiable={visiable}>
      <Image src={checkIcon} />
      사진을 올렸습니다. Successfully uploaded.
    </ContentContainer>
  );
}

const ContentContainer = styled(FlexWrap)<{ visiable: boolean }>`
  background-color: #3a3b3ecc;
  position: fixed;
  width: 311px;
  border-radius: 8px;
  padding: 12px;
  bottom: ${(props) => (props.visiable ? '40px' : '-40px')};
  opacity: ${(props) => (props.visiable ? 1 : 0)};
  transition: 500ms all ease-in;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 14px;
  z-index: 100;
`;
const Image = styled.img`
  width: 20px;
  height: 20px;
`;

export default ToastPopUp;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Spin } from 'animation';
import FlexWrap from 'components/_common/FlexWrap';
import { useAppDispatch } from 'hooks/useReduxHooks';

interface IProps {
  imgUrl: string;
  onTogglePreview?: () => void;
  setIsPreviewModal?: Dispatch<SetStateAction<boolean>>;
}
function ImagePreviewModal({
  imgUrl,
  onTogglePreview,
  setIsPreviewModal,
}: IProps) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const onClose = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: { type: 'PREVIEW_IMAGE', isActive: false, data: null },
    });
  };

  useEffect(() => {
    const handleBackButton = () => {
      window.history.go(1);
      if (setIsPreviewModal) {
        setIsPreviewModal(false);
      } else {
        onClose();
      }
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <BackContainer
      onClick={() => {
        document.body.style.removeProperty('overflow');
        if (onTogglePreview) {
          onTogglePreview();
        } else {
          onClose();
        }
      }}
    >
      <ModalImage src={imgUrl} onLoad={handleImageLoad} isLoading={isLoading} />
      {isLoading && <LoadingSpinner />}
    </BackContainer>
  );
}
const BackContainer = styled(FlexWrap)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: #3a3b3e;
  z-index: 200;
  @media ${(props) => props.theme.desktop} {
    max-width: 375px;
  }
`;
const ModalImage = styled.img<{ isLoading: boolean }>`
  max-width: 335px;
  max-height: 80%;
  background-color: lightgray;
`;

const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 6px solid #e9f0fe;
  border-top: 6px solid #246cf6;
  position: absolute;
`;
export default ImagePreviewModal;

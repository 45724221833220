import styled from 'styled-components';
import QrCode from 'assets/receipt/qrcode.png';
import LogoWhite from 'assets/receipt/logo_white.png';

import FlexWrap from 'components/_common/FlexWrap';
import { NotificationData } from 'model/receiptData';

function Notification() {
  return (
    <Container dir='column' flex='1'>
      <Title>
        텍스리펀 안내 <EngTitle>Tax Refund Procedure</EngTitle>
      </Title>
      {NotificationData.koreanText.map((item, index) => (
        <FlexWrap key={index} dir='column'>
          <SubTitle>{item.title}</SubTitle>
          <Description>{item.description}</Description>
        </FlexWrap>
      ))}
      {NotificationData.englishText.map((item, index) => (
        <FlexWrap key={index} dir='column'>
          <EngSubTitle>{item.title}</EngSubTitle>
          <EngSubDescription>{item.description}</EngSubDescription>
        </FlexWrap>
      ))}
      <FlexWrap gap='20px' margin='40px 0 32px'>
        <FlexWrap dir='column' style={{ width: 220 }}>
          <QrGuideTitle>{NotificationData.qrGuideText.title}</QrGuideTitle>
          <QrGuideDesceiption>
            {NotificationData.qrGuideText.description}
          </QrGuideDesceiption>
        </FlexWrap>
        <QrImage src={QrCode} />
      </FlexWrap>
      <LogoImage src={LogoWhite} />
    </Container>
  );
}
const Container = styled(FlexWrap)`
  padding: 30px;
  background-color: #f5f6f7;
`;

const Title = styled.h1`
  font-size: 28px;
  color: #1856cd;
  font-weight: 600;
  margin-bottom: 24px;
`;
const EngTitle = styled(Title)`
  font-size: 16px;
  font-weight: 600;
  display: inline;
`;
const SubTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 600;
`;
const Description = styled.p`
  color: #3a3b3e;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 18px;
`;
const EngSubTitle = styled.h2`
  font-size: 13px;
  margin-bottom: 12px;
  font-weight: 700;
  color: #5f6165;
`;
const EngSubDescription = styled.p`
  font-size: 11px;
  margin-bottom: 12px;
  color: #5f6165;
  line-height: 150%;
`;
const QrGuideTitle = styled.h3`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 150%;
`;
const QrGuideDesceiption = styled.p`
  font-size: 11px;
  line-height: 150%;
`;
const QrImage = styled.img`
  width: 100px;
  height: 100px;
`;
const LogoImage = styled.img`
  width: 130px;
  height: 32px;
  margin: 0 auto;
`;
export default Notification;

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';

import CheckCircle from 'assets/customerRefund/check_circle.png';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TextButton from 'components/_common/TextButton';
import ProgressBar from 'components/_common/ProgressBar';
import SendingReceiptMail from 'components/_common/SendingReceiptMail';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { getIsFrequentCountry } from 'utils/common';

type Params = {
  isEmailChanged?: boolean;
};
function RefundResult() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isEmailChanged } = useParams() as Params;
  const { nationality, isUploadRequired, refundCondition } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [sendingStatus, setSendingStatus] = useState<'IS_SENDING' | 'DEFAULT'>(
    'DEFAULT'
  );

  const goToRecipt = () => {
    if (refundCondition === 'REFUND_ONLY') {
      dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: false } });
      history.push(routes.home);
    } else {
      dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: true } });
      if (isUploadRequired) {
        history.push(routes.receiptInquiry);
      } else {
        history.push(routes.home, {
          scrollToReceipt: true,
        });
      }
    }
  };
  const onNavigate = (value: string) => {
    history.push(value);
  };

  useEffect(() => {
    isUploadRequired && isEmailChanged && setSendingStatus('IS_SENDING');
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: { isRefundInfoRegistered: true },
    });
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      history.push(routes.home);
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <ReceiptLayout>
      {sendingStatus === 'IS_SENDING' && (
        <SendingReceiptMail setSendingStatus={setSendingStatus} />
      )}
      <ProgressBar percent={100} />
      <Img src={CheckCircle} />
      <Title>
        {nationality === 'KOR' ? `모든 정보를 입력했습니다.` : `Completed`}
      </Title>
      <Description>
        {nationality === 'KOR'
          ? isUploadRequired
            ? `Tax Refund 영수증을 출력하고\n세관 도장을 받으세요.`
            : `세관에 방문하여 구매한 물건과\n모바일 Tax Refund 영수증을 보여주세요.\n이후 텍스리펀까지 영업일 기준 평균 14일 가량 소요됩니다.`
          : getIsFrequentCountry(nationality)
          ? 'Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we’ll transfer your refund amount\nto the information you provided within 2 weeks.'
          : "Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we'll send an email to request your information\nfor processing refunds within 10 days.\nPlease check your mailbox."}
      </Description>
      <TextButton
        title={
          nationality === 'KOR'
            ? '내 Tax Refund 영수증 보기'
            : 'My Mobile Tax Refund Receipts'
        }
        color='#FFFFFF'
        bgColor='#246CF6'
        margin='0 0 20px'
        onClick={goToRecipt}
      />
      <TextButton
        title={nationality === 'KOR' && isUploadRequired ? '홈으로' : 'Home'}
        color='#246CF6'
        bgColor='#FFFFFF'
        outline
        onClick={() => onNavigate(routes.home)}
      />
    </ReceiptLayout>
  );
}
const Title = styled.h2`
  color: #1856cd;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 20px;
  font-weight: 700;
  width: 410px;
  text-align: center;
  white-space: pre-wrap;
`;
const Img = styled.img`
  width: 66px;
  height: 66px;
  margin: 200px auto 52px;
  transform: translateX(-4px);
  @media ${(props) => props.theme.mobile} {
    margin: 130px auto 20px;
  }
`;
const Description = styled.p`
  font-size: 14px;
  color: #3a3b3e;
  line-height: 150%;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 100px;
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 70px;
  }
`;
export default RefundResult;

import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

function ProgressBar() {
  const {
    isRefundInfoRegistered,
    paymentStatus,
    isUploadRequired,
    isReceiptImageUploaded,
    unapprovedCount,
    nationality,
  } = useAppSelector((state) => state) as IGlobalState;

  const currentStep = isUploadRequired
    ? paymentStatus === 'PAYMENT_COMPLETE' &&
      isRefundInfoRegistered &&
      isReceiptImageUploaded
      ? 4
      : isReceiptImageUploaded && isRefundInfoRegistered
      ? 3
      : isRefundInfoRegistered
      ? 2
      : 1
    : paymentStatus === 'PAYMENT_COMPLETE' &&
      unapprovedCount === 0 &&
      isRefundInfoRegistered
    ? 4
    : unapprovedCount === 0 && isRefundInfoRegistered
    ? 3
    : isRefundInfoRegistered
    ? 2
    : 1;
  const getProgressPercent = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return 13;
      case 2:
        return 53;
      case 3:
        return 90;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  return (
    <Container>
      <BarWrapper>
        <Bar progress={getProgressPercent(currentStep)} />
        <Dot left='9%' status={currentStep === 1 ? 'ACTIVE' : 'COMPLETE'} />
        <Dot
          left='50%'
          status={
            currentStep === 1
              ? 'NOT_YET'
              : currentStep === 2
              ? 'ACTIVE'
              : 'COMPLETE'
          }
        />
        <Dot
          left='88%'
          status={
            currentStep === 3
              ? 'ACTIVE'
              : currentStep === 4
              ? 'COMPLETE'
              : 'NOT_YET'
          }
        />
      </BarWrapper>
      <FlexWrap justifyContent='space-between'>
        <ProgressLabel active={currentStep === 1}>
          {nationality === 'KOR' ? '정보 입력' : `Enter\ninformation`}
        </ProgressLabel>
        <ProgressLabel active={currentStep === 2}>
          {isUploadRequired
            ? '도장 사진 올리기'
            : nationality === 'KOR'
            ? '세관 방문'
            : `Visit\ncustoms`}
        </ProgressLabel>
        <ProgressLabel active={currentStep === 3}>
          {nationality === 'KOR' ? '환급액 수취' : `Receive\nrefunds`}
        </ProgressLabel>
      </FlexWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const BarWrapper = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  border-radius: 100px;
  background-color: #e5e6e8;
`;
const Bar = styled.div<{ progress: number }>`
  height: 12px;
  background-color: #246cf6;
  width: ${(props) => props.progress}%;
  border-radius: 100px;
  transition: all ease-in 500ms;
`;
const Dot = styled.div<{
  left: string;
  status: 'NOT_YET' | 'ACTIVE' | 'COMPLETE';
}>`
  width: 12px;
  height: 12px;
  top: 0;
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === 'NOT_YET'
      ? '#CBCCCE'
      : props.status === 'ACTIVE'
      ? '#A7C4FB'
      : '#5089F8'};
  position: absolute;
  left: ${(props) => props.left};
  transition: all ease-in 500ms;
`;
const ProgressLabel = styled.span<{ active: boolean }>`
  font-size: 12px;
  line-height: 150%;
  margin-top: 5px;
  color: ${(props) => (props.active ? '#1856CD' : '#3a3b3e')};
  text-align: center;
  white-space: pre-wrap;
`;

export default ProgressBar;

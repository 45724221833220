import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { routes } from 'routes';

import { verifyEmail, verifyPassport } from 'api';
import LoadingView from 'components/_common/LoadingView';
import { useAppDispatch } from 'hooks/useReduxHooks';
import {
  IVerifyEmailPayload,
  IVerifyPassportPayload,
  IVerifyPassportResponse,
} from 'model/types';

type Props = {
  id: string;
  verifiedEmail: string;
};
function VerifiactionRedirect() {
  const { id, verifiedEmail } = useParams() as Props;
  const passportNumber = id.toUpperCase();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const passportMutation = useMutation<
    IVerifyPassportResponse,
    AxiosError,
    IVerifyPassportPayload
  >((payload) => verifyPassport(payload), {
    retry: false,
    onSuccess: ({
      departureDate,
      register,
      customerNation,
      read,
      receiptUpload,
      uploadRequired,
      departureStatus,
      showCatUi,
      email,
      hasOverPrice,
    }) => {
      dispatch({
        type: 'UPDATE_USER_INFO',
        payload: {
          passportNumber,
          departureDate,
          isRefundInfoRegistered: register,
          nationality: customerNation,
          haveSeenReceipt: read,
          isReceiptImageUploaded: receiptUpload,
          isUploadRequired: uploadRequired,
          isCustomerLeft: departureStatus === 'DEPARTURE_COMPLETE',
          showCatUi: false, //원래 showCatUi가 전달되어야하는데 서버 문제로 일단 false 고정으로 함
          email,
          hasOverPrice,
        },
      });
      departureDate && localStorage.setItem('departureDate', departureDate);
      customerNation && localStorage.setItem('customerNation', customerNation);
      localStorage.setItem('login', passportNumber);
      verifyMutation.mutate({ passportNumber, verifiedEmail });
    },
  });
  const verifyMutation = useMutation<number, AxiosError, IVerifyEmailPayload>(
    (payload) => verifyEmail(payload),
    {
      retry: false,
      onSuccess: () => {
        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: {
            verifiedEmail,
          },
        });
        history.push(routes.customerRefund);
      },
      onError: () => {
        alert('이메일 인증에 실패하였습니다. 다시 시도해주세요.');
      },
    }
  );

  useEffect(() => {
    passportMutation.mutate({ passportNumber });
  }, []);

  return <LoadingView />;
}

export default VerifiactionRedirect;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import closeImg from 'assets/common/close.png';
import { useAppDispatch } from 'hooks/useReduxHooks';

interface IProps {
  name: string;
  placeInfo: any;
}

function PlaceDetailModal({ name, placeInfo }: IProps) {
  const { placeDetail, refundPrintImg } = placeInfo;
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: { type: 'LOCATION', isActive: false, data: null },
    });
  };
  return (
    <BackDrop onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <CloseImg width='24px' height='24px' src={closeImg} onClick={onClose} />
        <Title>세관 위치 안내</Title>
        <DivideLine />
        <Info>
          <Location>{name}</Location>
          <LocationDetail>{placeDetail}</LocationDetail>
          <LocationImage width='100%' height='210px' src={refundPrintImg} />
        </Info>
      </Container>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 375px;
  height: 100%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.4);
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const Container = styled.div`
  position: relative;
  top: 20%;
  width: 315px;
  height: 420px;
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0px 0px 20px 0px rgba(174, 191, 204, 0.5);
`;
const CloseImg = styled.img`
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-top: 60px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: #cbccce;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
const Location = styled.p``;
const LocationDetail = styled.p`
  font-weight: 400;
`;
const LocationImage = styled.img`
  object-fit: contain;
  align-self: flex-end;
`;
export default PlaceDetailModal;

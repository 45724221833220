import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import Passport from 'assets/receipt/passport.png';
import { IErrorState } from 'pages/ktp/PassportValidate';
import Input from 'components/_common/Input';
import TextButton from 'components/_common/TextButton';

interface IProps {
  onSubmit: any;
  error?: IErrorState;
}
function PassportInputForm({ onSubmit, error }: IProps) {
  const { register, setValue, watch, handleSubmit } = useFormContext();
  const { passportNumber } = watch();

  return (
    <>
      <Title>
        Please enter your
        <br />
        <Bold>Passport NO.</Bold>
      </Title>
      <PassportImg src={Passport} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('passportNumber', {
            onChange: (e) =>
              setValue(
                'passportNumber',
                e.target.value.toUpperCase().replace(/\s+/g, '')
              ),
          })}
          label='Passport No'
          isRequired
          placeholder='ex) M123A4567'
          maxLength={13}
          error={error}
          width='335px'
          margin={[0, 0, 70]}
        />
        <TextButton isActive={!!passportNumber} title='CHECK' bottomFixed />
      </Form>
    </>
  );
}
const Form = styled.form`
  position: relative;
`;
const Title = styled.p`
  text-align: start;
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media ${(props) => props.theme.mobile} {
    margin-top: 0;
  }
`;
const Bold = styled.strong`
  font-weight: 500;
`;
const PassportImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;
export default PassportInputForm;

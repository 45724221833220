import { useHistory } from 'react-router';
import styled from 'styled-components';

import TextButton from 'components/_common/TextButton';
import FlexWrap from 'components/_common/FlexWrap';
import DdayItem from './DdayItem';
import CurrentStatus from './CurrentStatus';
import RefundAmount from './RefundAmount';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { routes } from 'routes';
import { IGlobalState, initialGlobalState } from 'store';
import ReceiptAccordionContent from './ReceiptAccordionContent';

function TopSection() {
  const dispatch = useAppDispatch();
  const { refundAfter, nationality } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const history = useHistory();

  const onCloseModal = () =>
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        isActive: false,
        type: null,
        data: null,
      },
    });

  const onOpenLogoutModal = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        isActive: true,
        type: 'CUSTOM',
        data: {
          title: nationality === 'KOR' ? '로그아웃 할까요?' : 'Sign Out',
          content:
            nationality === 'KOR'
              ? '다른 여권번호로 조회하려면 로그아웃 하세요.'
              : `Sign out to look up\nanother passport number.`,
          btnText: nationality === 'KOR' ? '로그아웃' : 'Sign Out',
          subBtnText: nationality === 'KOR' ? '취소' : 'Cancel',
          btnCallback: logout,
        },
      },
    });
  };

  const logout = () => {
    onCloseModal();
    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: initialGlobalState,
    });
    localStorage.removeItem('login');
    localStorage.removeItem('departureDate');
    localStorage.removeItem('customerNation');
    history.push(routes.passport);
  };

  return (
    <Container dir='column' justifyContent='center'>
      {refundAfter && <DdayItem />}
      <CurrentStatus />
      <RefundAmount />
      {!refundAfter && (
        <>
          <TextButton
            title={nationality === 'KOR' ? '로그아웃' : 'Sign Out'}
            onClick={onOpenLogoutModal}
            color='#1856CD'
            bgColor='#fff'
            outline
            margin='28px 0'
          />
          <ReceiptAccordionContent />
        </>
      )}
    </Container>
  );
}

const Container = styled(FlexWrap)`
  padding: 0 20px 20px;
  width: 375px;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;

export default TopSection;

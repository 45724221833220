import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'lodash-es';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { getRefundInfoData } from 'api';

import Input from 'components/_common/Input';
import LoadingView from 'components/_common/LoadingView';
import TextButton from 'components/_common/TextButton';
import { routes } from 'routes';
import { IReceiptInfo } from 'types/ktp';
import Receipt from './Receipt';

type UserInputFormProps = {
  passportNumber?: string;
};

function UserInputForm({ passportNumber }: UserInputFormProps) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useFormContext();
  const { name, nation, email, passportNum } = watch();
  const [activeBtnRef, setActiveBtnRef] = useState(true);
  const [receiptInfo, setReceiptInfo] = useState<IReceiptInfo[]>([]);
  const [isAddFile, setIsAddFile] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { isLoading, mutate: postRefundInfo } = useMutation<
    string,
    AxiosError,
    FormData
  >((payload) => getRefundInfoData(payload), {
    onError: () => {
      alert(
        'Failed to enter information.\nPlease send e-mail to\n"ktaxpay@gmail.com"'
      );
    },
    onSuccess: () => {
      alert(
        'Information input completed.\nThe refund process will be\ninformed by the email\nwithin 1-2 days.'
      );
      history.push(routes.passport);
    },
  });

  useEffect(() => {
    if (emailInputRef.current && !activeBtnRef) {
      emailInputRef.current.focus();
    }
  }, [activeBtnRef]);

  const onSubmit = debounce(async () => {
    setActiveBtnRef(false);
    const emailRegex =
      /[a-zA-Z0-9]{1,}@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!emailRegex.test(email)) {
      setError('email', { message: 'The email format is not valid.' });
      setActiveBtnRef(true);
      if (emailInputRef.current) {
        emailInputRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      return;
    }
    const formData = new FormData();
    receiptInfo.forEach((item) => {
      formData.append('files', item.file);
    });
    const refundInfo = {
      name,
      nation,
      email,
      passportNum: passportNumber,
      type: 'KTP',
    };
    const blob = new Blob([JSON.stringify(refundInfo)], {
      type: 'application/json',
    });
    formData.append('dto', blob);
    postRefundInfo(formData);
    setActiveBtnRef(true);
  }, 500);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFile: FileList | null = e.target.files;
    if (newFile === null) return;
    const reader = new FileReader();
    reader.readAsDataURL(newFile[0]);
    reader.onloadend = () => {
      setReceiptInfo([
        ...receiptInfo,
        { image: reader.result as string, file: newFile[0] },
      ]);
    };
    setIsAddFile(true);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };
  const onChangeText = (e: { target: { value: string; name: string } }) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setValue(e.target.name, uppercaseValue);
  };

  return (
    <Wrapper>
      {isLoading && (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      )}
      <Form>
        <Input
          {...register('name')}
          onChange={onChangeText}
          label='Name'
          isRequired
          placeholder='ex) James Smith'
          margin={[0, 0, 16]}
          width='335px'
        />
        <Input
          {...register('nation')}
          onChange={onChangeText}
          label='Issuing country'
          isRequired
          placeholder='ex) KOR'
          maxLength={13}
          width='335px'
          margin={[0, 0, 16]}
        />
        <Input
          {...(register('email'),
          {
            onChange: (e) => setValue('email', e.target.value),
          })}
          label='E-mail'
          isRequired
          placeholder='ex) abc@email.com'
          width='335px'
          margin={[0, 0, 16]}
          error={errors.email}
          ref={emailInputRef}
        />
        <Input
          {...register('fileImage')}
          label='Store receipt'
          isRequired
          isFile
          fileBtnText={isAddFile ? 'Image Uploaded' : 'Upload Image'}
          type='file'
          accept='.jpg, .jpeg, .png, .gif'
          onChange={handleFileChange}
          onClick={handleButtonClick}
          fileInputRef={fileInputRef}
        />
      </Form>
      {receiptInfo.length > 0 && (
        <>
          <DivideLine />
          <ReceiptList>
            {receiptInfo.map((item, idx) => (
              <Receipt key={idx} info={item} />
            ))}
          </ReceiptList>
        </>
      )}
      <TextButton
        isActive={!!(name && nation && email && isAddFile && activeBtnRef)}
        title='CHECK'
        bottomFixed
        onClick={onSubmit}
        disabled={!(name && nation && email && isAddFile && activeBtnRef)}
      />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Form = styled.div`
  position: relative;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbccce;
  margin: 20px 0 12px;
`;
const ReceiptList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
`;
export default UserInputForm;

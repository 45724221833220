import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router';
import { useMutation } from 'react-query';

import { getHome } from 'api';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TopSection from 'components/Home/TopSection';
import BottomSection from 'components/Home/BottomSection';
import { IGlobalState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { getDday } from 'utils/common';
import { IVerifyPassportPayload, IVerifyPassportResponse } from 'model/types';
import alertIcon from 'assets/home/check_circle_active.png';

type Params = {
  scrollToReceipt?: boolean;
};
function Home() {
  const dispatch = useAppDispatch();
  const params = useLocation().state as Params;
  const scrollToReceipt = params?.scrollToReceipt;
  const history = useHistory();
  const {
    isUploadRequired,
    passportNumber,
    departureDate,
    refundAfter,
    nationality,
  } = useAppSelector((state) => state) as IGlobalState;

  const onCloseModal = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: { isActive: false, data: null, type: null },
    });
  };

  const homeMutation = useMutation<
    IVerifyPassportResponse,
    AxiosError,
    IVerifyPassportPayload
  >((payload) => getHome(payload), {
    retry: false,
    onSuccess: ({
      register,
      read,
      receiptUpload,
      departureStatus,
      paymentStatus,
      approvedCount,
      unapprovedCount,
      refundAmount,
      refundAfterAmount,
      email,
      refundCondition,
    }) => {
      dispatch({
        type: 'UPDATE_USER_INFO',
        payload: {
          isRefundInfoRegistered: register,
          haveSeenReceipt: read,
          isReceiptImageUploaded: receiptUpload,
          isCustomerLeft: isUploadRequired
            ? getDday(departureDate as string) === 'D-DAY'
            : departureStatus === 'DEPARTURE_COMPLETE',
          paymentStatus,
          approvedCount,
          unapprovedCount,
          refundAmount,
          refundAfterAmount,
          email,
          refundCondition,
        },
      });

      // 임시 중국 중단 송금 안내
      if (
        nationality === 'CHN' &&
        unapprovedCount === 0 &&
        register &&
        paymentStatus === 'PAYMENT_WAIT'
      ) {
        dispatch({
          type: 'UPDATE_HOME_MODAL_STATE',
          payload: {
            isActive: true,
            type: 'CUSTOM',
            data: {
              title: '汇款延迟通知',
              content: `谢谢您的等待。\n汇款会重新进行，预计一周内到账。`,
              btnText: '确认',
              icon: alertIcon,
              btnCallback: () => {},
            },
          },
        });
      }
    },
  });
  const fetchHome = () => {
    homeMutation.mutate({ passportNumber: passportNumber as string });
  };

  useEffect(() => {
    fetchHome();
  }, [passportNumber]);

  useEffect(() => {
    const preventGoBack = () => {
      onCloseModal();
      history.go(1);
    };
    window.addEventListener('popstate', preventGoBack);
    return () => window.removeEventListener('popstate', preventGoBack);
  }, []);

  return (
    <ReceiptLayout hasPadding={false} style={{ paddingBottom: 56 }}>
      <TopSection />
      {refundAfter && (
        <BottomSection
          refetchHome={fetchHome}
          scrollToReceipt={scrollToReceipt}
        />
      )}
    </ReceiptLayout>
  );
}
export default Home;

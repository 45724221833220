import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TopSection from 'components/RefundProcedure/TopSection';
import BottomSection from 'components/RefundProcedure/BottomSection';
import { useEffect } from 'react';

function RefundProcedure() {
  useEffect(() => {
    const handleBackButton = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', handleBackButton);
    return () => window.removeEventListener('popstate', handleBackButton);
  }, []);

  return (
    <ReceiptLayout hasPadding={false}>
      <TopSection />
      <BottomSection />
    </ReceiptLayout>
  );
}

export default RefundProcedure;

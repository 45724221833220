import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { Spin } from 'animation';
import { sendInquiryMail } from 'api';
import { qnaData, qnaDataEng } from 'constant';
import ToggleItem from 'components/QnA/ToggleItem';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TextButton from 'components/_common/TextButton';
import CustomModal from 'components/_common/CustomModal';
import { ISendInquiryMailPayload } from 'model/types';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

function QnA() {
  const { passportNumber, email, nationality } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [mailContent, setMailContent] = useState('');
  const [isModalActive, setIsModalActive] = useState(false);
  const mutation = useMutation<number, AxiosError, ISendInquiryMailPayload>(
    (payload) => sendInquiryMail(payload),
    {
      retry: false,
      onSuccess: () => {
        setMailContent('');
        onToggleModal();
        alert(
          nationality === 'KOR'
            ? `문의가 완료되었습니다.\n답변까지 2~3일 정도 소요됩니다.\n빠른 시일 내로 답변 드리겠습니다.`
            : `You have sent an inquiry.\nPlease allow 2-3 days for a response.`
        );
      },
      onError: () => {
        alert('메일 전송에 실패하였습니다.');
      },
    }
  );
  const onToggleModal = () => {
    setMailContent('');
    setIsModalActive((prev) => !prev);
  };
  const onChangeInput = (e: any) => {
    setMailContent(e.target.value);
  };
  const onSubmit = () => {
    mutation.mutate({
      passportNumber: passportNumber as string,
      email,
      content: mailContent,
    });
  };

  return (
    <ReceiptLayout hasPadding={false}>
      <Title>
        {nationality === 'KOR'
          ? `문의하시기 전에\n질문들을 확인해보세요`
          : 'FAQ'}
      </Title>
      {(nationality === 'KOR' ? qnaData : qnaDataEng).map((data, idx) => (
        <ToggleItem key={idx} id={idx} {...data} />
      ))}
      <BottomSection>
        <TextButton
          title={
            nationality === 'KOR'
              ? '기타 문의 작성'
              : 'Mail to customer service center'
          }
          bgColor='#fff'
          color='#246CF6'
          outline
          onClick={onToggleModal}
        />
      </BottomSection>
      {isModalActive && (
        <CustomModal
          btnText={nationality === 'KOR' ? '작성완료' : 'Send'}
          title={
            nationality === 'KOR' ? 'KTP 문의하기' : 'Mail to customer center'
          }
          content={
            mutation.isLoading ? (
              <LoadingView>
                <LoadingSpinner />
              </LoadingView>
            ) : (
              <MailContent
                placeholder={
                  nationality === 'KOR'
                    ? '서비스 이용 절차 등 궁금하신 사항을 남겨주세요. 문의 내용을 확인하여 빠르게 연락드리도록 하겠습니다.'
                    : `Leave us a message with your questions. We'll read it and get back to you as soon as possible.`
                }
                value={mailContent}
                onChange={onChangeInput}
              />
            )
          }
          btnActive={Boolean(mailContent.length !== 0 && !mutation.isLoading)}
          onClose={onToggleModal}
          btnCallback={onSubmit}
          preventDefault
        />
      )}
    </ReceiptLayout>
  );
}
const LoadingView = styled.div`
  width: 100%;
  height: 156px;
  display: flex;
  align-items: center;
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 5px solid #fff;
  margin: 0 auto;
  border-top: 5px solid #246cf6;
`;
const Title = styled.h2`
  color: #246cf6;
  font-size: 22px;
  line-height: 150%;
  white-space: pre-wrap;
  margin: 28px auto 28px 20px;
  font-weight: 700;
`;
const BottomSection = styled.div`
  background-color: #f5f6f7;
  width: 100%;
  padding: 28px 20px 0;
  margin-top: 40px;
  flex: 1;
`;
const MailContent = styled.textarea`
  height: 156px;
  width: 287px;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  line-height: 150%;
  resize: none;
  outline: none;
  border: 1px solid #80848a;
  ::placeholder {
    color: #80848a;
  }
`;
export default QnA;

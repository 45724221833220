export const NotificationData = {
  koreanText: [
    {
      title: '1. 세관 방문',
      description: '이 영수증과 물품을 세관에 보여주고 도장을 받으세요.',
    },
    {
      title: '2. 영수증 도장 사진 촬영',
      description: '세관 도장을 받은 영수증 사진을 웹사이트에 올려주세요.',
    },
    {
      title: '3. 환급액 지급',
      description:
        '사진 확인이 완료되면 입력하신 계좌로 환급액을 보내드립니다. 환급액 지급까지 6-7일 가량 소요됩니다.',
    },
  ],
  englishText: [
    {
      title: '1. Visit customs',
      description:
        'Show the Tax Refund receipt and your items to customs and get the reciept stamped.',
    },
    {
      title: '2. Take a photo of your receipt stamp.',
      description:
        'Please upload a photo of your KTP Tax Refund receipt with the customs stamp on your website.',
    },
    {
      title: '3. Recieve Your Refund',
      description:
        "After customs verification is complete, we'll send your refund to the information you provided. It will be completed within one week.",
    },
  ],
  qrGuideText: {
    title: '계좌 정보 입력 및 영수증 사진 등록용 웹사이트 연결 QR',
    description:
      'QR code for entering account information ans uploading photos of stemped receipts.',
  },
};

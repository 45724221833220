import { Fragment, useEffect } from 'react';
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { attachedHyphensDate } from 'utils/common';
import Input from 'components/_common/Input';
import { IRefundInfoForm } from 'model/types';
import { useAppSelector } from 'hooks/useReduxHooks';
import styled from 'styled-components';
import FlexWrap from 'components/_common/FlexWrap';
import ArrowDown from 'assets/common/arrow_down.png';
import { bankList } from 'constant';

interface IProps {
  register: UseFormRegister<IRefundInfoForm>;
  getValues: UseFormGetValues<IRefundInfoForm>;
  setValue: UseFormSetValue<IRefundInfoForm>;
  errors?: FieldErrors;
  disabled?: boolean;
  bankName?: string;
  isRegister?: boolean;
  isMedical?: boolean;
}
function RefundInfoForm({
  register,
  setValue,
  errors,
  disabled = false,
  bankName,
  getValues,
}: IProps) {
  const { nationality } = useAppSelector((state) => state);
  useEffect(() => {
    if (bankName !== '없음') {
      return;
    }
    setValue('bankAccount', '');
  }, [bankName]);

  return (
    <>
      <Input
        {...register(
          nationality === 'CHN' || nationality === 'KOR'
            ? 'firstName'
            : 'lastName',
          {
            required:
              nationality === 'CHN'
                ? '这是必填项。'
                : 'This is a required item.',
            pattern: {
              value:
                nationality === 'CHN'
                  ? /^\S[\u4e00-\u9fa50-9]*$/
                  : nationality === 'KOR'
                  ? /^\S[가-힣]*$/
                  : /^\S[a-zA-Z0-9\s]+$/,
              message:
                nationality === 'KOR'
                  ? '한글로 입력해주세요.'
                  : nationality === 'CHN'
                  ? '请用中文输入。'
                  : 'Please use English only.',
            },
          }
        )}
        error={
          nationality === 'CHN' || nationality === 'KOR'
            ? errors?.firstName
            : errors?.lastName
        }
        label={
          nationality === 'KOR'
            ? '성'
            : nationality === 'CHN'
            ? '姓'
            : nationality === 'JPN'
            ? '受取人名 (名)'
            : nationality === 'THA'
            ? 'ชื่อ'
            : 'Given Names'
        }
        isRequired
        placeholder={
          nationality === 'KOR'
            ? 'Surname (한국어만 입력 Korean only)'
            : nationality === 'CHN'
            ? '姓 仅限中文'
            : 'Given Names in English'
        }
        margin={[0, 0, 24]}
        disabled={disabled}
      />
      <Input
        {...register(
          nationality === 'CHN' || nationality === 'KOR'
            ? 'lastName'
            : 'firstName',
          {
            required:
              nationality === 'CHN'
                ? '这是必填项。'
                : 'This is a required item.',
            pattern: {
              value:
                nationality === 'CHN'
                  ? /^\S[\u4e00-\u9fa50-9]*$/
                  : nationality === 'KOR'
                  ? /^\S[가-힣]*$/
                  : /^\S[a-zA-Z0-9\s]+$/,
              message:
                nationality === 'KOR'
                  ? '한글로 입력해주세요.'
                  : nationality === 'CHN'
                  ? '请用中文输入。'
                  : 'Please use English only.',
            },
          }
        )}
        error={
          nationality === 'CHN' || nationality === 'KOR'
            ? errors?.lastName
            : errors?.firstName
        }
        label={
          nationality === 'KOR'
            ? '이름'
            : nationality === 'CHN'
            ? '名字'
            : nationality === 'JPN'
            ? '受取人名 (姓)'
            : nationality === 'THA'
            ? 'นามสกุล'
            : 'Surname'
        }
        isRequired
        placeholder={
          nationality === 'KOR'
            ? 'Given Name (한국어만 입력 Korean only)'
            : nationality === 'CHN'
            ? '名字 仅限中文'
            : 'Surname in English'
        }
        margin={[0, 0, 24]}
        disabled={disabled}
      />
      {nationality === 'CHN' && (
        <Fragment>
          <Input
            {...register('firstNameEng', {
              required: '这是必填项。',
              pattern: {
                value: /^\S[a-zA-Z0-9\s]+$/,
                message: '请仅使用英语。',
              },
            })}
            error={errors?.firstNameEng}
            label='姓 (Surname)'
            isRequired
            placeholder='Surname in English'
            margin={[0, 0, 24]}
            disabled={disabled}
          />
          <Input
            {...register('lastNameEng', {
              required: '这是必填项。',
              pattern: {
                value: /^\S[a-zA-Z0-9\s]+$/,
                message: '请仅使用英语。',
              },
            })}
            error={errors?.lastNameEng}
            label='名字 (Given names)'
            isRequired
            placeholder='Given names in English'
            margin={[0, 0, 24]}
            disabled={disabled}
          />
        </Fragment>
      )}
      <Input
        {...register('birth', {
          required:
            nationality === 'CHN' ? '这是必填项。' : 'This is a required item.',
        })}
        error={errors?.birth}
        label={
          nationality === 'KOR'
            ? '생년월일'
            : nationality === 'CHN'
            ? '生日 '
            : nationality === 'JPN'
            ? '誕生日'
            : nationality === 'THA'
            ? 'วันเกิด'
            : 'Date of Birth'
        }
        isRequired
        onChange={(e) => setValue('birth', attachedHyphensDate(e.target.value))}
        maxLength={10}
        placeholder='1993-05-11'
        margin={[0, 0, 24]}
        disabled={disabled}
      />
      <Input
        {...register('phoneNumber', {
          required:
            nationality === 'CHN' ? '这是必填项。' : 'This is a required item.',
          pattern: {
            value: /^\d+$/,
            message:
              nationality === 'CHN'
                ? '请仅填写号码。'
                : nationality === 'KOR'
                ? '숫자만 입력해주세요.'
                : 'Please fill in the number only.',
          },
        })}
        type='number'
        error={errors?.phoneNumber}
        label={
          nationality === 'KOR'
            ? '연락처'
            : nationality === 'CHN'
            ? '手机号码'
            : nationality === 'JPN'
            ? '携帯電話番号'
            : nationality === 'THA'
            ? 'หมายเลขโทรศัพท'
            : 'Mobile Number'
        }
        isRequired
        placeholder='Phone Mobile Number'
        margin={[0, 0, 24]}
        disabled={disabled}
      />{' '}
      {nationality === 'KOR' && (
        <>
          <LabelWrapper>
            <Label>계좌번호</Label>
            <RequiredDot>*</RequiredDot>
          </LabelWrapper>
          <BankWrap>
            <ArrowIcon src={ArrowDown} />
            <Select
              {...register('bank')}
              disabled={disabled}
              isSelected={Boolean(getValues('bank'))}
            >
              <Option value='' hidden>
                은행 이름 입력
              </Option>
              {bankList.map((bank) => (
                <Option value={bank} selected={bank === '없음'}>
                  {bank}
                </Option>
              ))}
            </Select>
          </BankWrap>
          <Input
            placeholder='계좌번호 입력'
            {...register('bankAccount', {
              required:
                getValues('bank') === '없음'
                  ? false
                  : '계좌번호를 입력해주세요.',
              pattern: {
                value: /^\d+$/,
                message: '숫자만 입력해주세요.',
              },
            })}
            disabled={disabled || bankName === '없음'}
            error={errors?.bankAccount}
            margin={[8, 0, 8]}
          />
          <Input
            placeholder='예금주를 입력해주세요'
            {...register('accountHolder', {
              required: '예금주를 입력해주세요.',
              pattern: {
                value: /^\S[가-힣]*$/,
                message: '한글로 입력해주세요.',
              },
            })}
            disabled={disabled}
            error={errors?.accountHolder}
            margin={[8, 0, 24]}
          />
          <GuideText>
            ※ 해외 계좌 송금을 원하실 경우에는 은행을 ‘없음’으로 선택 바랍니다.
            입력하신 이메일로 출국 예정일로부터 7일 이내에 안내 메일 발송해
            드립니다. 해외 계좌 송금 시 수수료가 발생 됩니다.
          </GuideText>
        </>
      )}
    </>
  );
}

const GuideText = styled.p`
  color: #a7a7a7;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 20px 0 40px;
  @media ${(props) => props.theme.mobile} {
    margin: 20px 0 20px;
  }
`;
const Select = styled.select<{
  isSelected?: boolean;
}>`
  width: 100%;
  border: 1px solid #cbccce;
  cursor: pointer;
  padding: 14px 13px;
  border-radius: 4px;
  appearance: none;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.isSelected ? '#3a3b3e' : '#CBCCCE')};
  cursor: default;
`;
const BankWrap = styled(FlexWrap)`
  position: relative;
`;
const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 13px;
  bottom: 15px;
`;
const Option = styled.option``;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;
const Label = styled.label`
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 4px;
  padding-bottom: 6px;
`;

export default RefundInfoForm;

import FlexWrap from 'components/_common/FlexWrap';
import { MutableRefObject, useEffect } from 'react';
import styled from 'styled-components';

import Leaflet from 'assets/uploadImage/leaflet.png';
import Case1 from 'assets/uploadImage/case_1.png';
import Case2 from 'assets/uploadImage/case_2.png';
import Case3 from 'assets/uploadImage/case_3.png';
import Case4 from 'assets/uploadImage/case_4.png';
import Close from 'assets/common/close.png';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch } from 'hooks/useReduxHooks';

interface IProps {
  inputRef: MutableRefObject<HTMLInputElement | null>;
}
function GuideModal({ inputRef }: IProps) {
  const dispatch = useAppDispatch();
  const data = [
    { text: '근접하여 촬영', imageUrl: Case1 },
    { text: '흔들리거나 뿌옇게 촬영', imageUrl: Case2 },
    { text: '밝은 환경에서 촬영', imageUrl: Case3 },
    { text: '어두운 환경에서 촬영', imageUrl: Case4 },
  ];

  const closeModal = () => {
    document.body.style.removeProperty('overflow');
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        type: null,
        isActive: false,
        data: null,
      },
    });
    inputRef?.current?.click();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <BackContainer justifyContent='center' alignItems='center'>
      <ModalContainer dir='column'>
        <CloseBtn src={Close} onClick={closeModal} />
        <Title>세관 도장 사진 안내</Title>
        <Wrapper>
          <SubTitle>① 출력한 Tax Refund 영수증에 도장 받기</SubTitle>
          <Desc>
            출력한 Tax Refund 영수증에 아래와 같이 세관 확인 도장을 받으세요.
          </Desc>
          <WholeImage src={Leaflet} />
          <SubTitle>② 영수증이 한 화면에 보이도록 촬영</SubTitle>
          <Desc>
            영수증이 전부 보이도록 촬영해주세요. 근접하거나 먼 경우 / 밝거나
            어두운 사진을 올리지 않도록 주의해주세요.
          </Desc>
          <GuideContainer justifyContent='space-between'>
            {data.map((item, idx) => (
              <GuideItem key={idx} dir='column'>
                <GuideImage src={item.imageUrl} />
                <GuideText>{item.text}</GuideText>
              </GuideItem>
            ))}
          </GuideContainer>
          <TextButton
            title='확인'
            onClick={closeModal}
            width='100%'
            margin='0 0 28px'
          />
        </Wrapper>
      </ModalContainer>
    </BackContainer>
  );
}

const BackContainer = styled(FlexWrap)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 200;
  @media ${(props) => props.theme.desktop} {
    max-width: 375px;
  }
`;
const ModalContainer = styled(FlexWrap)`
  width: 100%;
  height: 80%;
  position: relative;
  background-color: #ffffff;
  overflow-y: scroll;
  border-radius: 4px;
`;
const Wrapper = styled.div`
  padding: 0 20px;
`;
const Title = styled.h2`
  font-size: 22px;
  padding: 68px 0 18px;
  border-bottom: 1px solid #cbccce;
  text-align: center;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0 8px;
`;
const Desc = styled.p`
  font-size: 14px;
  line-height: 20px;
`;
const WholeImage = styled.img`
  width: 100%;
  margin-top: 20px;
`;
const GuideContainer = styled(FlexWrap)`
  flex-wrap: wrap;
  margin: 20px 0;
`;
const GuideItem = styled(FlexWrap)`
  flex-basis: 50%;
`;
const GuideText = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
  ::before {
    content: 'X';
    color: #ff5239;
    margin-right: 8px;
  }
`;
const GuideImage = styled.img`
  width: 140px;
  height: 140px;
`;
const CloseBtn = styled.img`
  position: absolute;
  top: 30px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export default GuideModal;

import { ReceiptResponseType } from 'model/types';
import { addComma } from './common';

export const removeLetter = (text: string) => {
  return text.replace(/[^0-9]/g, '');
};

export const comma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRefundPrice = (
  originPrice: string,
  paymentPrice: string
) => {
  return +originPrice - +paymentPrice;
};
export const formatPaymentPrice = (num: string) => {
  const vat = Math.floor((+num / 11) * 0.7);
  return vat < 100 ? +num - vat : Math.ceil((+num - vat) / 100) * 100;
};
export const formatNumber = (num: number) => {
  return String(num).length === 1 ? `0${num}` : num;
};
export const formatReceiptForm = (
  data: ReceiptResponseType,
  passportNumber: string
) => {
  return data?.map(
    ({
      category,
      purchaseSn,
      refundAfter,
      businessNumber,
      franchiseeName,
      saleDate,
      sellerName,
      storeAddress,
      storeTelNumber,
      taxFreeStoreNumber,
      totalAmount,
      totalRefund,
      expireDate,
      totalVat,
      barcodeS3Path,
    }) => ({
      purchaseSn,
      refundAfter,
      barcodeS3Path,
      rowDataArr: [
        {
          title: '지정번호',
          subTitle: 'Certificate No.',
          content: taxFreeStoreNumber,
        },
        {
          title: '판매일',
          subTitle: 'Date of Sale',
          content: saleDate,
        },
        {
          title: '판매자',
          subTitle: 'Retailer',
          content: sellerName,
        },
        { title: '상호', subTitle: 'Name of Company', content: franchiseeName },
        { title: '사업자번호', subTitle: 'Reg.No.', content: businessNumber },
        { title: '주소', subTitle: 'Address', content: storeAddress },
        {
          title: '대표자 / 연락처',
          content: `${sellerName} /    ${storeTelNumber}`,
          isLast: true,
        },
        {
          title: '물품구매내역',
          subTitle: 'Description of Goods',
          content: '',
        },
        {
          title: category,
          subTitle: '단가',
          hasComma: true,
          content: addComma(totalAmount),
        },
        { title: '', subTitle: '수량', content: '01' },
        {
          title: '',
          subTitle: '금액',
          hasComma: true,
          content: addComma(totalAmount),
        },
        ...(refundAfter
          ? [{ title: '결제유형', subTitle: '', content: '' }]
          : []),
        {
          title: '판매총액',
          subTitle: 'Total Payment',
          content: addComma(totalAmount),
          hasComma: true,
        },
        {
          title: '부가가치세',
          subTitle: 'V.A.T',
          content: addComma(totalVat),
          hasComma: true,
        },
        ...(refundAfter
          ? [
              {
                title: '개별소비세',
                subTitle: 'I.C.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '교육세',
                subTitle: 'E.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '농어촌특별세',
                subTitle: 'S.T.R.D',
                content: '0',
                hasComma: true,
              },
              {
                title: '새액계',
                subTitle: 'Total Tax',
                content: addComma(totalVat),
                hasComma: true,
              },
              {
                title: `제비용`,
              },
              {
                title: `(송금비용)`,
                subTitle: 'Administrative',
              },
              {
                title: '',
                subTitle: 'Charge',
                content: '',
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: `환급액`,
              },
              {
                title: `(송금세액)`,
                subTitle: 'Net Refund',
              },
              {
                title: '',
                subTitle: 'Value',
                content: addComma(totalRefund),
                hasComma: true,
              },
            ]
          : [
              {
                title: '즉시환급상당액',
                subTitle: 'Tax-free Amount',
                hasComma: true,
                content: addComma(totalRefund),
              },
            ]),
        ...(!refundAfter
          ? [
              {
                title: '결제금액',
                subTitle: 'Purchase Price',
                hasComma: true,
                content: addComma(+totalAmount - +totalRefund),
                isLast: true,
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: '반출유효기간',
                subTitle: 'Export Expiry',
              },
              {
                title: '',
                subTitle: 'Date',
                content: expireDate,
                isLast: true,
              },
            ]
          : []),

        {
          title: '구매자',
          subTitle: 'Customer in Passport',
        },
        {
          title: '여권번호',
          subTitle: 'Passport No.',
          content: passportNumber,
        },
        { title: '성명', subTitle: 'Passport Name', content: '' },
        { title: '국적', subTitle: 'Nationality', content: '' },
        { title: '주소', subTitle: 'Address for Refund', content: '' },
        { title: '', subTitle: '', content: '' },
        { title: '서명', subTitle: 'Signature' },
        {
          info: 'I agree above info is used only for Refund',
          isLast: !refundAfter,
        },
        ...(refundAfter
          ? [
              {
                title: '세관반출확인',
                subTitle: 'Stamp For Verification Of Export',
              },
            ]
          : []),
      ],
    })
  );
};

export const formatPrintReceiptForm = (
  data: ReceiptResponseType,
  passportNumber: string
) => {
  return data?.map(
    ({
      barcodeS3Path,
      category,
      purchaseSn,
      refundAfter,
      businessNumber,
      franchiseeName,
      saleDate,
      sellerName,
      storeAddress,
      storeTelNumber,
      taxFreeStoreNumber,
      totalAmount,
      totalRefund,
      expireDate,
      totalVat,
      refundIndex,
    }) => ({
      refundIndex,
      barcodeS3Path,
      purchaseSn,
      rowDataArr: [
        {
          title: '지정번호',
          subTitle: 'Certificate No.',
          content: taxFreeStoreNumber,
        },
        {
          title: '판매일',
          subTitle: 'Date of Sale',
          content: saleDate,
        },
        {
          title: '판매자',
          subTitle: 'Retailer',
          content: sellerName,
        },
        { title: '상호', subTitle: 'Name of Company', content: franchiseeName },
        { title: '사업자번호', subTitle: 'Reg.No.', content: businessNumber },
        { title: '주소', subTitle: 'Address', content: storeAddress },
        {
          title: '대표자 / 연락처',
          content: `${sellerName} /    ${storeTelNumber}`,
          isLast: true,
        },
        {
          title: '물품구매내역',
          subTitle: 'Description of Goods',
          content: '',
        },
        {
          title: category,
          subTitle: '단가',
          hasComma: true,
          content: addComma(totalAmount),
        },
        { title: '', subTitle: '수량', content: '01' },
        {
          title: '',
          subTitle: '금액',
          hasComma: true,
          content: addComma(totalAmount),
        },
        ...(refundAfter
          ? [{ title: '결제유형', subTitle: '', content: '' }]
          : []),
        {
          title: '판매총액',
          subTitle: 'Total Payment',
          content: addComma(totalAmount),
          hasComma: true,
        },
        {
          title: '부가가치세',
          subTitle: 'V.A.T',
          content: addComma(totalVat),
          hasComma: true,
        },
        ...(refundAfter
          ? [
              {
                title: '개별소비세',
                subTitle: 'I.C.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '교육세',
                subTitle: 'E.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '농어촌특별세',
                subTitle: 'S.T.R.D',
                content: '0',
                hasComma: true,
              },
              {
                title: '새액계',
                subTitle: 'Total Tax',
                content: addComma(totalVat),
                hasComma: true,
              },
              {
                title: `제비용(송금비용)`,
                subTitle: 'Administrative Charge',
                content: '',
              },
            ]
          : []),
        ...(!refundAfter
          ? [
              {
                title: '즉시환급상당액',
                subTitle: 'Tax-free Amount',
                hasComma: true,
                content: addComma(totalRefund),
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: `환급액(송금세액)`,
                subTitle: 'Net Refund Value',
                content: addComma(totalRefund),
                hasComma: true,
              },
            ]
          : []),
        ...(!refundAfter
          ? [
              {
                title: '결제금액',
                subTitle: 'Purchase Price',
                hasComma: true,
                content: addComma(totalAmount),
                isLast: true,
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: '반출유효기간',
                subTitle: 'Export Expiry Date',
                content: expireDate,
                isLast: true,
              },
            ]
          : []),
        {
          title: '구매자',
          subTitle: 'Customer in Passport',
        },
        {
          title: '여권번호',
          subTitle: 'Passport No.',
          content: passportNumber,
        },
        { title: '성명', subTitle: 'Passport Name', content: '' },
        { title: '국적', subTitle: 'Nationality', content: '' },
        { title: '주소', subTitle: 'Address for Refund', content: '' },
        { title: '', subTitle: '', content: '' },
        { title: '서명', subTitle: 'Signature' },
        {
          info: 'I agree above info is used only for Refund',
          isLast: !refundAfter,
        },
        ...(refundAfter
          ? [
              {
                title: '세관반출확인',
                subTitle: 'Stamp For Verification Of Export',
                content: '',
              },
            ]
          : []),
      ],
    })
  );
};

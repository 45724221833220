import { useEffect } from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import ReceiptImage from 'components/Receipt/ReceiptImage';
import { IData } from 'components/Receipt/RefundReceipt';
import ReceiptForPrint, {
  IPrintDataInfo,
} from 'components/Receipt/ReceiptForPrint';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

interface IProps {
  data: IPrintDataInfo | IData;
}
function ReceiptPreviewModal({ data }: IProps) {
  const dispatch = useAppDispatch();
  const { isUploadRequired } = useAppSelector((state) => state) as IGlobalState;

  const onClose = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: { type: 'PREVIEW_RECEIPT', isActive: false, data: null },
    });
  };

  useEffect(() => {
    window.addEventListener('popstate', onClose);
    return () => {
      window.removeEventListener('popstate', onClose);
    };
  }, []);

  return (
    <BackContainer
      onClick={() => {
        document.body.style.removeProperty('overflow');
        onClose();
      }}
    >
      {isUploadRequired ? (
        <FlexWrap dir='column'>
          <ReceiptWrapper>
            <ReceiptForPrint
              info={data as IPrintDataInfo}
              order={1}
              lastIndex={1}
            />
          </ReceiptWrapper>
          <TextButton
            title='닫기'
            bgColor='#E9F0FE'
            color='#1856CD'
            style={{
              position: 'absolute',
              top: '70%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            onClick={onClose}
          />
        </FlexWrap>
      ) : (
        <ReceiptImage
          info={data as IData}
          index={0}
          style={{
            height: '90%',
            overflowY: 'scroll',
          }}
        />
      )}
    </BackContainer>
  );
}
const BackContainer = styled(FlexWrap)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  @media ${(props) => props.theme.desktop} {
    max-width: 375px;
  }
`;

const ReceiptWrapper = styled.div`
  transform: scale(33.3%);
`;
export default ReceiptPreviewModal;

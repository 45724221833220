import React from 'react';
import styled from 'styled-components';

interface IProps {
  location: string;
  isActive: boolean;
  onClickFilter: (location: string) => void;
  onPreviewMove: () => void;
  onNextMove: () => void;
}

function GuideFilter({ location, isActive, onClickFilter }: IProps) {
  return (
    <Filter isActive={isActive}>
      <FilterButton isActive={isActive} onClick={() => onClickFilter(location)}>
        {location}
      </FilterButton>
    </Filter>
  );
}

const Filter = styled.div<{ isActive: boolean }>`
  border-radius: 100px;
  background-color: ${(props) => (props.isActive ? '#191919' : '#FFFFFF')};
  border: ${(props) => (props.isActive ? 'none' : '1px solid #000000')};
`;
const FilterButton = styled.button<{ isActive: boolean }>`
  white-space: nowrap;
  padding: 4px 25px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => (props.isActive ? '#FFFFFF' : '#191919')};
  :hover {
    opacity: 1;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export default GuideFilter;

import { legacy_createStore as createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export interface IGlobalState {
  passportNumber: null | string;
  refundAfter: boolean;
  printData: null | Array<any>;
  departureDate: null | string;
  nationality: string;
  isUploadRequired: boolean;
  isRefundInfoRegistered: boolean;
  isReceiptImageUploaded: boolean;
  isCustomerLeft: boolean;
  haveSeenReceipt: boolean;
  showCatUi: boolean;
  existDirectRefund: boolean;
  email: string;
  paymentStatus: 'PAYMENT_WAIT' | 'PAYMENT_COMPLETE';
  approvedCount: number;
  unapprovedCount: number;
  refundAmount: string;
  refundAfterAmount: string;
  verifiedEmail: string | null;
  globalModalInfo: {
    type:
      | 'UPLOAD_IMAGE'
      | 'LOCATION'
      | 'PREVIEW_IMAGE'
      | 'PREVIEW_RECEIPT'
      | 'CALENDAR'
      | 'CUSTOM'
      | null;
    isActive: boolean;
    data: any;
  };
  refundCondition: 'REFUND_ONLY' | 'REFUND_AFTER_ONLY' | 'BOTH';
  hasOverPrice: boolean;
}
export const initialGlobalState = {
  passportNumber: null,
  email: null,
  refundAfter: true,
  printData: null,
  departureDate: null,
  nationality: null,
  isUploadRequired: false,
  isRefundInfoRegistered: false,
  isReceiptImageUploaded: false,
  isCustomerLeft: false,
  haveSeenReceipt: false,
  showCatUi: false,
  existDirectRefund: false,
  paymentStatus: null,
  approvedCount: 0,
  unapprovedCount: 0,
  refundAmount: 0,
  refundAfterAmount: 0,
  verifiedEmail: null,
  homeModalData: {
    isActive: false,
    data: null,
    type: null,
  },
  hasOverPrice: false,
};
const persistConfig = {
  key: 'root',
  storage: storageSession,
};
function reducer(state = initialGlobalState, action: any) {
  switch (action.type) {
    case 'UPDATE_USER_INFO':
      return { ...state, ...action.payload };
    case 'UPDATE_HOME_MODAL_STATE':
      return {
        ...state,
        globalModalInfo: action.payload,
      };
    default:
      return state;
  }
}
const perReducer = persistReducer(persistConfig, reducer);
export const store = createStore(perReducer);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

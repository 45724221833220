import CryptoJS from 'crypto-js';

export const tanslateChFromToEnAddress = async (
  province: string = '',
  city: string = '',
  receipient: string = ''
) => {
  const text = province + '/' + city + '/' + receipient;

  const api_url = 'https://openapi.youdao.com/api';
  const appKey = '26bb1dd00b17f47f';
  const key = 'scret';
  const salt = new Date().getTime();
  const curtime = Math.round(new Date().getTime() / 1000);
  const query = text;
  const from = 'zh-CHS';
  const to = 'en';
  const str1 = appKey + truncate(query) + salt + curtime + key;
  const vocabId = 'ZRgQwM9diTP1uwABeYIYC5TEoifpsken';
  const sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);

  const payload = {
    q: query,
    appKey: appKey,
    salt: salt.toString(),
    from: from,
    to: to,
    sign: sign,
    signType: 'v3',
    curtime: curtime.toString(),
  };
  try {
    const response = await fetch(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(payload),
    });

    const data = await response.json();
    console.log('data', data);
  } catch (error) {
    console.error('Error:', error);
  }

  return;
};

function truncate(q: string) {
  var len = q.length;
  if (len <= 20) return q;
  return q.substring(0, 10) + len + q.substring(len - 10, len);
}

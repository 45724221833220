import React from 'react';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import FlexWrap from 'components/_common/FlexWrap';
import UserInputForm from 'components/RefundInfoLeaflet/UserInputForm';
import cautionIcon from 'assets/common/caution.png';
import Icon from 'components/_common/Icon';
import Typography from 'components/_common/Typography';

type LocationState = {
  passportNumber?: string;
};

function RefundInfoInput() {
  const location = useLocation<LocationState>();
  const passportNumber = location?.state?.passportNumber;

  const formMethod = useForm({
    defaultValues: {
      name: '',
      nation: '',
      email: '',
    },
  });

  return (
    <ReceiptLayout>
      <Container dir='column' gap='20px'>
        <Title>Refund information</Title>
        <Content>
          If you cannot verify your passport number, please
          <br />
          enter your refund information. The refund process
          <br />
          will be informed by the email within 1-2 days.
        </Content>
        <DivdeLine />
        <Caution>
          <Icon
            imgUrl={cautionIcon}
            width='24px'
            height='24px'
            margin='0 9px 0 0'
          />
          <Typography
            size={isMobile ? '12px' : '14px'}
            lineHeight='24px'
            color='#FFFFFF'
            fontWeight='400'
          >
            Please enter the correct information to
            <br />
            receive your refund amount via transfer.
          </Typography>
        </Caution>
        <FormProvider {...formMethod}>
          <UserInputForm passportNumber={passportNumber} />
        </FormProvider>
      </Container>
    </ReceiptLayout>
  );
}

const Container = styled(FlexWrap)`
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
`;
const Title = styled.p`
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 28px;
`;
const Content = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
const Caution = styled(FlexWrap)`
  padding: 8px;
  border-radius: 8px;
  background-color: #ff2d55;
`;
const DivdeLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4px;
  background-color: #cbccce;
`;
export default RefundInfoInput;

import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';
import { getRefundInfo, registerRefundInfo } from 'api';

import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import CheckboxWithLabel from 'components/_common/CheckboxWithLabel';
import RefundInfoForm from 'components/Receipt/RefundInfoForm';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IRefundInfoForm, IRegisterRefundInfoResponse } from 'model/types';
import { IGlobalState } from 'store';
import BankInfoForm from 'components/Receipt/BankInfoForm';
import ProgressBar from 'components/_common/ProgressBar';
import EmailVerification from 'components/CustomerRefund/EmailVerification';
import { getIsFrequentCountry } from 'utils/common';
import LoadingView from 'components/_common/LoadingView';
import useCheckEmailAuth from 'hooks/useCheckEmailAuth';
import { tanslateChFromToEnAddress } from 'utils/translate';

function CustomerRefund() {
  const { passportNumber, nationality, verifiedEmail, email } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const originEmailRef = useRef(email);
  const [isFirstTermSelected, setIsFirstTermSelected] = useState(true);
  const [isSecondTermSelected, setIsSecondTermSelected] = useState(true);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTransfer, setSelectedTransfer] = useState('');
  const [selectedAccountInfo, setSelectedAccountInfo] = useState('');
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedTown, setSelectedTown] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [isBankCardInquiry, setIsBankCardInquiry] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isHkgNation, setIsHkgNation] = useState(false);
  const [isVerified, setIsVerified] = useState(verifiedEmail === email);

  const { mutateAsync: checkEmailAuthMutate } = useCheckEmailAuth(
    email,
    nationality
  );

  const isFrequentCountry = getIsFrequentCountry(nationality);
  const [currentStep, setCurrentStep] = useState(1); //현재 단계
  const targetStep = isFrequentCountry ? 2 : 1; //마지막 단계
  const history = useHistory();
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IRefundInfoForm>();
  const { firstName, bank, lastName, phoneNumber } = watch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (nationality === 'CHN' || nationality === 'HKG') {
      dispatch({
        type: 'UPDATE_USER_INFO',
        payload: {
          nationality: isHkgNation ? 'HKG' : 'CHN',
        },
      });
    }
    reset();
  }, [isHkgNation]);

  //현재 단계, 환급 빈도수 상위 18개국 여부에 따라 버튼 활성화
  useEffect(() => {
    const isActive = Boolean(
      currentStep === targetStep
        ? firstName &&
            lastName &&
            phoneNumber &&
            isFirstTermSelected &&
            isSecondTermSelected
        : true
    );
    setIsBtnActive(isActive);
  }, [
    email,
    currentStep,
    targetStep,
    firstName,
    lastName,
    phoneNumber,
    isFirstTermSelected,
    isSecondTermSelected,
  ]);

  const { refetch, isFetching } = useQuery<any, string>(
    'RefundInfo',
    () => getRefundInfo(passportNumber),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        if (!data) {
          return;
        }
        const { accountInfo } = data;
        setValue('firstName', accountInfo.firstName);
        setValue('lastName', accountInfo.lastName);
        setValue('phoneNumber', accountInfo.phoneNumber);
        setValue('transferMethod', accountInfo.transferMethod);
        setValue('aliPayAccount', accountInfo.aliPayAccount);
        setValue('cardNumber', accountInfo.cardNumber);
        setValue('bank', accountInfo.bank);
        setValue('bankAccount', accountInfo.bankAccount);
        setValue('accountInfo', accountInfo.accountInfo);
        setValue('routingNumber', accountInfo.routingNumber);
        setValue('stateProvinceRegion', accountInfo.stateProvinceRegion);
        setValue('zipPostalCode', accountInfo.zipPostalCode);
        setValue('address', accountInfo.address);
        setValue('branchInformation', accountInfo.branchInformation);
        setValue('bsbNumber', accountInfo.bsbNumber);
        setValue('transitNumber', accountInfo.transitNumber);
        setValue('cashPickup', accountInfo.cashPickup);
        setValue('ibanNumber', accountInfo.ibanNumber);
        setValue('swiftCode', accountInfo.swiftCode);
        setValue('birth', accountInfo.birth);
        setValue('addressDetail', accountInfo.addressDetail);
        setValue('addressDetail2', accountInfo.addressDetail2);
        setValue('accountType', accountInfo.accountType);
        setValue('institutionNumber', accountInfo.institutionNumber);
        setValue('gender', accountInfo.gender);
        setValue('citizenId', accountInfo.citizenId);
        setValue('country', accountInfo.country);
        setValue('firstNameEng', accountInfo.firstNameEng);
        setValue('lastNameEng', accountInfo.lastNameEng);
        setValue('directInput', accountInfo.directInput);
        setValue('accountHolder', accountInfo.accountHolder);
        setSelectedTransfer(accountInfo.transferMethod);
        setSelectedAccountInfo(accountInfo.accountInfo);
        setSelectedAccountType(accountInfo.accountType);
        setSelectedBank(accountInfo.bank);
        setSelectedGender(accountInfo.gender);
        setIsBankCardInquiry(accountInfo.isBankCardInquiry || false);
        setIsHkgNation(accountInfo.isHkgNation || false);
        await setSelectedRegion(accountInfo.stateProvinceRegion);
        setValue('city', accountInfo.city);
        setSelectedTown(accountInfo.city);
      },
    }
  );

  const { mutate } = useMutation<IRegisterRefundInfoResponse, AxiosError, any>(
    (payload) => registerRefundInfo(payload),
    {
      retry: false,
      onSuccess: ({ isForTemporaryStorage }) => {
        if (isForTemporaryStorage) return;
        if (isFrequentCountry || nationality === 'KOR') {
          if (currentStep !== targetStep) {
            setCurrentStep(currentStep + 1);
            window.scrollTo({ behavior: 'smooth', top: 0 });
          } else {
            history.push(routes.refundResult, {
              isEmailChanged: originEmailRef.current !== email,
            });
          }
        } else {
          dispatch({
            type: 'UPDATE_HOME_MODAL_STATE',
            payload: {
              type: 'CUSTOM',
              isActive: true,
              data: {
                title: 'Information has been Entered',
                content: `We will send an email within 10 days\nto request the information needed for\nprocessing refunds.\nPlease check your mailbox`,
                btnText: 'OK',
                btnCallback: () => {
                  history.push(routes.refundResult, {
                    isEmailChanged: originEmailRef.current !== email,
                  });
                },
              },
            },
          });
        }
      },
    }
  );

  const onSubmit = () => {
    if (
      ((isFrequentCountry && currentStep === 2) || !isFrequentCountry) &&
      nationality === 'CHN'
    ) {
      dispatch({
        type: 'UPDATE_HOME_MODAL_STATE',
        payload: {
          isActive: true,
          type: 'CUSTOM',
          data: {
            title: '您输入的名字对吗?',
            content: `${getValues('firstName')}${getValues(
              'lastName'
            )}\n${getValues('firstNameEng')}${getValues('lastNameEng')}`,
            subBtnText: '重新输入',
            btnText: '确认',
            btnCallback: () =>
              mutate({
                passportNumber,
                register: currentStep === targetStep,
                accountInfo: {
                  ...getValues(),
                  gender: selectedGender,
                  accountType: selectedAccountType,
                  accountInfo: selectedAccountInfo,
                  isBankCardInquiry,
                  isHkgNation,
                },
              }),
          },
        },
      });
      return;
    }

    console.log('accuontInfo', getValues());

    if (nationality === 'CHN') {
      const { stateProvinceRegion, city, address } = getValues();
      tanslateChFromToEnAddress(stateProvinceRegion, city, address);
    }

    // mutate({
    //   passportNumber,
    //   register: currentStep === targetStep,
    //   accountInfo: {
    //     ...getValues(),
    //     gender: selectedGender,
    //     accountType: selectedAccountType,
    //     accountInfo: selectedAccountInfo,
    //     isBankCardInquiry,
    //     isHkgNation,
    //   },
    // });
  };

  const emailCheckAndSubmitForm = () => {
    checkEmailAuthMutate({ email, passportNumber }).then((res) => {
      if (res) {
        setIsVerified(true);
        setIsBtnActive(true);
        handleSubmit(onSubmit)();
      }
    });
  };

  useEffect(() => {
    setValue('aliPayAccount', '');
    setValue('cardNumber', '');
    setValue('bank', '');
    setValue('bankAccount', '');
    setValue('accountInfo', '');
    setValue('routingNumber', '');
    setValue('city', '');
    setValue('stateProvinceRegion', '');
    setValue('zipPostalCode', '');
    setValue('address', '');
    setValue('branchInformation', '');
    setValue('bsbNumber', '');
    setValue('transitNumber', '');
    setValue('cashPickup', '');
    setValue('ibanNumber', '');
    setValue('swiftCode', '');
    setValue('birth', '');
    setValue('addressDetail', '');
    setValue('addressDetail2', '');
    setValue('accountType', '');
    setValue('institutionNumber', '');
    setValue('gender', '');
    setValue('citizenId', '');
    setValue('country', '');
    setValue('firstNameEng', '');
    setValue('lastNameEng', '');
    setValue('accountHolder', '');
    setIsBankCardInquiry(false);
    setSelectedBank('');
    setSelectedRegion('');
    setSelectedTown('');
    setSelectedGender('');
  }, [selectedTransfer, isHkgNation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch();
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ContainerWrapper className='notranslate'>
      <ReceiptLayout hasPadding={false}>
        <ProgressBar
          percent={targetStep === 2 ? (currentStep === 1 ? 33 : 66) : 50}
        />
        <Title>
          {nationality === 'KOR'
            ? `${passportNumber}님의\n정보를 작성해주세요`
            : `${passportNumber}!\nPlease enter your information.`}
        </Title>
        <Wrapper>
          {currentStep === 1 && (
            <EmailVerification
              getValues={getValues}
              isBankCardInquiry={isBankCardInquiry}
              setIsBtnActive={setIsBtnActive}
              isHkgNation={isHkgNation}
              isVerified={isVerified}
              setIsVerified={setIsVerified}
            />
          )}
          {isFrequentCountry &&
            currentStep === 1 &&
            (isFetching ? (
              <LoadingView />
            ) : (
              <BankInfoForm
                errors={errors}
                register={register}
                getValues={getValues}
                selectedAccountInfo={selectedAccountInfo}
                setSelectedAccountInfo={setSelectedAccountInfo}
                selectedBank={selectedBank}
                setSelectedBank={setSelectedBank}
                setValue={setValue}
                selectedAccountType={selectedAccountType}
                setSelectedAccountType={setSelectedAccountType}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                isBankCardInquiry={isBankCardInquiry}
                setIsBankCardInquiry={setIsBankCardInquiry}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedTown={selectedTown}
                setSelectedTown={setSelectedTown}
                isHkgNation={isHkgNation}
                setIsHkgNation={setIsHkgNation}
              />
            ))}
          {((isFrequentCountry && currentStep === 2) || !isFrequentCountry) && (
            <RefundInfoForm
              register={register}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              bankName={bank}
            />
          )}
          {currentStep === targetStep && (
            <>
              <CheckboxWithLabel
                isTopLine
                isActive={isFirstTermSelected}
                setIsActive={setIsFirstTermSelected}
                label={
                  nationality === 'KOR'
                    ? '개인정보 수집 및 이용안내에 동의합니다.'
                    : 'I will consent to '
                }
                highlightLabel={
                  nationality === 'KOR'
                    ? ''
                    : 'disclosure of personal information'
                }
              />
              <CheckboxWithLabel
                isActive={isSecondTermSelected}
                setIsActive={setIsSecondTermSelected}
                label={
                  nationality === 'KOR'
                    ? '고유식별정보 수집에 동의합니다.'
                    : 'I agree to '
                }
                highlightLabel={
                  nationality === 'KOR'
                    ? ''
                    : 'collection and use of personal information'
                }
              />
            </>
          )}

          <TextButton
            isActive={
              isBtnActive && (nationality === 'CHN' ? isBankCardInquiry : true)
            }
            onClick={
              isVerified ? handleSubmit(onSubmit) : emailCheckAndSubmitForm
            }
            disabled={
              !isBtnActive || (nationality === 'CHN' && !isBankCardInquiry)
            }
            title={
              currentStep === targetStep
                ? nationality === 'KOR'
                  ? '완료'
                  : 'CHECK'
                : 'NEXT'
            }
            margin='20px 0'
          />
        </Wrapper>
      </ReceiptLayout>
    </ContainerWrapper>
  );
}
const ContainerWrapper = styled.div``;
const Title = styled.h2`
  font-size: 21px;
  color: #1856cd;
  padding-left: 20px;
  margin: 30px 0 20px;
  white-space: pre-wrap;
  line-height: 150%;
  text-align: left;
  font-weight: 500;
  width: 100%;
`;
const Wrapper = styled.div`
  width: 335px;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding: 0 20px;
  }
`;
export default CustomerRefund;

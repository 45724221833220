import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useState,
} from 'react';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';

import { IErrorState } from 'pages/ktp/PassportValidate';
import Input from 'components/_common/Input';
import TextButton from 'components/_common/TextButton';
import { useAppSelector } from 'hooks/useReduxHooks';
import { format } from 'date-fns';
import CalendarModal from './CalendarModal';
import CalendarImg from 'assets/uploadImage/calendar.png';
import { IGlobalState } from 'store';

interface IProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  setDepartureDate: Dispatch<SetStateAction<any>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  error?: IErrorState;
  departureDate?: any;
}
function DepartureInfoForm({
  onSubmit,
  setDepartureDate,
  email,
  setEmail,
  error,
  departureDate,
}: IProps) {
  const { passportNumber, nationality, showCatUi } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const [isActveCalendar, setIsActiveCalendar] = useState(false);

  const onClick = () => {
    setIsActiveCalendar(!isActveCalendar);
  };
  return (
    <>
      {nationality === 'KOR' ? (
        <Title>
          <Colored>{passportNumber}</Colored>님 환영합니다!
          <br />
          <Bold>출국일과 이메일을 입력해주세요.</Bold>
        </Title>
      ) : (
        <Title>
          Welcome! <Colored>{passportNumber}</Colored>
          <br />
          <Bold>Please enter the departure date & email.</Bold>
        </Title>
      )}
      <Form onSubmit={onSubmit}>
        <InputWrapper onClick={onClick}>
          <Input
            value={departureDate && format(new Date(departureDate), 'yy-MM-dd')}
            label={nationality === 'KOR' ? '출국일' : 'Departure Date'}
            isRequired
            placeholder='YY-MM-DD'
            width='335px'
            style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}
            readOnly
          />
          <CalendarIcon src={CalendarImg} />
        </InputWrapper>
        {!showCatUi && (
          <Input
            value={email}
            label={nationality === 'KOR' ? '이메일' : 'E-Mail'}
            isRequired
            placeholder='E-mail'
            type='email'
            error={error}
            width='335px'
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <TextButton
          isActive={Boolean(departureDate && (showCatUi ? true : email))}
          title={nationality === 'KOR' ? '다음' : 'CHECK'}
          bottomFixed
          onClick={onSubmit}
        />
      </Form>
      {isActveCalendar && (
        <CalendarModal
          onChange={setDepartureDate}
          departureDate={departureDate}
          onClose={onClick}
        />
      )}
    </>
  );
}
const Form = styled.form`
  position: relative;
`;
const Title = styled.p`
  text-align: start;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #cbccce;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  display: inline-block;
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 30px;
    margin-top: 0;
  }
`;
const Colored = styled.span`
  color: #246cf6;
`;
const Bold = styled.strong`
  font-weight: 500;
`;
const InputWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
`;
const CalendarIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: 55px;
  cursor: pointer;
`;
export default DepartureInfoForm;

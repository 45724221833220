import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Refund from 'assets/home/refund.png';
import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';
import { routes } from 'routes';
import { IGlobalState } from 'store';
import { comma } from 'utils/format';

function RefundAmount() {
  const {
    refundAfterAmount,
    refundAmount,
    refundAfter,
    isUploadRequired,
    nationality,
  } = useAppSelector((state) => state) as IGlobalState;
  const history = useHistory();
  const [amount, setAmount] = useState(
    refundAfter ? refundAfterAmount : refundAmount
  );

  const onClick = () => {
    if (!refundAfter) return;
    if (isUploadRequired) {
      history.push(routes.receiptInquiry);
    } else {
      window.scrollTo({ top: 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setAmount(refundAfter ? refundAfterAmount : refundAmount);
  }, [refundAfter, refundAfterAmount, refundAmount]);

  return (
    <Container active={refundAfter} onClick={onClick}>
      <RefundIcon src={Refund} />
      <FlexWrap dir='column' alignItems='flex-start'>
        <FlexWrap gap='4px'>
          <RefundLabel>
            {nationality === 'KOR'
              ? refundAfter
                ? '내가 환급 받을 금액'
                : '내가 환급 받은 금액'
              : 'My refunds'}
          </RefundLabel>
        </FlexWrap>
        <FlexWrap alignItems='center' gap='8px'>
          <Currency>KRW</Currency>
          <Amount className='notranslate'>{comma(amount)}</Amount>
        </FlexWrap>
      </FlexWrap>
    </Container>
  );
}

const Container = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? '#5089f8' : '#5F6165')};
  padding: 16px;
  border-radius: 10px;
  gap: 20px;
  color: #fff;
`;
const RefundIcon = styled.img`
  width: 68px;
  height: 68px;
`;
const CheckedIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const WhiteText = styled.span`
  color: #fff;
  line-height: 150%;
`;
const RefundLabel = styled(WhiteText)`
  font-size: 14px;
`;
const BoldText = styled(WhiteText)`
  font-weight: 700;
`;
const Currency = styled(BoldText)`
  font-size: 18px;
`;
const Amount = styled(BoldText)`
  font-size: 24px;
`;
export default RefundAmount;

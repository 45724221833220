import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import BtnWithIcon from 'components/Receipt/BtnWithIcon';
import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import CustomsLocation from 'components/Home/CustomsLocation';
import PrinterLocation from 'components/Home/PrinterLocation';
import UploadReceiptImage from 'components/Home/UploadReceiptImage';

import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState, initialGlobalState } from 'store';
import ReceiptAccordionContent from './ReceiptAccordionContent';
import { getIsFrequentCountry } from 'utils/common';

type Props = {
  refetchHome: () => void;
  scrollToReceipt?: boolean;
};
function BottomSection({ refetchHome, scrollToReceipt }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    isRefundInfoRegistered,
    nationality,
    haveSeenReceipt,
    isReceiptImageUploaded,
    isUploadRequired,
    unapprovedCount,
  } = useAppSelector((state) => state) as IGlobalState;
  const customerNationToken = localStorage.getItem('customerNation');
  const currNation = isMobile ? customerNationToken : nationality;
  const [isReceiptToggleActive, setIsReceiptToggleActive] = useState(false);
  const [isUploadToggleActive, setIsUploadToggleActive] = useState(false);

  const onClickRefundInfo = () => {
    if (isRefundInfoRegistered) {
      history.push(routes.refundInfo);
    } else {
      history.push(routes.customerRefund);
    }
  };

  const onClickReceiptInquiry = () => {
    dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: true } });
    history.push(routes.receiptInquiry);
  };

  const onOpenLogoutModal = () => {
    dispatch({
      type: 'UPDATE_HOME_MODAL_STATE',
      payload: {
        isActive: true,
        type: 'CUSTOM',
        data: {
          title: nationality === 'KOR' ? '로그아웃 할까요?' : 'Sign Out',
          content:
            nationality === 'KOR'
              ? '다른 여권번호로 조회하려면 로그아웃 하세요.'
              : `Sign out to look up\nanother passport number.`,
          btnText: nationality === 'KOR' ? '로그아웃' : 'Sign Out',
          subBtnText: nationality === 'KOR' ? '취소' : 'Cancel',
          btnCallback: logout,
        },
      },
    });
  };

  const logout = () => {
    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: initialGlobalState,
    });
    localStorage.removeItem('login');
    localStorage.removeItem('departureDate');
    localStorage.removeItem('customerNation');
    history.push(routes.passport);
  };
  const goToRefundProcedure = () => {
    history.push(routes.refundProcedure);
  };
  const goToQnA = () => {
    history.push(routes.qna);
  };

  useEffect(() => {
    if (!scrollToReceipt) {
      return;
    }
    window.scrollTo({ top: 700, behavior: 'smooth' });
    setTimeout(() => {
      setIsReceiptToggleActive(true);
    }, 700);
  }, []);

  return (
    <Container>
      <MainContent>
        <Title>
          {nationality === 'KOR' ? '환급절차' : 'Tax Refund Process'}
        </Title>
        <RefundProcedure dir='column'>
          <BtnWithIcon
            title={
              currNation === 'KOR'
                ? `정보 입력 (3개월 이내)`
                : 'Enter information within 3 months'
            }
            description={
              currNation === 'KOR'
                ? isUploadRequired && isRefundInfoRegistered
                  ? '환급액은 고객님 계좌로 지급됩니다.\n송금에 필요한 정보를 3개월 이내 작성해주세요.'
                  : `${
                      isRefundInfoRegistered
                        ? '세관 확인 후 환급액 지급까지 영업일 기준 14일 정도\n소요됩니다. '
                        : '환급액은 고객님 계좌로 지급됩니다.\n송금에 필요한 정보를 3개월 이내 작성해주세요.'
                    }`
                : getIsFrequentCountry(nationality)
                ? "We'll send your refund to your account.\nComplete your account information."
                : `We will send an email within 10 days to request the information needed for processing refunds. Please check your mailbox`
            }
            isCompleted={isRefundInfoRegistered}
            onClick={onClickRefundInfo}
            margin='0 0 28px'
          />
          <BtnWithIcon
            title={
              currNation === 'KOR'
                ? isUploadRequired
                  ? '나의 Tax Refund 영수증 출력'
                  : '세관 방문'
                : 'Visit customs'
            }
            description={
              isUploadRequired
                ? isReceiptImageUploaded
                  ? '' //재외국민) 이미지 업로드 완료
                  : 'Tax Refund 영수증을 출력하세요.' //재외국민) 이미지 업로드 미완료
                : unapprovedCount === 0
                ? '' //외국인) 모두 승인된 경우
                : nationality === 'KOR' //외국인) 일부 미승인건 존재하는 경우
                ? `세관에 구매한 물건과 모바일 Tax Refund 영수증을 보여주세요.`
                : `Show your purchases and KTP Mobile receipt\nto customs.`
            }
            isCompleted={haveSeenReceipt}
            onClick={onClickReceiptInquiry}
            margin='0 0 28px'
            isToggle={!isUploadRequired}
            subContentComponent={
              isUploadRequired ? undefined : (
                <ReceiptAccordionContent
                  style={{
                    paddingTop: 20,
                    marginTop: 18,
                    borderTop: '1px solid #CBCCCE',
                  }}
                />
              )
            }
            isToggleActive={isReceiptToggleActive}
            setIsToggleActive={setIsReceiptToggleActive}
          />
          {isUploadRequired && (
            <BtnWithIcon
              title='도장 받은 Tax Refund 영수증 사진 올리기'
              description={
                isReceiptImageUploaded
                  ? ''
                  : 'Tax Refund 영수증에 세관 확인 도장을 받은 후 사진을 올려주세요.'
              }
              isCompleted={isReceiptImageUploaded}
              margin='0 0 28px'
              isToggle
              subContentComponent={
                <UploadReceiptImage refetchHome={refetchHome} />
              }
              isToggleActive={isUploadToggleActive}
              setIsToggleActive={setIsUploadToggleActive}
            />
          )}
        </RefundProcedure>
        {Boolean(
          isUploadRequired ||
            Boolean(isRefundInfoRegistered && unapprovedCount !== 0)
        ) && <CustomsLocation />}
        {isUploadRequired && <PrinterLocation />}
      </MainContent>
      <BtnContainer>
        <TextButton
          title={nationality === 'KOR' ? '환급절차 안내' : 'Tax Refund Process'}
          onClick={goToRefundProcedure}
          color='#1856CD'
          bgColor='#fff'
          outline
          margin='0 0 12px'
        />
        <TextButton
          title={nationality === 'KOR' ? 'KTP 문의하기' : 'KTP Customer Center'}
          onClick={goToQnA}
          color='#1856CD'
          bgColor='#fff'
          outline
          margin='0 0 12px'
        />
        <TextButton
          title={nationality === 'KOR' ? '로그아웃' : 'Log out'}
          onClick={onOpenLogoutModal}
          color='#1856CD'
          bgColor='#fff'
          outline
        />
      </BtnContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
`;
const MainContent = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  -webkit-box-shadow: 0px 20px 20px -20px rgba(174, 191, 204, 0.5);
  box-shadow: 0px -12px 16px -2px rgba(174, 191, 204, 0.5);
  padding: 36px 20px 40px;
  margin-top: 20px;
  border-bottom: 8px solid #f5f6f7;
`;
const Title = styled.h3`
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  color: #3a3b3e;
`;
const RefundProcedure = styled(FlexWrap)`
  width: 100%;
  margin-top: 20px;
`;
const BtnContainer = styled.div`
  padding: 20px;
  background-color: #fff;
`;
export default BottomSection;

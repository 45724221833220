import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import { useAppSelector } from 'hooks/useReduxHooks';
import ArrowDown from 'assets/common/arrow_down.png';
import Input from 'components/_common/Input';
import { IRefundInfoForm } from 'model/types';
import {
  chnBankabbreviationList,
  chnBankList,
  chnBankTraditionList,
  hkgBankList,
  hkgInfoList,
  jpnBankList,
  sgpSwiftCodeList,
} from 'constant/accountInfo';
import { bankCardOwnershipInquiry } from 'utils/apiCaller';
import { Spin } from 'animation';
import { attachedHyphensPostCode } from 'utils/common';
import Icon from 'components/_common/Icon';
import ErrorAlert from 'assets/common/alert.png';

type BankInfoFormProps = {
  errors?: FieldErrors;
  register: UseFormRegister<IRefundInfoForm>;
  getValues: UseFormGetValues<IRefundInfoForm>;
  selectedAccountInfo: string;
  setSelectedAccountInfo: Dispatch<SetStateAction<string>>;
  selectedBank: string;
  setSelectedBank: Dispatch<SetStateAction<string>>;
  selectedAccountType: string;
  setSelectedAccountType: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  isEdit?: boolean;
  setValue: UseFormSetValue<IRefundInfoForm>;
  selectedGender: string;
  setSelectedGender: Dispatch<SetStateAction<string>>;
  isBankCardInquiry: boolean;
  setIsBankCardInquiry: Dispatch<SetStateAction<boolean>>;
  isRefundInfoPage?: boolean;
  selectedRegion: string;
  setSelectedRegion: Dispatch<SetStateAction<string>>;
  selectedTown: string;
  setSelectedTown: Dispatch<SetStateAction<string>>;
  isHkgNation: boolean;
  setIsHkgNation?: Dispatch<SetStateAction<boolean>>;
};

function BankInfoForm({
  errors,
  register,
  getValues,
  selectedAccountInfo,
  setSelectedAccountInfo,
  selectedBank,
  setSelectedBank,
  selectedAccountType,
  setSelectedAccountType,
  disabled,
  isEdit,
  setValue,
  selectedGender,
  setSelectedGender,
  isBankCardInquiry,
  setIsBankCardInquiry,
  isRefundInfoPage,
  selectedRegion,
  setSelectedRegion,
  selectedTown,
  setSelectedTown,
  isHkgNation,
  setIsHkgNation,
}: BankInfoFormProps) {
  const { nationality } = useAppSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropList, setIsDropList] = useState(false);
  const [keywordList, setKeyWordList] = useState<string[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  let dropDownRef = useRef<any>(null);

  let isIntital = useRef(false);

  useEffect(() => {
    if (selectedTown !== 'Direct Input') {
      setValue('directInput', '');
    }
  }, [selectedTown]);

  useEffect(() => {
    if (isHkgNation && isIntital.current) {
      setValue('city', '');
      setValue('directInput', '');
      setSelectedTown('');
    }
  }, [selectedRegion]);

  const sanitizeInput = (value: string) => {
    return value.replace(/[^a-zA-Z0-9, ]/g, '');
  };

  const onClickBankCardOwnership = async () => {
    setIsLoading(true);
    const cardInfo: any = await bankCardOwnershipInquiry(
      getValues('bankAccount') || ''
    );
    setIsLoading(false);

    if (cardInfo?.codeid !== '10000') {
      alert('银行验证失败，请重新输入。');
      return;
    }
    if (cardInfo?.retdata?.cardtype !== '借记卡') {
      alert('输入您的借记卡号。不接受信用卡。');
      return;
    }

    // 중국은행 간체
    const bankSimple = chnBankList.filter((item) =>
      item.includes(cardInfo.retdata.bankname)
    );
    // 중국은행 번체
    const bankTradition = chnBankTraditionList.filter((item) =>
      item.includes(cardInfo.retdata.bankname)
    );
    // 중국은행 약어
    const bankAbbreviation = chnBankabbreviationList.filter(
      (item) => item === cardInfo.retdata.abbreviation
    );
    const bank = [...bankSimple, ...bankTradition, ...bankAbbreviation];
    if (bank.length === 0) {
      alert(
        '银行无法汇款。\n\n可以汇款的银行列表\n1. 中国銀行\n2. 中国工商银行\n3. 华夏银行\n4. 中国农业银行\n5. 中国建设银行\n6. 交通银行\n7. 光大银行\n8. 上海银行\n9. 临商银行\n\n如果您在输入信息时遇到困难,\n请通过以下电子邮件联系我们。\n客户服务 ktaxpay@gmail.com'
      );
      return;
    }
    // 약어만 같을경우, chnBankabbreviationList와 bank값을 매칭 해서 "bank" 할당
    if (bankAbbreviation.length > 0 && bank.length === 1) {
      const index = chnBankabbreviationList.indexOf(bank[0]);
      setValue('bank', chnBankList[index]);
    } else {
      setValue('bank', bank[0]);
    }
    setIsBankCardInquiry(true);
  };

  // 일본 은행 검색 기능
  const bankObject: { [key: string]: string } = jpnBankList.reduce(
    (acc, bank) => {
      acc[bank.toUpperCase()] = bank;
      return acc;
    },
    {} as { [key: string]: string }
  );

  const onChangeAndSearchKeyword = (e: any) => {
    const value = e.target.value;
    setValue('bank', sanitizeInput(value));

    if (value === '') {
      setSelectedItemIndex(-1);
      setIsDropList(false);
    }

    const newBankList = Object.keys(bankObject)
      .filter((key) => {
        const sanitizedKey = key.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        return (
          sanitizedKey.includes(
            value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
          ) && sanitizedKey !== ''
        );
      })
      .map((key) => bankObject[key]);

    if (newBankList.length > 0 && value) {
      setIsDropList(true);
    } else {
      setIsDropList(false);
    }
    setKeyWordList(newBankList);
  };

  // 드롭박스 스크롤 관련코드
  useEffect(() => {
    if (isDropList && dropDownRef.current && selectedItemIndex !== -1) {
      const item = dropDownRef.current.children[selectedItemIndex];
      if (item) {
        item.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isDropList, selectedItemIndex]);

  const remittanceMethodInfo = () => {
    switch (nationality) {
      case 'CHN':
        return (
          <>
            <LabelWrapper>
              <Label>账号</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            {isBankCardInquiry && (
              <Input
                placeholder='bank'
                {...register('bank')}
                error={errors?.bank}
                disabled={disabled || isBankCardInquiry}
                margin={[0, 0, 8, 0]}
              />
            )}
            <InputWrapper>
              <Input
                placeholder='Bank account'
                {...register('bankAccount', {
                  pattern: {
                    value: /^\d+$/,
                    message: '请仅填写号码。',
                  },
                })}
                type='number'
                error={errors?.bankAccount}
                disabled={disabled || isBankCardInquiry}
                style={{ padding: '14px 13px' }}
              />
              {(isBankCardInquiry || isRefundInfoPage) && (
                <AuthText>已认证</AuthText>
              )}
            </InputWrapper>
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 20px 0'
            >
              * 您只能向自己的账户汇款。
            </Typography>
            {!isRefundInfoPage &&
              !isBankCardInquiry &&
              (isLoading ? (
                <Button isDisabled>
                  <LoadingSpinner />
                </Button>
              ) : (
                <Button
                  isDisabled={disabled || !getValues('bankAccount')}
                  disabled={disabled}
                  onClick={onClickBankCardOwnership}
                >
                  验证银行账户
                </Button>
              ))}
            {!isRefundInfoPage && (
              <Typography
                size='13px'
                lineHeight='19.5px'
                fontWeight='400'
                color='#246CF6'
                margin='0 0 20px 0'
                cursor='pointer'
                onClick={() => setIsHkgNation && setIsHkgNation((v) => !v)}
              >
                Receive money to a Hong Kong account &gt;
              </Typography>
            )}
            <FlexWrap dir='column'>
              <LabelWrapper>
                <Label>性别</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <FlexWrap
                dir='column'
                justifyContent='center'
                gap='16px'
                margin='0 0 20px 0'
              >
                <RadioLabel>
                  <input
                    {...register('gender', {
                      required: '这是必填项。',
                    })}
                    type='radio'
                    value='FEMALE'
                    onClick={() => setSelectedGender('FEMALE')}
                    checked={selectedGender === 'FEMALE'}
                    disabled={disabled}
                  />
                  Female 女性
                </RadioLabel>
                <RadioLabel>
                  <input
                    {...register('gender', {
                      required: '这是必填项。',
                    })}
                    type='radio'
                    value='MALE'
                    onClick={() => setSelectedGender('MALE')}
                    checked={selectedGender === 'MALE'}
                    disabled={disabled}
                  />
                  Male 男性
                </RadioLabel>
              </FlexWrap>
              {errors?.gender && (
                <FlexWrap gap='8px'>
                  <Icon imgUrl={ErrorAlert} width='18px' height='18px' />
                  <Typography color='#f13e4b' size='13px' fontWeight='400'>
                    这是必填项。
                  </Typography>
                </FlexWrap>
              )}
            </FlexWrap>
            <Input
              {...register('citizenId', {
                required: '这是必填项。',
                pattern: {
                  value: /(\d){17}(\d|[Xx])/,
                  message: '无效的公民身份号码。',
                },
              })}
              label='公民身份号码'
              maxLength={18}
              isRequired
              placeholder='Citizen ID Number'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.citizenId}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: '这是必填项。',
                pattern: {
                  value: isHkgNation ? /^\S.+$/ : /^\S[\u4e00-\u9fa50-9\s]*$/,
                  message: '请用中文输入。',
                },
              })}
              label='省'
              isRequired
              placeholder='Province 仅限中文'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: '这是必填项。',
                pattern: {
                  value: isHkgNation ? /^\S.+$/ : /^\S[\u4e00-\u9fa50-9\s]*$/,
                  message: '请用中文输入。',
                },
              })}
              label='市'
              isRequired
              placeholder='City 仅限中文'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: '这是必填项。',
                pattern: {
                  value: /^\S[\u4e00-\u9fa50-9\s,-]*$/,
                  message: '这是必填项。',
                },
              })}
              label='区'
              isRequired
              placeholder='District 仅限中文'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail', {
                pattern: {
                  value: /^\S[\u4e00-\u9fa50-9\s,-]*$/,
                  message: '这是必填项。',
                },
              })}
              label='详细地址'
              isRequired
              placeholder='Recipient address 仅限中文'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('zipPostalCode', {
                required: '这是必填项。',
                pattern: {
                  value: /^[a-zA-Z0-9]{6}$/,
                  message: '请输入 6 个字符（仅限字母和数字）。',
                },
              })}
              label='邮政编码'
              isRequired
              placeholder='Post code'
              maxLength={6}
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'HKG':
        return (
          <>
            <FlexWrap dir='column'>
              <LabelWrapper>
                <Label>Bank account</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <BankWrap isChnBank>
                <ArrowIcon src={ArrowDown} isChnBank />
                <Select
                  {...register('bank', {
                    required: 'This is a required item.',
                  })}
                  isSelected={Boolean(selectedBank)}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  isEdit={isEdit}
                  disabled={disabled}
                >
                  <Option value='' hidden>
                    Name of Bank
                  </Option>
                  {hkgBankList.map((bank, idx) => (
                    <Option key={idx} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </BankWrap>
            </FlexWrap>
            <Input
              placeholder='Bank account'
              {...register('bankAccount', {
                pattern: {
                  value: /^\S/,
                  message: 'This is a required item.',
                },
              })}
              type='number'
              error={errors?.bankAccount}
              disabled={disabled}
            />
            <Typography
              size='14px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * Corporate accounts are not accepted.
            </Typography>

            {!isRefundInfoPage && (
              <Typography
                size='13px'
                lineHeight='19.5px'
                fontWeight='400'
                color='#246CF6'
                margin='20px 0 0 0'
                cursor='pointer'
                onClick={() => setIsHkgNation && setIsHkgNation((v) => !v)}
              >
                向中国账户汇款 &gt;
              </Typography>
            )}

            {/* Region */}
            <FlexWrap dir='column' margin='20px 0 0 0'>
              <LabelWrapper>
                <Label>Region</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <BankWrap isChnBank>
                <ArrowIcon src={ArrowDown} isChnBank />
                <Select
                  {...register('stateProvinceRegion', {
                    required: 'This is a required item.',
                  })}
                  isSelected={Boolean(selectedRegion)}
                  onChange={(e) => {
                    setSelectedRegion(e.target.value);
                    isIntital.current = true;
                  }}
                  isEdit={isEdit}
                  disabled={disabled}
                >
                  <Option value='' hidden>
                    Hongkong / Kowlong / The New Territories
                  </Option>
                  {Object.keys(hkgInfoList)?.map((region, idx) => (
                    <Option key={idx} value={region}>
                      {region}
                    </Option>
                  ))}
                </Select>
              </BankWrap>
            </FlexWrap>
            {/* Town or District */}
            <FlexWrap dir='column' margin='20px 0 0 0'>
              <LabelWrapper>
                <Label>Town or District</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <BankWrap isChnBank>
                <ArrowIcon src={ArrowDown} isChnBank />
                <Select
                  {...register('city', {
                    required: 'This is a required item.',
                  })}
                  isSelected={Boolean(selectedTown)}
                  onChange={(e) => setSelectedTown(e.target.value)}
                  isEdit={isEdit}
                  disabled={disabled}
                >
                  <Option value='' hidden>
                    Town or District
                  </Option>
                  {hkgInfoList[selectedRegion]?.streetList.map(
                    (street, idx) => (
                      <Option key={idx} value={street}>
                        {street}
                      </Option>
                    )
                  )}
                </Select>
              </BankWrap>
            </FlexWrap>
            {selectedTown === 'Direct Input' && (
              <Input
                {...register('directInput', {
                  required: 'This is a required item.',
                  pattern: {
                    value: /^\S[a-zA-Z0-9\s]+$/,
                    message: 'Please enter only English and numbers.',
                  },
                  onChange: (e) =>
                    setValue('directInput', sanitizeInput(e.target.value)),
                })}
                type='text'
                isRequired
                placeholder='Please enter your town or district in English'
                error={errors?.directInput}
                disabled={disabled}
              />
            )}
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              type='text'
              isRequired
              label='Street name'
              placeholder='Name of street'
              error={errors?.address}
              margin={[20, 0, 0]}
              disabled={disabled}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail', sanitizeInput(e.target.value)),
              })}
              type='text'
              isRequired
              label='Building number '
              placeholder='Building number'
              error={errors?.addressDetail}
              margin={[20, 0, 0]}
              disabled={disabled}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              type='text'
              isRequired
              label='Name of Building'
              placeholder='Name of Building'
              error={errors?.addressDetail2}
              margin={[20, 0, 0]}
              disabled={disabled}
            />
          </>
        );
      case 'USA':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * Corporate accounts are not accepted.
            </Typography>
            <Input
              {...register('routingNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
              })}
              type='text'
              label='ACH routing number'
              error={errors?.routingNumber}
              isRequired
              maxLength={9}
              placeholder='ACH routing number (9-digits)'
              margin={[20, 0, 0]}
              disabled={disabled}
            />
            <Input
              {...register('swiftCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='SWIFT Code'
              isRequired
              placeholder='Swift Code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.swiftCode}
            />
            <FlexWrap dir='column' margin='20px 0 0 0'>
              <LabelWrapper>
                <Label>Account Type</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <FlexWrap dir='column' justifyContent='center' gap='16px'>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='SAVING'
                    onClick={() => setSelectedAccountInfo('SAVING')}
                    checked={selectedAccountInfo === 'SAVING'}
                    disabled={disabled}
                  />
                  Saving Account
                </RadioLabel>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='CHECKING'
                    onClick={() => setSelectedAccountInfo('CHECKING')}
                    checked={selectedAccountInfo === 'CHECKING'}
                    disabled={disabled}
                  />
                  Checking Account
                </RadioLabel>
                {errors?.accountInfo && (
                  <FlexWrap gap='8px'>
                    <Icon imgUrl={ErrorAlert} width='18px' height='18px' />
                    <Typography color='#f13e4b' size='13px' fontWeight='400'>
                      This is a required item.
                    </Typography>
                  </FlexWrap>
                )}
              </FlexWrap>
            </FlexWrap>
            <Input
              {...register('country', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('country', sanitizeInput(e.target.value)),
              })}
              label='Country'
              isRequired
              placeholder='Country'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.country}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue(
                    'stateProvinceRegion',
                    sanitizeInput(e.target.value)
                  ),
              })}
              label='State'
              isRequired
              placeholder='State'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='City'
              isRequired
              placeholder='City'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Recipient Address'
              isRequired
              placeholder='Recipient Address'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'JPN':
        return (
          <>
            <InputWrapper>
              <Input
                {...register('bank', {
                  required: 'This is a required item.',
                  onChange: onChangeAndSearchKeyword,
                })}
                label='Bank account'
                placeholder='Name of Bank'
                autoComplete='none'
                type='text'
                error={errors?.bank}
                disabled={disabled}
                isRequired
                onBlur={() => {
                  setIsDropList(false);
                  setSelectedItemIndex(-1);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) =>
                      Math.min(prev + 1, keywordList.length - 1)
                    );
                  } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) => Math.max(prev - 1, 0));
                  } else if (e.key === 'Escape') {
                    e.preventDefault();
                    setIsDropList(false);
                    setSelectedItemIndex(-1);
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    setValue('bank', keywordList[selectedItemIndex]);
                    setIsDropList(false);
                  }
                }}
              />
              {isDropList && (
                <DropList ref={dropDownRef}>
                  {keywordList.map((bank, idx) => (
                    <DropItem
                      key={idx}
                      isSelected={selectedItemIndex === idx}
                      onClick={() => {
                        setValue('bank', bank);
                        setIsDropList(false);
                      }}
                    >
                      {bank}
                    </DropItem>
                  ))}
                </DropList>
              )}
            </InputWrapper>
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d{7}$/,
                  message: 'Please enter the number 7 digits.',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank Account Number (口座番号+)'
              maxLength={7}
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * 法人の銀行口座は受け付けておりません。
            </Typography>
            <Input
              {...register('branchInformation', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
              })}
              label='支店番号'
              isRequired
              placeholder='Branch Number (3-digits)'
              margin={[20, 0, 0]}
              disabled={disabled}
              maxLength={3}
              error={errors?.branchInformation}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue(
                    'stateProvinceRegion',
                    sanitizeInput(e.target.value)
                  ),
              })}
              label='県(英語)'
              isRequired
              placeholder='Prefecture (英語のみ English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='市(英語)'
              isRequired
              placeholder='City (英語のみ English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='詳細注所(英語)'
              isRequired
              placeholder='Address (英語のみ English and numbers only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^[0-9-]{8}$/,
                  message: 'Please enter the number 7 digits.',
                },
              })}
              label='郵便番号'
              isRequired
              value={attachedHyphensPostCode(getValues('zipPostalCode') || '')}
              maxLength={8}
              placeholder='Post Code (numbers only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
            <LabelWrapper marginTop='20px'>
              <Label>口座タイプ</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <FlexWrap dir='column' justifyContent='center' gap='16px'>
              <RadioLabel>
                <input
                  {...register('accountType', {
                    required: 'This is a required item.',
                  })}
                  type='radio'
                  value='普通 (Futsuu) current/general'
                  onClick={() =>
                    setSelectedAccountType('普通 (Futsuu) current/general')
                  }
                  checked={
                    selectedAccountType === '普通 (Futsuu) current/general'
                  }
                  disabled={disabled}
                />
                普通 (Futsuu) current/general
              </RadioLabel>
              <RadioLabel>
                <input
                  {...register('accountType', {
                    required: 'This is a required item.',
                  })}
                  type='radio'
                  value='当座 (Touza) checking'
                  onClick={() =>
                    setSelectedAccountType('当座 (Touza) checking')
                  }
                  checked={selectedAccountType === '当座 (Touza) checking'}
                  disabled={disabled}
                />
                当座 (Touza) checking
              </RadioLabel>
              {errors?.accountType && (
                <FlexWrap gap='8px'>
                  <Icon imgUrl={ErrorAlert} width='18px' height='18px' />
                  <Typography color='#f13e4b' size='13px' fontWeight='400'>
                    This is a required item.
                  </Typography>
                </FlexWrap>
              )}
              <Typography
                size='12px'
                lineHeight='150%'
                fontWeight='400'
                color='#A7A7A7'
              >
                *貯蓄口座の払い戻しを受けることはできません。
              </Typography>
            </FlexWrap>
          </>
        );

      case 'THA':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * บัญชีธนาคารของบริษัทไม่ได้รับการยอมรับ
            </Typography>
            <Input
              {...register('swiftCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='รหัสสวิฟท์'
              isRequired
              placeholder='Swift Code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.swiftCode}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
              })}
              label='จังหวัด'
              isRequired
              placeholder='Province (ภาษาอังกฤษเท่านั้น English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
              })}
              label='เขต'
              isRequired
              placeholder='District (ภาษาอังกฤษเท่านั้น English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='ตำบล. ถนน'
              isRequired
              placeholder='Subdistrict. Road'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='แปลง/เลขที่บ้าน. หมู่บ้าน'
              isRequired
              placeholder='Plot/House number. Village'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='รหัสไปรษณีย์'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );

      case 'AUS':
        return (
          <>
            <Input
              {...register('bsbNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
              })}
              type='number'
              label='BSB code'
              error={errors?.bsbNumber}
              maxLength={6}
              isRequired
              placeholder='BSB code (6-digits)'
              disabled={disabled}
            />
            <Input
              placeholder='Bank account'
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              isRequired
              label='Bank account'
              type='number'
              margin={[20, 0, 0]}
              error={errors?.bankAccount}
              disabled={disabled}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue(
                    'stateProvinceRegion',
                    sanitizeInput(e.target.value)
                  ),
              })}
              label='State'
              isRequired
              placeholder='State'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='City or Suburb'
              isRequired
              placeholder='City or Suburb'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail', sanitizeInput(e.target.value)),
              })}
              label='Street No.'
              isRequired
              placeholder='Street No.'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              label='APT, UNIT or SUITE'
              isRequired
              placeholder='APT, UNIT or SUITE'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail2}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );

      case 'CAN':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 20px 0'
            >
              * Corporate accounts are not accepted.
            </Typography>
            <FlexWrap dir='column'>
              <LabelWrapper>
                <Label>Account Type</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <FlexWrap dir='column' justifyContent='center' gap='16px'>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='SAVING'
                    onClick={(e) => setSelectedAccountInfo('SAVING')}
                    checked={selectedAccountInfo === 'SAVING'}
                    disabled={disabled}
                  />
                  Saving Account
                </RadioLabel>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='CHECKING'
                    onClick={() => setSelectedAccountInfo('CHECKING')}
                    checked={selectedAccountInfo === 'CHECKING'}
                    disabled={disabled}
                  />
                  Checking Account
                </RadioLabel>
                {errors?.accountInfo && (
                  <FlexWrap gap='8px'>
                    <Icon imgUrl={ErrorAlert} width='18px' height='18px' />
                    <Typography color='#f13e4b' size='13px' fontWeight='400'>
                      This is a required item.
                    </Typography>
                  </FlexWrap>
                )}
              </FlexWrap>
            </FlexWrap>
            <Input
              {...register('transitNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
              })}
              label='Transit Number'
              isRequired
              maxLength={5}
              placeholder='Transit Number (5-digit)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.transitNumber}
            />
            <Input
              {...register('institutionNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
              })}
              label='Institution number'
              isRequired
              placeholder='Institution number (3-digit)'
              maxLength={3}
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.institutionNumber}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * The institution number is a 3-digit number identifying the
              financial institution to which a payment item is directed.
            </Typography>
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
              })}
              label='Province or Territory'
              isRequired
              placeholder='Province or Territory (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='City or Suburb'
              isRequired
              placeholder='City or Suburb'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail', sanitizeInput(e.target.value)),
              })}
              label='Street No.'
              isRequired
              placeholder='Street No.'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              label='APT, UNIT or SUITE'
              isRequired
              placeholder='APT, UNIT or SUITE'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail2}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );

      case 'SGP':
        return (
          <>
            <Input
              placeholder='Name of Bank'
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              isRequired
              error={errors?.bank}
              disabled={disabled}
            />
            <Input
              placeholder='Bank account'
              {...register('bankAccount', {
                pattern: {
                  value: /^\S/,
                  message: 'This is a required item.',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 20px 0'
            >
              * Corporate accounts are not accepted.
            </Typography>
            <FlexWrap dir='column'>
              <LabelWrapper>
                <Label>Swift Code</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <BankWrap isChnBank>
                <ArrowIcon src={ArrowDown} isChnBank />
                <Select
                  {...register('swiftCode')}
                  isSelected={Boolean(selectedBank)}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  isEdit={isEdit}
                  disabled={disabled}
                >
                  <Option value='' hidden>
                    Swift Code
                  </Option>
                  {sgpSwiftCodeList.map((bank, idx) => (
                    <Option key={idx} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </BankWrap>
            </FlexWrap>
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s,-]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Block/Street Name'
              isRequired
              placeholder='Block/Street Name (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail', sanitizeInput(e.target.value)),
              })}
              label='Building/House Number'
              isRequired
              placeholder='Building/House Number (English and numbers)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'TWN':
        return (
          <>
            <FlexWrap dir='column'>
              <LabelWrapper>
                <Label>Cash Pickup</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <BankWrap>
                <ArrowIcon src={ArrowDown} isChnBank />
                <Select
                  {...register('cashPickup', {
                    required: 'This is a required item.',
                  })}
                  isSelected={Boolean(selectedBank)}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  isEdit={isEdit}
                  disabled={disabled}
                >
                  <Option value='' hidden>
                    Cash Pickup
                  </Option>
                  <Option value='Western Union'>Western Union</Option>
                </Select>
              </BankWrap>
            </FlexWrap>
          </>
        );
      case 'IDN':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 20px 0'
            >
              * Corporate accounts are not accepted.
            </Typography>
            <Input
              {...register('swiftCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='SWIFT Code'
              isRequired
              placeholder='Swift Code'
              disabled={disabled}
              error={errors?.swiftCode}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue(
                    'stateProvinceRegion',
                    sanitizeInput(e.target.value)
                  ),
              })}
              label='Province'
              isRequired
              placeholder='Province (English Only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='City or Regency'
              isRequired
              placeholder='City or Regency  (English Only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Street Name'
              isRequired
              placeholder='Street Name'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              label='APT, UNIT or SUITE'
              isRequired
              placeholder='APT, UNIT or SUITE'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail2}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'MNG':
      case 'NZL':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                pattern: {
                  value: /^\S/,
                  message: 'This is a required item.',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * Corporate accounts are not accepted.
            </Typography>
          </>
        );
      case 'CHE':
      case 'DEU':
        return (
          <>
            <Input
              {...register('ibanNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('ibanNumber', sanitizeInput(e.target.value)),
              })}
              label='IBAN Number'
              isRequired
              placeholder='IBAN Number'
              disabled={disabled}
              error={errors?.ibanNumber}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * IBANs are long account numbers used by banks for cross-border
              transfers. Each country structures this number differently, but it
              always starts with a 2 digit country code (e.g. DE for Germany).
            </Typography>
            <Input
              {...register('swiftCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label='Swift Code'
              isRequired
              placeholder='Swift Code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.swiftCode}
            />
            <Input
              {...register('stateProvinceRegion', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
              })}
              label='Region'
              isRequired
              placeholder='Region (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.stateProvinceRegion}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
              })}
              label='Town/City'
              isRequired
              placeholder='Town/City'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Street Name'
              isRequired
              placeholder='Street Name'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail', sanitizeInput(e.target.value)),
              })}
              label='House No.'
              isRequired
              placeholder='House No.'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );

      case 'PHL':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Bank account'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Please enter only numbers',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Input
              {...register('swiftCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
              })}
              label=' Swift Code'
              isRequired
              placeholder=' Swift Code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.swiftCode}
            />
            <Input
              {...register('country', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('country', sanitizeInput(e.target.value)),
              })}
              label='Country'
              isRequired
              placeholder='Country (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.country}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='City'
              isRequired
              placeholder='City (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Street Name'
              isRequired
              placeholder='Street Name'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              label='APT, UNIT or SUITE'
              isRequired
              placeholder='APT, UNIT or SUITE'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail2}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'RUS':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='банковский счет'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                pattern: {
                  value: /\S+/,
                  message: 'This is a required item.',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <Input
              {...register('bsbNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('bsbNumber', sanitizeInput(e.target.value)),
              })}
              label='быстрый код'
              isRequired
              placeholder='BIC'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.bsbNumber}
            />
            <Input
              {...register('country', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('country', sanitizeInput(e.target.value)),
              })}
              label='Страна'
              isRequired
              placeholder='Country (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.country}
            />
            <Input
              {...register('city', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z\s]+$/,
                  message: 'Please enter only English',
                },
                onChange: (e) =>
                  setValue('city', sanitizeInput(e.target.value)),
              })}
              label='Сити'
              isRequired
              placeholder='City (English only)'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.city}
            />
            <Input
              {...register('address', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('address', sanitizeInput(e.target.value)),
              })}
              label='Название улицы'
              isRequired
              placeholder='Street Name'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.address}
            />
            <Input
              {...register('addressDetail2', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s,-]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('addressDetail2', sanitizeInput(e.target.value)),
              })}
              label='КВАРТИРА, НОМЕР или ЛЮКС'
              isRequired
              placeholder='APT, UNIT or SUITE'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.addressDetail2}
            />
            <Input
              {...register('zipPostalCode', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('zipPostalCode', sanitizeInput(e.target.value)),
              })}
              label='Post code'
              isRequired
              placeholder='Post code'
              margin={[20, 0, 0]}
              disabled={disabled}
              error={errors?.zipPostalCode}
            />
          </>
        );
      case 'ARE':
        return (
          <>
            <Input
              {...register('ibanNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('ibanNumber', sanitizeInput(e.target.value)),
              })}
              label='IBAN Number'
              isRequired
              placeholder='IBAN Number'
              disabled={disabled}
              error={errors?.ibanNumber}
            />
            <Typography
              size='12px'
              lineHeight='20px'
              fontWeight='400'
              color='#A7A7A7'
              margin='8px 0 0 0'
            >
              * IBANs are long account numbers used by banks for cross-border
              transfers. Each country structures this+ number differently, but
              it always starts with a 2 digit country code (e.g. DE for
              Germany).
            </Typography>
          </>
        );
      case 'CHL':
        return (
          <>
            <Input
              {...register('bank', {
                required: 'This is a required item.',
                onChange: (e) =>
                  setValue('bank', sanitizeInput(e.target.value)),
              })}
              label='Cuenta bancaria'
              placeholder='Name of Bank'
              type='text'
              error={errors?.bank}
              disabled={disabled}
              isRequired
            />
            <Input
              {...register('bankAccount', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S/,
                  message: 'This is a required item.',
                },
                onChange: (e) =>
                  setValue('bankAccount', sanitizeInput(e.target.value)),
              })}
              type='text'
              placeholder='Bank account'
              error={errors?.bankAccount}
              margin={[8, 0, 0]}
              disabled={disabled}
            />
            <FlexWrap dir='column' margin='20px 0 0 0'>
              <LabelWrapper>
                <Label>Tipo de cuenta</Label>
                <RequiredDot>*</RequiredDot>
              </LabelWrapper>
              <FlexWrap dir='column' justifyContent='center' gap='16px'>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='CHECKING'
                    onClick={() => setSelectedAccountInfo('CHECKING')}
                    checked={selectedAccountInfo === 'CHECKING'}
                    disabled={disabled}
                  />
                  Checking [cuenta corriente]
                </RadioLabel>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='SAVING'
                    onClick={() => setSelectedAccountInfo('SAVING')}
                    checked={selectedAccountInfo === 'SAVING'}
                    disabled={disabled}
                  />
                  Savings [cuenta de ahorro]
                </RadioLabel>
                <RadioLabel>
                  <input
                    {...register('accountInfo', {
                      required: 'This is a required item.',
                    })}
                    type='radio'
                    value='DEMAND'
                    onClick={() => setSelectedAccountInfo('DEMAND')}
                    checked={selectedAccountInfo === 'DEMAND'}
                    disabled={disabled}
                  />
                  Demand [cuenta vista]
                </RadioLabel>
                {errors?.accountInfo && (
                  <FlexWrap gap='8px'>
                    <Icon imgUrl={ErrorAlert} width='18px' height='18px' />
                    <Typography color='#f13e4b' size='13px' fontWeight='400'>
                      This is a required item.
                    </Typography>
                  </FlexWrap>
                )}
              </FlexWrap>
            </FlexWrap>
            <Input
              {...register('institutionNumber', {
                required: 'This is a required item.',
                pattern: {
                  value: /^\S[a-zA-Z0-9\s]+$/,
                  message: 'Please enter only English and numbers.',
                },
                onChange: (e) =>
                  setValue('institutionNumber', sanitizeInput(e.target.value)),
              })}
              type='text'
              isRequired
              label='Rol Único Tributario'
              placeholder="Recipient's RUT number"
              error={errors?.institutionNumber}
              margin={[20, 0, 0]}
              disabled={disabled}
            />
          </>
        );
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <ContactWrapper>{remittanceMethodInfo()}</ContactWrapper>
    </>
  );
}

const ContactWrapper = styled.div``;
const LabelWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;
const Label = styled.label`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 4px;
  padding-bottom: 6px;
`;
const BankWrap = styled(FlexWrap)<{ isChnBank?: boolean }>`
  position: relative;
  align-items: center;
  margin-bottom: 8px;
`;

const DropList = styled.ul`
  position: absolute;
  max-height: 300px;
  overflow: auto;
  z-index: 10;
  top: 100px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;
const DropItem = styled.li<{ isSelected: boolean }>`
  font-size: 12px;
  padding: 10px 6px;
  background-color: ${(props) => props.isSelected && '#d3e2fd'};
  :hover {
    background-color: #d3e2fd;
    cursor: pointer;
  }
`;
const ArrowIcon = styled.img<{ isChnBank?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 13px;
  ${(props) => props.isChnBank && css``}
`;
const Select = styled.select<{
  isChnBank?: boolean;
  isSelected?: boolean;
  isEdit?: boolean;
  isBankCardInquiry?: boolean;
  isSearch?: boolean;
}>`
  width: 100%;
  border: 1px solid #cbccce;
  cursor: pointer;
  padding: 14px 13px;
  border-radius: 4px;
  appearance: none;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: ${(props) =>
    props.isEdit ? '#3A3B3E' : props.isSelected ? '#3A3B3E' : '#CBCCCE'};
  ${(props) =>
    props.isChnBank &&
    css`
      background-color: #f5f6f7;
      font-weight: 500;
      color: #3a3b3e;
      cursor: default;
    `}
  :disabled {
    background-color: #f5f6f7;
  }
  ${(props) =>
    props.isSearch &&
    css`
      max-height: 200px;
      overflow: auto;
    `}
  :disabled {
    background-color: #f5f6f7;
  }
`;
const Option = styled.option``;
const RadioLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #212121;
`;
const Button = styled.button<{
  isDisabled: boolean;
}>`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  background-color: #246cf6;
  padding: 12px 0;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  ${(props) =>
    props.isDisabled &&
    css`
      background-color: #d3e2fd;
    `}
  :disabled {
    color: #fff;
    :hover {
      opacity: 1;
    }
  }
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 5px solid #fff;
  margin: 0 auto;
  border-top: 5px solid #246cf6;
`;
const InputWrapper = styled.div`
  position: relative;
`;
const AuthText = styled.p`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #246cf6;
`;

export default BankInfoForm;
